import React, { Component } from "react";
import { ButtonToolbar, Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { closeModify } from "../../../actions/newsActions";
import { updateNews } from "../../../actions/newsActions";
import { selectId } from "../../../actions/newsActions";
import classnames from "classnames";

export class MyModalModifyNews extends Component {
  state = {
    title: this.props.modified_news[0].title,
    text: this.props.modified_news[0].text,
    lgShow: this.props.status,
    errors: {}
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.state.title === "") {
      this.setState({ errors: { title: "Il titolo è obbligatorio" } });
      return;
    }
    if (this.state.text === "") {
      this.setState({ errors: { text: "Il testo è obbligatorio" } });
      return;
    }
    this.props.updateNews(
      this.props.select_id,
      this.state.title,
      this.state.text
    );
    this.setState({ lgShow: false });
    this.props.closeModify();
  };

  onChange = e =>
    this.setState({
      [e.target.name]: e.target.value
    });

  onClose = () => {
    this.setState({ lgShow: false });
    this.props.closeModify();
  };

  render() {
    const { title, text, errors } = this.state;

    return (
      <ButtonToolbar>
        <Modal
          size="lg"
          show={this.state.lgShow}
          onHide={this.onClose}
          aria-labelledby="example-modal-sizes-title-lg"
          style={{ padding: "0" }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Modifica la news selezionata
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ marginTop: "30px" }}>
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Titolo</label>
                  <input
                    name="title"
                    type="text"
                    className={classnames("form-control lg", {
                      "is-invalid": errors.title
                    })}
                    value={title}
                    onChange={this.onChange}
                  />
                  {errors.title && (
                    <div className="invalid-feedback">{errors.title}</div>
                  )}
                </div>
                <div className="form-group">
                  <label>Testo</label>
                  <textarea
                    className={classnames("form-control lg", {
                      "is-invalid": errors.text
                    })}
                    rows="10"
                    name="text"
                    value={text}
                    onChange={this.onChange}
                  />
                  {errors.text && (
                    <div className="invalid-feedback">{errors.text}</div>
                  )}
                </div>
                <br />
                <input
                  type="submit"
                  value="MODIFICA"
                  className="btn btn-light btn-block button-contatti"
                />
                <div
                  className="mywrapper"
                  style={{ textAlign: "center", marginTop: "30px" }}
                >
                  <Button onClick={this.onClose} variant="danger">
                    ANNULLA
                  </Button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </ButtonToolbar>
    );
  }
}

const mapStateToProps = state => ({
  modified_news: state.news.modified_news,
  select_id: state.news.select_id
});

export default connect(
  mapStateToProps,
  { closeModify, updateNews, selectId }
)(MyModalModifyNews);
