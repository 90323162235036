import React from "react";
import Photo_3_4_servizi from "../component/js/photo_3_4_servizi";
import Rectangle_2_4_ri from "../component/js/rectangle_2_4_ri";
import Rectangle_2_4_li from "../component/js/rectangle_2_4_li";
import Rectangle_2_4_ri_2 from "../component/js/rectangle_2_4_ri_2";
import "../style_app/Servizi.css";

export default function Servizi() {
  document.title = "Servizi";
  window.scrollTo(0, 0);
  return (
    <React.Fragment>
      <Photo_3_4_servizi />
      <div className="title_servizi">COME POSSIAMO AIUTARTI</div>
      <hr style={{ width: "20%", margin: "0 auto 20px auto" }} />
      <Rectangle_2_4_ri />
      <Rectangle_2_4_li />
      <Rectangle_2_4_ri_2 />
    </React.Fragment>
  );
}
