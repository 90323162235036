import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import logo from "../../photos/logo.png";
import "../style_app/Homepage.css";

class Homepage_photo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(); // Create a ref object
    this.routeChange = this.routeChange.bind(this);
  }

  // scrollToMyRef = () => {
  //   window.scrollTo(0, this.myRef.current.clientHeight);
  // };
  componentDidMount() {
    document.title = "Homepage";
  }

  routeChange() {
    let path = "/contatti";
    this.props.history.push(path);
  }

  render() {
    window.scrollTo(0, 0);
    return (
      <React.Fragment>
        <div ref={this.myRef} className="responsive">
          <div style={{ position: "relative", top: "25vh" }}>
            <img
              src={logo}
              alt=""
              className="main_logo"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto"
              }}
            />
            <h1
              className="main_phrase"
              style={{
                minHeight: "191px",
                textAlign: "center",
                marginTop: "25px",
                lineWidth: "20vw",
                padding: "25px",
                fontSize: 35
                //fontFamily: "verdana"
              }}
            >
              Cambia il modo di gestire il personale
            </h1>
          </div>
          <section
            id="section05"
            className="scroll_homepage"
            style={{ position: "absolute", bottom: 0 }}
          >
            <a style={{ cursor: "default" }}>
              <span />
            </a>
          </section>
        </div>
        {/* <div style={{ position: "relative", top: "20px" }}> */}
        <main
          id="main"
          className="site-main"
          role="main"
          style={{ minHeight: "137.6px", marginTop: "25px" }}
        >
          <div className="box_left">
            <h2>COSTI DEL PERSONALE</h2>
            <h2 className="homepageh2">TROPPO ALTI?</h2>
          </div>
          <div className="next_to_box">
            <h4>
              Da sempre al fianco dei nostri clienti, perchè la vostra
              soddisfazione è il nostro premio più importante.
            </h4>
          </div>
          <div className="image_business" />
          <div
            className="services_container animatedParent"
            data-sequence="200"
            data-appear-top-offset="-50"
          >
            <div
              data-aos="fade-left"
              data-aos-duration="2000"
              //className="half animated fadeInRightShort go"
              className="half"
              data-id="0"
            >
              <span className="icon_circle">
                <i className="fas fa-user-tie" />
              </span>
              <h2>GESTIONE</h2>
              <h5>
                Amministrare il personale significa gestire le risorse umane per
                conto delle aziende, prevedendo gli impatti economici,
                assicurativi e previdenziali.
              </h5>
              <p id="content_1" />
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="2000"
              className="half"
              data-id="1"
              //className="half animated fadeInRightShort go" data-id="1"
            >
              <span className="icon_circle">
                <i
                  className="fas fa-chart-line"
                  style={{ paddingTop: "5px" }}
                />
              </span>
              <h2>PERCHE' NOI</h2>
              <h5>
                Da sempre crediamo nell'innovazione tecnologica e nei suoi
                benefici: scopri le nostre soluzioni per migliorare l'efficienza
                e ridurre i costi.
              </h5>
              <p id="content_2" />
            </div>
          </div>

          <div className="home_testimonial">
            <h4>
              L'unico modo di fare un gran bel lavoro è amare quello che fate
            </h4>
            <span>
              <i>
                <strong>Steve Jobs</strong>
              </i>
            </span>
          </div>

          <div>
            <div className="box_left">
              <h2 className="text_h2">COME POSSIAMO AIUTARTI?</h2>
            </div>
            <div className="next_to_box" style={{ height: "300px" }}>
              <h3 id="content_3" style={{ textAlign: "center" }} />

              <div
                id="myPageContent"
                className="container-fluid"
                style={{ marginTop: "-50px" }}
              >
                <section>
                  <div id="textSlider" className="row">
                    <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 iamCol">
                      <p style={{ fontSize: 35 }}>G&amp;F</p>
                      <p style={{ fontSize: 20 }}> Consulting</p>
                    </div>
                    <div
                      className="col-xs-12 col-sm-12 col-md-5 col-lg-7 slideCol"
                      style={{ fontSize: "30px" }}
                    >
                      <div className="scroller">
                        <div
                          className="inner"
                          style={{ wordWrap: "break-word" }}
                        >
                          <p>Esperienza</p>
                          <p>Passione</p>
                          <p>Trasparenza</p>
                          <p>Innovazione</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div className="image_business2" data-appear-top-offset="-150" />

          <div className="centerButton">
            <button className="button button2" onClick={this.routeChange}>
              CONTATTACI
            </button>
          </div>
        </main>
        {/* </div> */}
      </React.Fragment>
    );
  }
}

export default withRouter(Homepage_photo);
