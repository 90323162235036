import React, { Component } from "react";
import { connect } from "react-redux";
import { DeleteFile } from "../../../actions/userActions";
import { selectId2 } from "../../../actions/userActions";

export class Single_file extends Component {
  onDelete = id => {
    this.props.selectId2(id);
    this.props.DeleteFile();
  };
  render() {
    const { my_owner, anno, mese, file_nome, data } = this.props.single_file;
    const date = new Date(data).toLocaleDateString();
    return (
      <tr>
        <td>{my_owner}</td>
        <td>{anno}</td>
        {mese === 1 ? (
          <td>Gennaio</td>
        ) : mese === 2 ? (
          <td>Febbraio</td>
        ) : mese === 3 ? (
          <td>Marzo</td>
        ) : mese === 4 ? (
          <td>Aprile</td>
        ) : mese === 5 ? (
          <td>Maggio</td>
        ) : mese === 6 ? (
          <td>Giugno</td>
        ) : mese === 7 ? (
          <td>Luglio</td>
        ) : mese === 8 ? (
          <td>Agosto</td>
        ) : mese === 9 ? (
          <td>Settembre</td>
        ) : mese === 10 ? (
          <td>Ottobre</td>
        ) : mese === 11 ? (
          <td>Novembre</td>
        ) : mese === 12 ? (
          <td>Dicembre</td>
        ) : null}
        <td>{file_nome}</td>
        <td>{date}</td>

        <td>
          <i
            className=" fas fa-trash-alt"
            style={{ color: "red", cursor: "pointer" }}
            onClick={this.onDelete.bind(this, file_nome)}
          />
        </td>
      </tr>
    );
  }
}

export default connect(
  null,
  { selectId2, DeleteFile }
)(Single_file);
