import React, { Component } from "react";
import { connect } from "react-redux";
import { sendUser } from "../../../actions/userActions";
import ModalSuccessGlobal from "../../component/js/ModalSuccessGlobal";
import classnames from "classnames";

export class Add_user extends Component {
  state = {
    username: "",
    email: "",
    password: "",
    errors: {}
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.state.username === "") {
      this.setState({ errors: { username: "Username obbligatorio" } });
      return;
    }
    if (this.state.email === "") {
      this.setState({ errors: { email: "Email obbligatoria" } });
      return;
    }
    if (this.state.password === "") {
      this.setState({ errors: { password: "Email obbligatoria" } });
      return;
    }
    this.props.sendUser(
      this.state.username,
      this.state.email,
      this.state.password
    );
    this.setState({ username: "", email: "", password: "", errors: {} });
  };

  onChange = e =>
    this.setState({
      [e.target.name]: e.target.value
    });

  render() {
    const { username, email, password, errors } = this.state;
    const { show_modal_global } = this.props;
    return (
      <div style={{ marginTop: "30px" }}>
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              name="username"
              type="text"
              className={classnames("form-control lg", {
                "is-invalid": errors.username
              })}
              value={username}
              onChange={this.onChange}
            />
            {errors.username && (
              <div className="invalid-feedback">{errors.username}</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              name="email"
              type="email"
              className={classnames("form-control lg", {
                "is-invalid": errors.email
              })}
              value={email}
              onChange={this.onChange}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email}</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              name="password"
              type="text"
              className={classnames("form-control lg", {
                "is-invalid": errors.password
              })}
              value={password}
              onChange={this.onChange}
            />
            {errors.password && (
              <div className="invalid-feedback">{errors.password}</div>
            )}
          </div>
          <br />
          <input
            type="submit"
            value="Crea Nuovo Utente"
            className="btn btn-light btn-block  button-contatti"
            style={{ background: "#28a745" }}
          />
        </form>
        {show_modal_global ? <ModalSuccessGlobal status={true} /> : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  show_modal_global: state.modal.show_modal_global
});

export default connect(
  mapStateToProps,
  { sendUser }
)(Add_user);
