import { ALL_DATA_REPORT } from "../actions/types";

const initialState = {
  ordered_months: null,
  overall: null,
  unita_locali: null,
  qualifica: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ALL_DATA_REPORT:
      return {
        ...state,
        ordered_months: action.payload.ordered_months,
        overall: action.payload.overall,
        unita_locali: action.payload.unita_locali,
        qualifica: action.payload.qualifica
      };
    default:
      return state;
  }
}
