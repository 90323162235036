import React, { Component } from "react";
import "./navbar.css";
import { Link } from "react-router-dom";

export class navbar extends Component {
  state = {
    my_class: "collapse navbar-collapse"
  };

  my_toggle() {
    this.setState({
      my_class: "collapse navbar-collapse"
    });
  }
  render() {
    const opacity = this.props.opacity ? Math.max(this.props.opacity, 0.2) : 0;
    const borderBottomWidth =
      this.props.opacity === 1 ? this.props.borderBottomWidth : 0;

    return (
      <div
        className="navbar navbar-default navbar-static-top"
        role="navigation"
        style={{ opacity, borderBottomWidth, margin: "0", padding: "0" }}
      >
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <a className="navbar-brand" href="#" style={{ color: "white" }}>
            G&amp;F Consulting
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className={this.state.my_class} id="navbarNavAltMarkup">
            <div className="navbar-nav ml-auto">
              <Link
                to="/"
                className="nav-item nav-link active"
                onClick={this.my_toggle}
              >
                Homepage <span className="sr-only">(current)</span>
              </Link>
              {/* <a className="nav-item nav-link active" href="/">
                Homepage <span className="sr-only">(current)</span>
              </a> */}
              <Link
                to="/team"
                className="nav-item nav-link active"
                onClick={this.my_toggle}
              >
                Chi Siamo <span className="sr-only">(current)</span>
              </Link>
              {/* <a className="nav-item nav-link active" href="/team">
                Chi Siamo <span className="sr-only">(current)</span>
              </a> */}
              <Link
                to="/servizi"
                className="nav-item nav-link active"
                onClick={this.my_toggle}
              >
                Servizi <span className="sr-only">(current)</span>
              </Link>
              {/* <a className="nav-item nav-link active" href="/servizi">
                Servizi <span className="sr-only">(current)</span>
              </a> */}
              <Link
                to="/perche_noi"
                className="nav-item nav-link active"
                onClick={this.my_toggle}
              >
                Perchè noi <span className="sr-only">(current)</span>
              </Link>
              {/* <a className="nav-item nav-link active" href="/perche_noi">
              Perchè noi <span className="sr-only">(current)</span>
              </a> */}
              <Link
                to="/news"
                className="nav-item nav-link active"
                onClick={this.my_toggle}
              >
                News <span className="sr-only">(current)</span>
              </Link>
              {/* <a className="nav-item nav-link active" href="/news">
                News <span className="sr-only">(current)</span>
              </a> */}
              <Link
                to="/contatti"
                className="nav-item nav-link active"
                onClick={this.my_toggle}
              >
                Contattaci <span className="sr-only">(current)</span>
              </Link>
              {/* <a className="nav-item nav-link active" href="/contatti">
                Contattaci <span className="sr-only">(current)</span>
              </a> */}
              <Link
                to="/login"
                className="nav-item nav-link active"
                onClick={this.my_toggle}
              >
                Login <span className="sr-only">(current)</span>
              </Link>
              {/* <a className="nav-item nav-link active" href="/login">
                Login <span className="sr-only">(current)</span>
              </a> */}
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default navbar;
