import {
  GET_DISTINCT_YEAR,
  GET_ERRORS,
  DEFAULT_SELECTED_YEAR,
  SELECT_YEAR,
  IS_LOADING_DATA,
  LOADED_DATA,
  SELECT_PAGE,
  API_URL,
  HANDLE_COLUMN,
  ANNULLA_COLUMN,
  UPDATE_ANNULLA,
  HANDLE_COLUMN_TOTALI,
  UPDATE_ANNULLA_TOTALI,
  ANNULLA_COLUMN_TOTALI,
  ALL_DATA_HOME,
  ALL_DATA_UNITA_LOCALI,
  ALL_DATA_QUALIFICA,
  SET_MODE,
  UPDATE_SELECTED_TOTALI,
  ALL_DATA_DETTAGLI,
  ALL_DATA_DOWNLOAD,
  ALL_DATA_STORICO,
  ALL_DATA_REPORT
} from "./types";

import axios from "axios";

export const dataRequest = async (selected_year, dispatch, getState) => {
  try {
    const res_get = await axios.get(
      `${API_URL}/dipendenti/?q=all&year=${selected_year}`,
      tokenConfig(getState)
    );
    dispatch({
      type: ALL_DATA_HOME,
      payload: res_get.data.home
    });
    dispatch({
      type: ALL_DATA_UNITA_LOCALI,
      payload: res_get.data.unita_locali
    });
    dispatch({
      type: ALL_DATA_QUALIFICA,
      payload: res_get.data.qualifica
    });
    dispatch({
      type: ALL_DATA_DETTAGLI,
      payload: res_get.data.dettagli
    });
    dispatch({
      type: ALL_DATA_DOWNLOAD,
      payload: res_get.data.download
    });
    dispatch({
      type: ALL_DATA_STORICO,
      payload: res_get.data.storico
    });
    dispatch({
      type: ALL_DATA_REPORT,
      payload: res_get.data.report
    });
    dispatch({
      type: LOADED_DATA
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
    dispatch({
      type: LOADED_DATA
    });
  }
};

export const getAllData = () => async (dispatch, getState) => {
  try {
    // start loading --> loading is closed in dataRequest function
    dispatch({
      type: IS_LOADING_DATA
    });
    // get all distinct year for appbar
    const res = await axios.get(
      `${API_URL}/dipendenti/?q=d_y`,
      tokenConfig(getState)
    );
    dispatch({
      type: DEFAULT_SELECTED_YEAR,
      payload: res.data[0]
    });
    dispatch({
      type: GET_DISTINCT_YEAR,
      payload: res.data
    });

    // get all data using a function (because it's called also in selectYear)
    // --> by default, selected year is the last one (array ordered from backend)
    dataRequest(res.data[0], dispatch, getState);
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
    dispatch({
      type: LOADED_DATA
    });
  }
};

// change year from appbar
export const selectYear = selected_year => async (dispatch, getState) => {
  try {
    // start loading --> loading is closed in dataRequest function
    dispatch({
      type: IS_LOADING_DATA
    });

    dispatch({
      type: SELECT_YEAR,
      payload: selected_year
    });
    dataRequest(selected_year, dispatch, getState);
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
    dispatch({
      type: LOADED_DATA
    });
  }
};

export const setMode = mode => async dispatch => {
  dispatch({
    type: SET_MODE,
    payload: mode
  });
};

export const updateSelectedTotali = selected_checkboxes => async dispatch => {
  dispatch({
    type: UPDATE_SELECTED_TOTALI,
    payload: selected_checkboxes
  });
};

export const selectPage = page => dispatch => {
  dispatch({
    type: SELECT_PAGE,
    payload: page
  });
};

export const handleChart = elem => dispatch => {
  dispatch({
    type: HANDLE_COLUMN,
    payload: elem
  });
};

export const handleChartTotali = elem => dispatch => {
  dispatch({
    type: HANDLE_COLUMN_TOTALI,
    payload: elem
  });
};

export const removeTabChart = elem => async dispatch => {
  try {
    dispatch({
      type: HANDLE_COLUMN,
      payload: elem
    });
    dispatch({
      type: UPDATE_ANNULLA
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
  }
};

export const annullaChart = () => dispatch => {
  dispatch({
    type: ANNULLA_COLUMN
  });
};

export const annullaChartTotali = () => dispatch => {
  dispatch({
    type: ANNULLA_COLUMN_TOTALI
  });
};

export const updateAnnulla = () => async dispatch => {
  try {
    dispatch({
      type: UPDATE_ANNULLA
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
  }
};

export const updateAnnullaTotali = () => async dispatch => {
  try {
    dispatch({
      type: UPDATE_ANNULLA_TOTALI
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
  }
};

export const removeTabChartTotali = elem => dispatch => {
  dispatch({
    type: HANDLE_COLUMN_TOTALI,
    payload: elem
  });
  dispatch({
    type: UPDATE_ANNULLA_TOTALI
  });
};

export const tokenConfig = getState => {
  // get token from state
  const token = getState().auth.token;
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
};
