import React, { Component } from "react";
import Photo_3_4_team from "../component/js/photo_3_4_team";
import Intro_team from "../component/js/intro_team";
import Photo_all from "../component/js/photo_all";
import { Row, Col } from "reactstrap";

// import Description_1_team from "../component/js/description_1_team";
// import Description_2_team from "../component/js/description_2_team";
// import Description_3_team from "../component/js/description_3_team";
// import Description_4_team from "../component/js/description_4_team";
import "../style_app/Team.css";

class Team extends Component {
  componentDidMount() {
    document.title = "Chi Siamo";
  }
  render() {
    window.scrollTo(0, 0);
    return (
      <React.Fragment>
        <Photo_3_4_team />
        <div className="main_holder_team">
          <Intro_team />
          <br />
          <Photo_all />
          <br />
          <br />
          <br />
          <Row>
            <Col md="3" sm="6" xs="12">
              <h3
                style={{
                  color: "#304b5b",
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: "20px",
                  lineHeight: "24px"
                }}
              >
                {" "}
                <strong>PIERFEDERICO GARDINO</strong>
              </h3>
              <p
                style={{
                  color: "#757575"
                }}
              >
                Pierfederico esercita la Professione di Dottore Commercialista,
                iscritto all'Ordine di Torino. E' Amministratore Unico della
                SEVAT s.r.l. ed Amministratore Unico della G&amp;F Consulting
                Stp s.r.l.{" "}
              </p>
            </Col>
            <Col md="3" sm="6" xs="12">
              <h3
                style={{
                  color: "#304b5b",
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: "20px",
                  lineHeight: "24px"
                }}
              >
                {" "}
                <strong>LUCIA BERGAMIN</strong>
              </h3>
              <p
                style={{
                  color: "#757575"
                }}
              >
                Collaboratore G&amp;F Consulting{" "}
              </p>
            </Col>
            <Col md="3" sm="6" xs="12">
              <h3
                style={{
                  color: "#304b5b",
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: "20px",
                  lineHeight: "24px"
                }}
              >
                {" "}
                <strong>ELENA FIORAVANZO</strong>
              </h3>
              <p
                style={{
                  color: "#757575"
                }}
              >
                Collaboratore G&amp;F Consulting{" "}
              </p>
            </Col>
            <Col md="3" sm="6" xs="12">
              <h3
                style={{
                  color: "#304b5b",
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: "20px",
                  lineHeight: "24px"
                }}
              >
                {" "}
                <strong>MARTA BARALE</strong>
              </h3>
              <p
                style={{
                  color: "#757575"
                }}
              >
                Collaboratore G&amp;F Consulting{" "}
              </p>
            </Col>
          </Row>
          {/* <Description_1_team />
          <Description_2_team />
          <Description_3_team />
          <Description_4_team /> */}
        </div>
      </React.Fragment>
    );
  }
}

export default Team;
