import React, { Component } from "react";
import { connect } from "react-redux";
import { sendExcel } from "../../../actions/userActions";
import { getUsers } from "../../../actions/userActions";
import { fileError } from "../../../actions/userActions";
import { fileError2 } from "../../../actions/userActions";
import { getFiles } from "../../../actions/userActions";
import Load_file from "./Load_file";

class Add_Data extends Component {
  state = {
    files: null,
    file_nome: "",
    selected_user: "",
    anno: "",
    mese: ""
  };

  componentDidMount() {
    this.props.getUsers();
    this.props.getFiles();
  }

  onChangeFillIn = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onChange(e) {
    this.props.getFiles();
    let files = e.target.files;
    if (files.length !== 0) {
      this.setState({ files: files, file_nome: files[0].name });
      if (files[0].name.indexOf(".xls") > -1) {
      } else {
        this.props.fileError();
        this.setState({ files: null, file_nome: "" });
      }
    } else {
      this.setState({ files: null });
    }
  }

  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  handleUp() {
    const { files_all } = this.props;
    const { anno, files, selected_user, mese, file_nome } = this.state;

    let filteredClasses = files_all.filter(cls =>
      file_nome.includes(cls.file_nome)
    );

    if (filteredClasses.length == 0) {
      const date_long = new Date();
      const date =
        date_long.getDate() +
        "-" +
        (date_long.getMonth() + 1) +
        "-" +
        date_long.getFullYear();

      const date2 =
        date_long.getFullYear() +
        "-" +
        this.pad(date_long.getMonth() + 1) +
        "-" +
        this.pad(date_long.getDate());

      var mese_numero = 0;

      if (mese === "Gennaio") {
        mese_numero = 1;
      } else if (mese === "Febbraio") {
        mese_numero = 2;
      } else if (mese === "Marzo") {
        mese_numero = 3;
      } else if (mese === "Aprile") {
        mese_numero = 4;
      } else if (mese === "Maggio") {
        mese_numero = 5;
      } else if (mese === "Giugno") {
        mese_numero = 6;
      } else if (mese === "Luglio") {
        mese_numero = 7;
      } else if (mese === "Agosto") {
        mese_numero = 8;
      } else if (mese === "Settembre") {
        mese_numero = 9;
      } else if (mese === "Ottobre") {
        mese_numero = 10;
      } else if (mese === "Novembre") {
        mese_numero = 11;
      } else if (mese === "Dicembre") {
        mese_numero = 12;
      }

      const sub_key = selected_user.concat(anno, mese_numero, file_nome, date2);

      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = e => {
        const formData = {
          data: date,
          selected_user: selected_user,
          anno: anno,
          mese: mese,
          file: e.target.result,
          file_nome: file_nome,
          sub_key: sub_key
        };

        this.props.sendExcel(formData);
      };
    } else {
      this.props.fileError2();
    }
  }

  render() {
    const { files } = this.state;
    const { users, onload_file } = this.props;
    const { selected_user, anno, mese } = this.state;
    const date = new Date();
    const year = date.getFullYear();
    const fillRange = (start, end) => {
      return Array(end - start + 1)
        .fill()
        .map((item, index) => start + index);
    };

    const allYear = fillRange(2017, year);
    return (
      <React.Fragment>
        <br />
        <div className="form-group">
          <label htmlFor="sel_utente">Seleziona un utente</label>
          <select
            name="selected_user"
            onChange={this.onChangeFillIn}
            value={selected_user}
            className="form-control"
            id="sel_utente"
          >
            <option value="" selected disabled>
              Visualizza elenco...
            </option>
            {users.map(single_user => (
              <option key={single_user.id}>{single_user.username}</option>
            ))}
          </select>
          <br />
          {selected_user !== "" ? (
            <div>
              <label htmlFor="sel_utente2">
                Seleziona l'anno di riferimento
              </label>
              <select
                name="anno"
                onChange={this.onChangeFillIn}
                value={anno}
                className="form-control"
                id="sel_utente2"
              >
                <option value="" selected disabled>
                  Visualizza elenco...
                </option>
                {allYear.map(single_year => (
                  <option key={single_year}>{single_year}</option>
                ))}
              </select>
            </div>
          ) : null}
          <br />
          {anno !== "" ? (
            <div>
              <label htmlFor="sel_utente3">
                Seleziona il mese di riferimento
              </label>
              <select
                name="mese"
                onChange={this.onChangeFillIn}
                value={mese}
                className="form-control"
                id="sel_utente3"
              >
                <option value="" selected disabled>
                  Visualizza elenco...
                </option>
                <option>Gennaio</option>
                <option>Febbraio</option>
                <option>Marzo</option>
                <option>Aprile</option>
                <option>Maggio</option>
                <option>Giugno</option>
                <option>Luglio</option>
                <option>Agosto</option>
                <option>Settembre</option>
                <option>Ottobre</option>
                <option>Novembre</option>
                <option>Dicembre</option>
              </select>
            </div>
          ) : null}
          <br />
        </div>
        {mese !== "" ? (
          <div onSubmit={this.onFormSubmit}>
            <h3>Seleziona un file da caricare</h3>
            <input type="file" name="file" onChange={e => this.onChange(e)} />
            <br />
            <br />
            <br />
            {files !== null ? (
              <button
                type="button"
                className="btn btn-outline-primary btn-lg btn-block"
                onClick={e => this.handleUp(e)}
              >
                CARICA
              </button>
            ) : null}
            {onload_file ? <Load_file /> : null}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users.users,
  onload_file: state.users.onload_file,
  files_all: state.users.distinct_file
});

export default connect(
  mapStateToProps,
  { sendExcel, getUsers, fileError, fileError2, getFiles }
)(Add_Data);
