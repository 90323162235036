import React, { useState, useEffect } from "react";
import ReactExport from "react-data-export";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export const Download_component = ({ selected_data, detail, download }) => {
  const downloadClone = cloneDeep(download);
  const [selectedDownload, updateSelectedDownload] = useState(null);

  useEffect(() => {
    var dataset;
    if (detail.includes("No")) {
      dataset = [downloadClone[selected_data]];
    } else {
      dataset = [
        downloadClone[selected_data],
        downloadClone["detail_employees"]
      ];
    }
    updateSelectedDownload(dataset);
  }, [selected_data, detail]);

  return (
    <React.Fragment>
      <ExcelFile
        element={
          <Button size="lg" block color="success">
            Download
          </Button>
        }
      >
        <ExcelSheet dataSet={selectedDownload} name="Dati" />
      </ExcelFile>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  download: state.download
});

export default connect(mapStateToProps)(Download_component);
