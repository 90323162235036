import React, { Component } from "react";
import Manage_main from "../component/js/Manage_main";
import Manage_News from "../component/js/Manage_News";
import Manage_Data from "../component/js/Manage_Data";
import Manage_User from "../component/js/Manage_User";
import { BrowserRouter as Router, Route } from "react-router-dom";

class Manage extends Component {
  render() {
    return (
      <React.Fragment>
        <Route path="/manage/home" component={Manage_main} />
        <Route path="/manage/manage_news" component={Manage_News} />
        <Route path="/manage/manage_data" component={Manage_Data} />
        <Route path="/manage/create_user" component={Manage_User} />
      </React.Fragment>
    );
  }
}

export default Manage;
