import React from "react";
import "../css/photo_3_4_perche_noi.css";

export default function photo_3_4() {
  return (
    <div className="perche_noi_page">
      <h1
        className="perche_noi_h1"
        data-aos="fade-left"
        data-aos-duration="2000"
      >
        PERCHE' NOI
      </h1>
      <h2 className="perche_noi_h2" data-aos="fade-up" data-aos-duration="1500">
        Tutti i dati sempre con te
      </h2>
    </div>
  );
}
