import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { selectPage } from "../../actions/clientActions";
import DettagliTable from "../../components/component/js/DettagliTable";
import cloneDeep from "lodash/cloneDeep";

export const Dettagli = ({
  all_employees,
  selected_year,
  selected_page,
  selectPage
}) => {
  const all_employeesClone = cloneDeep(all_employees);
  const [selectedOption, updateSelectedOption] = useState("Tutti");
  const [selectedData, updateSelectedData] = useState(null);

  const mounted = useRef();
  selectPage("DETTAGLI");

  useEffect(() => {
    updateSelectedData(all_employeesClone);
  }, [selected_page]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (selectedOption === "Tutti") {
        updateSelectedData(all_employeesClone);
      } else if (selectedOption === "Solo attivi") {
        var filtereData = all_employeesClone.filter(obj => {
          if (obj.attivo === true) {
            return obj;
          }
        });
        updateSelectedData(filtereData);
      } else if (selectedOption === "Solo cessati") {
        var filtereData = all_employeesClone.filter(obj => {
          if (obj.attivo === false) {
            return obj;
          }
        });
        updateSelectedData(filtereData);
      }
    }
  }, [selectedOption]);

  return (
    <div className="content" style={{ marginTop: "0", paddingBottom: "0px" }}>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Elenco dipendenti anno {selected_year}
                <h5
                  className="card-category"
                  style={{
                    textTransform: "none",
                    marginBottom: "15px"
                  }}
                >
                  Visualizza dettagli buste paga
                </h5>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div>
                <Row>
                  <Col lg="3">
                    <div>
                      <label htmlFor="sel_data">Stato contratto</label>
                      <select
                        name="selected_data"
                        onChange={e => updateSelectedOption(e.target.value)}
                        value={selectedOption}
                        className="form-control"
                        id="sel_data"
                      >
                        <option value="" selected disabled>
                          Visualizza elenco...
                        </option>
                        <option key="tutti">Tutti</option>
                        <option key="attivi">Solo attivi</option>
                        <option key="cessati">Solo cessati</option>
                      </select>
                      <br />
                    </div>
                  </Col>
                </Row>
              </div>
              {selectedData !== null ? (
                <DettagliTable
                  dataPassed={selectedData}
                  columnsPassed={[
                    { name: "nome", title: "Nome" },
                    { name: "unita_locale", title: "Unità locale" },
                    { name: "qualifica", title: "Qualifica" },
                    { name: "attivo", title: "Info" }
                  ]}
                  defaultSorting={[{ columnName: "nome", direction: "asc" }]}
                  defaultOrder={["nome", "unita_locale", "qualifica", "attivo"]}
                  columnAlignment={[
                    { columnName: "nome", align: "left" },
                    { columnName: "unita_locale", align: "left" },
                    { columnName: "qualifica", align: "center" },
                    { columnName: "attivo", align: "center" }
                  ]}
                />
              ) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  all_employees: state.dettagli.all_employees.rows,
  selected_year: state.client.selected_year,
  selected_page: state.client.selected_page
});

export default connect(mapStateToProps, {
  selectPage
})(Dettagli);
