import React from "react";
import {
  Card,
  CardFooter,
  CardBody,
  CardTitle,
  CardText,
  CardSubtitle,
} from "reactstrap";
import Linkedin from "../../photos/linkedin.png";
import { Link } from "react-router-dom";

import "../layout/Footer.css";

const Footer = (props) => {
  return (
    <div>
      <Card
        body
        inverse
        style={{ backgroundColor: "#006280", borderColor: "#006280" }}
      >
        <CardFooter style={{ paddingLeft: "0", paddingRight: "0" }}>
          <CardBody style={{ padding: "0%", margin: "0 10%" }}>
            <div className="row" style={{ textAlign: "center" }}>
              <div className="col-md-3">
                <p className="p_footer">POLICY</p>
                <hr
                  style={{
                    width: "20%",
                    margin: "10px auto 10px auto",
                    backgroundColor: "#fff",
                  }}
                />
                <p style={{ margin: "0" }}>
                  <a href="/privacy" style={{ color: "white" }}>
                    Privacy Policy
                  </a>
                </p>
                <p style={{ margin: "0" }}>
                  <a href="/cookie" style={{ color: "white" }}>
                    Cookie Policy
                  </a>
                </p>
              </div>
              <div className="col-md-3">
                <p className="p_footer">LINK UTILI</p>
                <hr
                  style={{
                    width: "20%",
                    margin: "10px auto 10px auto",
                    backgroundColor: "#fff",
                  }}
                />
                <p style={{ margin: "0" }}>
                  <a href="https://sevatsrl.it/" style={{ color: "white" }}>
                    SEVAT srl
                  </a>
                </p>
                <p style={{ margin: "0" }}>
                  <a
                    href="https://www.gdpanalytics.com/index.php"
                    style={{ color: "white" }}
                  >
                    GDP Analytics srl
                  </a>
                </p>
              </div>
              <div className="col-md-3">
                <p className="p_footer">RECAPITI</p>
                <hr
                  style={{
                    width: "20%",
                    margin: "10px auto 10px auto",
                    backgroundColor: "#fff",
                  }}
                />
                {/* <p style={{ margin: "0" }}>
                  <Link to="/contatti" style={{ color: "white" }}>
                    Inviaci una email
                  </Link>
                </p> */}
                <p style={{ margin: "0" }}>011 433 0333</p>
                <p style={{ margin: "0" }}>Via Berruti e Ferrero 10, Torino</p>
              </div>
              <div className="col-md-3">
                <p className="p_footer">SEGUICI</p>
                <hr
                  style={{
                    width: "20%",
                    margin: "10px auto 10px auto",
                    backgroundColor: "#fff",
                  }}
                />
                <a href="https://www.linkedin.com/company/g-f-consulting-stp-s-r-l/about/">
                  <img
                    src={Linkedin}
                    alt=""
                    className="logo_footer"
                    style={{ marginTop: "5px" }}
                  />
                </a>
              </div>
            </div>
          </CardBody>
        </CardFooter>
      </Card>
    </div>
  );
};

export default Footer;
