import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { Line } from "react-chartjs-2";

export const StoricoChart = ({ labels_chart, dataset }) => {
  const [dataMultiline, updateDataMultiline] = useState({
    data: null,
    options: null
  });

  useEffect(() => {
    let chart_multiple_options = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.0)",
              zeroLineColor: "transparent"
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e"
            }
          }
        ],
        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(0,242,195,0.1)",
              zeroLineColor: "transparent"
            },
            ticks: {
              padding: 20,
              fontColor: "#9e9e9e"
            }
          }
        ]
      }
    };

    var dataToUpdate = {
      data: canvas => {
        let ctx = canvas.getContext("2d");
        let gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);
        let gradientStroke2 = ctx.createLinearGradient(0, 230, 0, 50);
        let gradientStroke3 = ctx.createLinearGradient(0, 230, 0, 50);
        let gradientStroke4 = ctx.createLinearGradient(0, 230, 0, 50);
        gradientStroke1.addColorStop(1, "rgba(29,140,248,0.15)");
        gradientStroke1.addColorStop(0.4, "rgba(29,140,248,0.0)"); //blu colors
        gradientStroke1.addColorStop(0, "rgba(29,140,248,0)"); //blu colors
        gradientStroke2.addColorStop(1, "rgba(66,134,121, 0.4)");
        gradientStroke2.addColorStop(0.4, "rgba(66,134,121, 0.0)"); //green colors
        gradientStroke2.addColorStop(0, "rgba(66,134,121, 0.0)"); //green colors
        gradientStroke3.addColorStop(1, "rgba(255, 148, 120, 0.4)");
        gradientStroke3.addColorStop(0.4, "rgba(255, 148, 120, 0.0)"); //red colors
        gradientStroke3.addColorStop(0, "rgba(255, 148, 120, 0.0)"); //red colors
        gradientStroke4.addColorStop(1, "rgba(25, 240, 255, 0.4)");
        gradientStroke4.addColorStop(0.4, "rgba(25, 240, 255, 0.0)"); //light blue colors
        gradientStroke4.addColorStop(0, "rgba(25, 240, 255, 0.0)"); //light blue colors
        var gradientStrokeArray = [
          gradientStroke1,
          gradientStroke2,
          gradientStroke3,
          gradientStroke4
        ];
        var colorsArray = ["#1f8ef1", "#00d6b4", "#d24419", "#19F0FF"];
        var obj_dataset;
        var datasetArray = Object.keys(dataset).map(function(str, index) {
          obj_dataset = {
            label: str,
            fill: true,
            backgroundColor: gradientStrokeArray[index],
            borderColor: colorsArray[index],
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: colorsArray[index],
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: colorsArray[index],
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataset[str].values
          };
          return obj_dataset;
        });
        return {
          labels: labels_chart,
          datasets: datasetArray
        };
      },
      options: chart_multiple_options
    };
    updateDataMultiline(dataToUpdate);
  }, [labels_chart, dataset]);

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card className="card-chart">
            <CardBody>
              <div className="chart-area">
                {dataMultiline.data !== null ? (
                  <Line
                    data={dataMultiline.data}
                    options={dataMultiline.options}
                  />
                ) : null}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default StoricoChart;
