import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import StoricoChart from "../../components/component/js/StoricoChart";

export const StoricoChart_component = ({ selected_data, detail, storico }) => {
  const storicoClone = cloneDeep(storico);
  const [selectedStorico, updateSelectedStorico] = useState(null);

  useEffect(() => {
    var dataset = storicoClone[selected_data][detail];
    updateSelectedStorico(dataset);
  }, [selected_data, detail]);

  return (
    <React.Fragment>
      {selectedStorico !== null ? (
        selected_data === "overall" ? (
          <div>
            <h4 style={{ marginBottom: "15px" }}>Azienda</h4>
            <StoricoChart
              key={"overall"}
              labels_chart={storico.labels}
              dataset={selectedStorico}
            />
          </div>
        ) : (
          Object.keys(selectedStorico).map(str => {
            return (
              <div key={str}>
                <h4 style={{ marginBottom: "15px" }}>{str}</h4>
                <StoricoChart
                  key={str}
                  labels_chart={storico.labels}
                  dataset={selectedStorico[str]}
                />
              </div>
            );
          })
        )
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  storico: state.storico
});

export default connect(mapStateToProps)(StoricoChart_component);
