import { ALL_DATA_DOWNLOAD } from "../actions/types";

const initialState = {
  overall: null,
  unita_locale: null,
  qualifica: null,
  detail_employees: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ALL_DATA_DOWNLOAD:
      return {
        ...state,
        overall: action.payload.overall,
        unita_locale: action.payload.unita_locale,
        qualifica: action.payload.qualifica,
        detail_employees: action.payload.detail_employees
      };
    default:
      return state;
  }
}
