import React from "react";
import "../css/photo_3_4_servizi.css";

export default function photo_3_4() {
  return (
    <div className="servizi_page">
      <h1 className="servizi_h1" data-aos="fade-left" data-aos-duration="2000">
        I NOSTRI SERVIZI
      </h1>
      <h2 className="servizi_h2" data-aos="fade-up" data-aos-duration="1500">
        Una risposta ad ogni esigenza
      </h2>
    </div>
  );
}
