import { ALL_DATA_DETTAGLI } from "../actions/types";

const initialState = {
  all_employees: null,
  all_buste_paga: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ALL_DATA_DETTAGLI:
      return {
        ...state,
        all_employees: action.payload.all_employees,
        all_buste_paga: action.payload.all_buste_paga
      };
    default:
      return state;
  }
}
