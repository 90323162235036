import {
  MODAL_SUCCESS_GLOBAL,
  GET_ERRORS,
  GET_ALL_USERS,
  SELECT_USER_ID,
  MODAL_DELETE_USER,
  DELETE_USER,
  MODAL_DELETE_CLOSE_USER,
  ONLOAD_FILE_HANDLE,
  GET_ALL_DATA,
  MODAL_DELETE_FILE,
  SELECT_FILE,
  MODAL_DELETE_CLOSE_FILE,
  DELETE_FILE,
  ADD_FILE_TO_STATE,
  API_URL
} from "./types";

import axios from "axios";

export const sendUser = (username, email, password) => async dispatch => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  // Request Body
  const body = JSON.stringify({ username, email, password });

  try {
    await axios.post(`${API_URL}/auth/register`, body, config);
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
    // dispatch({
    //   type: LOGIN_FAIL
    // });
  }
};

export const getUsers = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/auth/all_users`,
      tokenConfig(getState)
    );

    dispatch({
      type: GET_ALL_USERS,
      payload: res.data
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
  }
};

export const selectId = id => async dispatch => {
  dispatch({
    type: SELECT_USER_ID,
    payload: id
  });
};

export const openDelete = () => async dispatch => {
  dispatch({
    type: MODAL_DELETE_USER
  });
};

export const closeDelete = selected_user_id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/auth/delete/${selected_user_id}/`,
      tokenConfig(getState)
    );

    dispatch({
      type: DELETE_USER,
      payload: selected_user_id
    });
    dispatch({
      type: MODAL_DELETE_CLOSE_USER
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
  }
};

export const closeDeleteModal = () => async dispatch => {
  dispatch({
    type: MODAL_DELETE_CLOSE_USER
  });
};

export const sendExcel = formData => async (dispatch, getState) => {
  dispatch({
    type: ONLOAD_FILE_HANDLE
  });
  try {
    await axios.post(`${API_URL}/dipendenti/`, formData, tokenConfig(getState));

    const formData2 = {
      file_nome: formData.file_nome,
      data: formData.data,
      mese: formData.mese,
      anno: formData.anno,
      my_owner: formData.selected_user,
      my_key: formData.sub_key
    };
    // why add to state? Because i can check the next file_nome in the same window without doing getFiles
    dispatch({
      type: ADD_FILE_TO_STATE,
      payload: formData2
    });
    dispatch({
      type: ONLOAD_FILE_HANDLE
    });
  } catch (err) {
    dispatch({
      type: ONLOAD_FILE_HANDLE
    });
    const errors = {
      msg: err.response.data,
      status: err.response.status
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
    // dispatch({
    //   type: LOGIN_FAIL
    // });
  }
};

export const fileError = () => async dispatch => {
  const errors = {
    msg: "File non supportato",
    status: 500
  };
  dispatch({
    type: GET_ERRORS,
    payload: errors
  });
};

export const fileError2 = () => async dispatch => {
  const errors = {
    msg:
      "Esiste già un file con lo stesso nome. Assicurati di non voler inserire lo stesso file, oppure rinominalo.",
    status: 500
  };
  dispatch({
    type: GET_ERRORS,
    payload: errors
  });
};

export const getFiles = () => async (dispatch, getState) => {
  try {
    const res_partial = await axios.get(
      `${API_URL}/dipendenti`,
      tokenConfig(getState)
    );

    function compare(a, b) {
      const newsA = a.data;
      const newsB = b.data;

      let comparison = 0;
      if (newsA > newsB) {
        comparison = -1;
      } else if (newsA < newsB) {
        comparison = 1;
      }
      return comparison;
    }

    const res = res_partial.data.sort(compare);

    dispatch({
      type: GET_ALL_DATA,
      payload: res
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
  }
};

export const DeleteFile = () => async dispatch => {
  dispatch({
    type: MODAL_DELETE_FILE
  });
};

// export const openDelete = () => async dispatch => {
//   dispatch({
//     type: MODAL_DELETE
//   });
// };

export const selectId2 = id => async dispatch => {
  dispatch({
    type: SELECT_FILE,
    payload: id
  });
};

export const closeDeleteModalFile = () => async dispatch => {
  dispatch({
    type: MODAL_DELETE_CLOSE_FILE
  });
};

export const closeDeleteFile = my_body => async (dispatch, getState) => {
  try {
    await axios.delete(`${API_URL}/dipendenti/`, {
      data: my_body,
      headers: tokenConfig(getState).headers
    });

    dispatch({
      type: DELETE_FILE,
      payload: my_body.file_nome
    });
    dispatch({
      type: MODAL_DELETE_CLOSE_FILE
    });
  } catch (err) {
    dispatch({
      type: MODAL_DELETE_CLOSE_FILE
    });
    const errors = {
      msg: "Operazione non riuscita!",
      status: 404
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
  }
};

export const sendEmail = formData => async dispatch => {
  try {
    await axios.post(`${API_URL}/test_dash/`, formData);
    dispatch({
      type: MODAL_SUCCESS_GLOBAL
    });
  } catch (err) {
    dispatch({
      type: ONLOAD_FILE_HANDLE
    });
    const errors = {
      msg: err.response.data,
      status: err.response.status
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
    // dispatch({
    //   type: LOGIN_FAIL
    // });
  }
};

export const tokenConfig = getState => {
  // get token from state
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};
