import { GET_NEWS, MODAL_SUCCESS_CLOSING } from "./types";
import {
  MODAL_SUCCESS,
  MODAL_MODIFY,
  MODAL_MODIFY_CLOSE,
  DELETE_NEWS,
  MODAL_DELETE,
  MODAL_DELETE_CLOSE,
  SELECT_ID,
  MODIFY_NEWS,
  UPDATE_NEWS,
  GET_ERRORS,
  API_URL
} from "./types";
import axios from "axios";

export const getNews = () => async dispatch => {
  const res_partial = await axios.get(`${API_URL}/news`);

  function compare(a, b) {
    const newsA = a.created_at;
    const newsB = b.created_at;

    let comparison = 0;
    if (newsA > newsB) {
      comparison = -1;
    } else if (newsA < newsB) {
      comparison = 1;
    }
    return comparison;
  }

  const res = res_partial.data.sort(compare);
  dispatch({
    type: GET_NEWS,
    payload: res
  });
};

export const sendNews = (title, text) => async dispatch => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  // Request Body
  const body = JSON.stringify({ title, text });

  try {
    await axios.post(`${API_URL}/news/`, body, config);

    dispatch({
      type: MODAL_SUCCESS
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
  }
};

export const closeModal = () => async dispatch => {
  dispatch({
    type: MODAL_SUCCESS_CLOSING
  });
};

export const openModify = () => async dispatch => {
  dispatch({
    type: MODAL_MODIFY
  });
};

export const openDelete = () => async dispatch => {
  dispatch({
    type: MODAL_DELETE
  });
};

export const closeDelete = select_id => async dispatch => {
  try {
    await axios.delete(`${API_URL}/news/${select_id}/`);

    dispatch({
      type: DELETE_NEWS,
      payload: select_id
    });
    dispatch({
      type: MODAL_DELETE_CLOSE
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
  }
};

export const selectId = id => async dispatch => {
  dispatch({
    type: SELECT_ID,
    payload: id
  });
};

export const closeDeleteModal = () => async dispatch => {
  dispatch({
    type: MODAL_DELETE_CLOSE
  });
};

export const closeModify = () => async dispatch => {
  dispatch({
    type: MODAL_MODIFY_CLOSE
  });
};

export const getNewsToModify = id => async dispatch => {
  try {
    dispatch({
      type: MODIFY_NEWS,
      payload: id
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
  }
};

export const updateNews = (select_id, title, text) => async dispatch => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  // Request Body
  const body = JSON.stringify({ title, text });

  try {
    const res = await axios.put(`${API_URL}/news/${select_id}/`, body, config);

    dispatch({
      type: UPDATE_NEWS,
      payload: res.data
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors
    });
  }
};
