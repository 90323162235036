import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  CustomInput,
  Form,
  FormGroup
} from "reactstrap";
import { Line } from "react-chartjs-2";
import { handleChartTotali } from "../../../actions/clientActions";
import { annullaChartTotali } from "../../../actions/clientActions";
import { updateAnnullaTotali } from "../../../actions/clientActions";
import { removeTabChartTotali } from "../../../actions/clientActions";
import { connect } from "react-redux";

export const MultilineComponentTotali = ({
  data,
  options,
  selected_columns_totali,
  handleChartTotali,
  annullaChartTotali,
  removeTabChartTotali,
  multiline,
  all_checkboxes,
  updateAnnullaTotali
}) => {
  const insertColumnTotali = elem => {
    handleChartTotali(elem);
  };

  const removeTab = elem => {
    removeTabChartTotali(elem);
  };

  const annullaInsert = () => {
    annullaChartTotali();
  };

  const updateChart = () => {
    updateAnnullaTotali();
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card className="card-chart">
            <CardHeader>
              <Row style={{ marginTop: "4px" }}>
                <Col lg="11" md="11" sm="11" xs="10" style={{ height: "25px" }}>
                  <h5
                    className="card-category"
                    style={{ textTransform: "none" }}
                  >
                    Seleziona i costi
                  </h5>
                </Col>
                <Col
                  lg="1"
                  md="1"
                  sm="1"
                  xs="2"
                  style={{
                    height: "25px",
                    position: "absolute",
                    top: "15px",
                    right: "0px"
                  }}
                >
                  <CardTitle tag="h3">
                    <i
                      data-toggle="modal"
                      data-target="#exampleModalChart"
                      className="tim-icons icon-simple-add text-success"
                      data-placement="bottom"
                      title="Aggiungi elemento"
                      style={{
                        cursor: "pointer",
                        fontSize: "1em",
                        marginTop: "15px"
                      }}
                    />
                    <div
                      className="modal fade"
                      id="exampleModalChart"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                      data-backdrop="static"
                      data-keyboard="false"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title" id="exampleModalLabel">
                              Aggiungi uno o più elementi
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={annullaInsert}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <Form>
                              <FormGroup>
                                <div>
                                  {all_checkboxes.map(elem =>
                                    elem === "Tutte" ? null : (
                                      <CustomInput
                                        type="checkbox"
                                        id={elem}
                                        label={elem}
                                        key={elem}
                                        onClick={() => insertColumnTotali(elem)}
                                        checked={
                                          selected_columns_totali.includes(elem)
                                            ? true
                                            : false
                                        }
                                        className="darkLabel"
                                      />
                                    )
                                  )}
                                </div>
                              </FormGroup>
                            </Form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                              onClick={annullaInsert}
                            >
                              Annulla
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-dismiss="modal"
                              onClick={updateChart}
                            >
                              Applica
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardTitle>
                </Col>
              </Row>

              <CardTitle tag="h3">
                <i
                  className="tim-icons icon-sound-wave text-success"
                  style={{
                    marginRight: "10px"
                  }}
                />
                Costo dipendenti
              </CardTitle>

              <Row style={{ marginTop: "20px" }}>
                {selected_columns_totali.map(single_elem => (
                  <Col lg="3" md="4" sm="6" style={{ height: "45px" }}>
                    <Card>
                      <CardBody style={{ padding: "5px 5px" }}>
                        <CardTitle style={{ marginBottom: "0px" }}>
                          <Button
                            close
                            style={{ color: "gray" }}
                            onClick={() => removeTab(single_elem)}
                          />
                        </CardTitle>
                        <CardText
                          style={{ fontSize: "14px", lineHeight: "18px" }}
                        >
                          {single_elem}
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </CardHeader>
            <CardBody>
              <div className="chart-area">
                {multiline !== null ? (
                  selected_columns_totali.length === 0 ? (
                    <div style={{ marginLeft: "18px" }}>
                      <h4>
                        Inserire almeno un elemento per visualizzare il grafico
                      </h4>
                    </div>
                  ) : selected_columns_totali.length >= 5 ? (
                    <div style={{ marginLeft: "18px" }}>
                      <h4>
                        Attenzione: per una resa ottimale del grafico inserire
                        massimo 4 elementi per volta
                      </h4>
                    </div>
                  ) : (
                    <Line data={data} options={options} />
                  )
                ) : null}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  multiline: state.home.multiline,
  selected_columns_totali: state.client.selected_columns_totali
});

export default connect(mapStateToProps, {
  handleChartTotali,
  annullaChartTotali,
  updateAnnullaTotali,
  removeTabChartTotali
})(MultilineComponentTotali);
