import React from "react";
import classNames from "classnames";
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Navbar,
  Nav,
  Container,
  Modal,
  Dropdown
} from "reactstrap";
import { connect } from "react-redux";
import { getAllData } from "../../../actions/clientActions";
import { selectYear } from "../../../actions/clientActions";
import { logout } from "../../../actions/auth";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.selectYear = this.selectYear.bind(this);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent",
      dropdownOpen: false
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
    this.props.getAllData();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      });
    } else {
      this.setState({
        color: "bg-white"
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };

  selectYear(selected_year) {
    this.props.selectYear(selected_year);
    this.props.history.push("/admin/dashboard");
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    const { distinct_year, selected_year } = this.props;
    return (
      <React.Fragment>
        <h4
          style={{
            marginTop: window.innerWidth > 990 ? "28px" : "20px",
            marginBottom: window.innerWidth > 990 ? "0px" : "-45px",
            color: "white",
            marginLeft: window.innerWidth > 990 ? "25px" : "65px",
            fontWeight: "bold",
            position: window.innerWidth > 990 ? "fixed" : "static"
          }}
        >
          {this.props.selected_page.toUpperCase()}
        </h4>
        <Navbar
          className={classNames("navbar-absolute", this.state.color)}
          expand="lg"
          style={{ paddingTop: window.innerWidth > 990 ? "10px" : "20px" }}
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: this.props.sidebarOpened
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.props.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
            </div>
            <button
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navigation"
              // data-toggle="collapse"
              id="navigation"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse navbar isOpen={this.state.collapseOpen}>
              <Nav className="ml-auto" navbar>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                  <DropdownToggle
                    caret
                    style={{
                      padding: "15px 25px 15px 15px",
                      margin: "5px 15px 0px 0px",
                      background: "rgba(0,0,0,0.2)"
                    }}
                  >
                    {selected_year}
                    <b
                      className="caret d-none d-lg-block d-xl-block"
                      style={{ marginLeft: "-5px", marginTop: "-5px" }}
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    {distinct_year.map(single_year =>
                      single_year == selected_year ? (
                        <DropdownItem
                          className="disabled"
                          key={single_year}
                          onClick={() => this.selectYear(single_year)}
                        >
                          {single_year}
                        </DropdownItem>
                      ) : (
                        <DropdownItem
                          key={single_year}
                          onClick={() => this.selectYear(single_year)}
                        >
                          {single_year}
                        </DropdownItem>
                      )
                    )}
                  </DropdownMenu>
                </Dropdown>
                <div>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={this.props.logout}
                    style={{
                      textAlign: "center",
                      marginTop: "8px",
                      padding: "11px 40px",
                      overflow: "visible",
                      backgroundImage:
                        "linear-gradient(to bottom left, #f67070, #db3157,#cc0132)"
                    }}
                  >
                    Logout
                  </button>
                </div>

                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <Modal
          modalClassName="modal-search"
          isOpen={this.state.modalSearch}
          toggle={this.toggleModalSearch}
        >
          <div className="modal-header">
            <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalSearch}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  distinct_year: state.client.distinct_year,
  selected_year: state.client.selected_year,
  selected_page: state.client.selected_page
});

export default connect(mapStateToProps, { getAllData, selectYear, logout })(
  AdminNavbar
);
