import React, { useState, useEffect, useRef } from "react";
import { Line, Pie } from "react-chartjs-2";
import { connect } from "react-redux";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { selectPage } from "../../actions/clientActions";
import MultilineComponent from "../../components/component/js/MultilineComponent";
import cloneDeep from "lodash/cloneDeep";

export const Dashboard = ({
  home,
  selected_columns,
  selected_year,
  selectPage
}) => {
  const homeClone = cloneDeep(home);
  const [dataMultiline, updateDataMultiline] = useState({
    data: null,
    options: null
  });
  const [dataNumEmployees, updateDataNumEmployees] = useState({
    data: null,
    options: null
  });
  const [dataNumIndet, updateDataNumIndet] = useState({
    data: null,
    options: null
  });
  const [dataRapportoAll, updateDataRapportoAll] = useState({
    data: null,
    options: null
  });
  const [dataFtPtAll, updateDataFtPtAll] = useState({
    data: null,
    options: null
  });

  const mounted = useRef();
  useEffect(() => {
    if (homeClone.multiline !== null) {
      mounted.current = true;
    }
    if (!mounted.current) {
      mounted.current = true;
    } else {
      selectPage("HOME");
      let chart_lines_options = {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest"
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.0)",
                zeroLineColor: "transparent"
              },
              ticks: {
                padding: 20,
                fontColor: "#9a9a9a"
              }
            }
          ],
          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.1)",
                zeroLineColor: "transparent"
              },
              ticks: {
                padding: 20,
                fontColor: "#9a9a9a"
              }
            }
          ]
        }
      };
      var num_employees_chart = {
        data: canvas => {
          let ctx = canvas.getContext("2d");
          let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
          gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
          gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
          gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
          return {
            labels: homeClone.num_employees.labels,
            datasets: [
              {
                label: "N. Dipendenti",
                fill: true,
                backgroundColor: gradientStroke,
                borderColor: "#1f8ef1",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: "#1f8ef1",
                pointBorderColor: "rgba(255,255,255,0)",
                pointHoverBackgroundColor: "#1f8ef1",
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: homeClone.num_employees.values
              }
            ]
          };
        },
        options: chart_lines_options
      };
      updateDataNumEmployees(num_employees_chart);
      var num_indet_chart = {
        data: canvas => {
          let ctx = canvas.getContext("2d");
          let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
          gradientStroke.addColorStop(1, "rgba(66,134,121,0.15)");
          gradientStroke.addColorStop(0.4, "rgba(66,134,121,0.0)"); //green colors
          gradientStroke.addColorStop(0, "rgba(66,134,121,0)"); //green colors
          return {
            labels: homeClone.num_indet.labels,
            datasets: [
              {
                label: "A tempo indet.",
                fill: true,
                backgroundColor: gradientStroke,
                borderColor: "#00d6b4",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: "#00d6b4",
                pointBorderColor: "rgba(255,255,255,0)",
                pointHoverBackgroundColor: "#00d6b4",
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: homeClone.num_indet.values
              }
            ]
          };
        },
        options: chart_lines_options
      };
      updateDataNumIndet(num_indet_chart);
      var rapporto_all_chart = {
        data: canvas => {
          let ctx = canvas.getContext("2d");
          let gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);
          let gradientStroke2 = ctx.createLinearGradient(0, 230, 0, 50);
          gradientStroke1.addColorStop(1, "rgba(29,140,248,0.5)");
          gradientStroke1.addColorStop(0.4, "rgba(29,140,248,0.0)");
          gradientStroke1.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
          gradientStroke2.addColorStop(1, "rgba(83, 51, 237, 0.4)");
          gradientStroke2.addColorStop(0.4, "rgba(83, 51, 237, 0.0)");
          gradientStroke2.addColorStop(0, "rgba(83, 51, 237, 0.0)"); //blue colors
          return {
            labels: homeClone.rapporto_all.labels,
            datasets: [
              {
                fill: true,
                backgroundColor: [gradientStroke1, gradientStroke2],
                borderColor: "#1f8ef1",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: "#1f8ef1",
                pointBorderColor: "rgba(255,255,255,0)",
                pointHoverBackgroundColor: "#1f8ef1",
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: homeClone.rapporto_all.values
              }
            ]
          };
        },
        options: {
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          tooltips: {
            backgroundColor: "#f5f5f5",
            titleFontColor: "#333",
            bodyFontColor: "#666",
            bodySpacing: 4,
            xPadding: 12,
            mode: "nearest",
            intersect: 0,
            position: "nearest"
          },
          responsive: true
        }
      };
      updateDataRapportoAll(rapporto_all_chart);
      let chart_multiple_options = {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest"
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.0)",
                zeroLineColor: "transparent"
              },
              ticks: {
                padding: 20,
                fontColor: "#9e9e9e"
              }
            }
          ],
          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(0,242,195,0.1)",
                zeroLineColor: "transparent"
              },
              ticks: {
                padding: 20,
                fontColor: "#9e9e9e"
              }
            }
          ]
        }
      };
      if (selected_columns.length >= 1 && selected_columns.length <= 4) {
        var dataToUpdate = {
          data: canvas => {
            let ctx = canvas.getContext("2d");
            let gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);
            let gradientStroke2 = ctx.createLinearGradient(0, 230, 0, 50);
            let gradientStroke3 = ctx.createLinearGradient(0, 230, 0, 50);
            let gradientStroke4 = ctx.createLinearGradient(0, 230, 0, 50);
            gradientStroke1.addColorStop(1, "rgba(29,140,248,0.15)");
            gradientStroke1.addColorStop(0.4, "rgba(29,140,248,0.0)"); //blu colors
            gradientStroke1.addColorStop(0, "rgba(29,140,248,0)"); //blu colors
            gradientStroke2.addColorStop(1, "rgba(66,134,121, 0.4)");
            gradientStroke2.addColorStop(0.4, "rgba(66,134,121, 0.0)"); //green colors
            gradientStroke2.addColorStop(0, "rgba(66,134,121, 0.0)"); //green colors
            gradientStroke3.addColorStop(1, "rgba(255, 148, 120, 0.4)");
            gradientStroke3.addColorStop(0.4, "rgba(255, 148, 120, 0.0)"); //red colors
            gradientStroke3.addColorStop(0, "rgba(255, 148, 120, 0.0)"); //red colors
            gradientStroke4.addColorStop(1, "rgba(25, 240, 255, 0.4)");
            gradientStroke4.addColorStop(0.4, "rgba(25, 240, 255, 0.0)"); //light blue colors
            gradientStroke4.addColorStop(0, "rgba(25, 240, 255, 0.0)"); //light blue colors
            var gradientStrokeArray = [
              gradientStroke1,
              gradientStroke2,
              gradientStroke3,
              gradientStroke4
            ];
            var colorsArray = ["#1f8ef1", "#00d6b4", "#d24419", "#19F0FF"];
            var obj_dataset;
            var datasetArray = selected_columns.map(function(str, index) {
              obj_dataset = {
                label: str,
                fill: true,
                backgroundColor: gradientStrokeArray[index],
                borderColor: colorsArray[index],
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: colorsArray[index],
                pointBorderColor: "rgba(255,255,255,0)",
                pointHoverBackgroundColor: colorsArray[index],
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: homeClone.multiline[selected_columns[index]].values
              };
              return obj_dataset;
            });
            return {
              labels: homeClone.multiline[selected_columns[0]].labels,
              datasets: datasetArray
            };
          },
          options: chart_multiple_options
        };
        updateDataMultiline(dataToUpdate);
      }
      var ftpt_all_chart = {
        data: canvas => {
          let ctx = canvas.getContext("2d");
          let gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);
          let gradientStroke2 = ctx.createLinearGradient(0, 230, 0, 50);
          gradientStroke1.addColorStop(1, "rgba(66,134,121, 0.4)");
          gradientStroke1.addColorStop(0.4, "rgba(66,134,121, 0.0)"); //green colors
          gradientStroke1.addColorStop(0, "rgba(66,134,121, 0.0)"); //green colors
          gradientStroke2.addColorStop(1, "rgba(255, 100, 0, 0.4)");
          gradientStroke2.addColorStop(0.4, "rgba(255, 100, 0, 0.0)"); //red colors
          gradientStroke2.addColorStop(0, "rgba(255, 100, 0, 0.0)"); //red colors
          var gradientStrokeArray = [gradientStroke1, gradientStroke2];
          var colorsArray = ["#00d6b4", "#FF6400"];
          var obj_dataset;
          var datasetArray = ["Full-time", "Part-time"].map(function(
            str,
            index
          ) {
            obj_dataset = {
              label: str,
              fill: true,
              backgroundColor: gradientStrokeArray[index],
              borderColor: colorsArray[index],
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: colorsArray[index],
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: colorsArray[index],
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: homeClone.ftpt_all.values[index]
            };
            return obj_dataset;
          });
          return {
            labels: homeClone.ftpt_all.labels,
            datasets: datasetArray
          };
        },
        options: chart_multiple_options
      };
      updateDataFtPtAll(ftpt_all_chart);
    }
  }, [home, selected_columns]);
  return (
    <>
      <div
        className="content"
        style={{ marginTop: "0", marginBottom: "0px", paddingBottom: "0px" }}
      >
        <MultilineComponent
          data={dataMultiline.data}
          options={dataMultiline.options}
        ></MultilineComponent>

        <Row>
          <Col lg="6">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Numero Dipendenti</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-notes text-info" />
                  {home.num_employees !== null
                    ? home.num_employees.total
                    : null}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {home.num_employees !== null ? (
                    <Line
                      data={dataNumEmployees.data}
                      options={dataNumEmployees.options}
                    />
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">A tempo indeterminato</h5>
                <CardTitle tag="h3">
                  <i
                    className="tim-icons icon-money-coins text-success"
                    style={{ marginRight: "10px" }}
                  />
                  {home.num_indet !== null ? home.num_indet.total : null}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {home.num_indet !== null ? (
                    <Line
                      data={dataNumIndet.data}
                      options={dataNumIndet.options}
                    />
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category" style={{ textTransform: "none" }}>
                  Rapporto Part-time / Full-time ultimo mese
                </h5>
                <CardTitle tag="h3">
                  <i
                    className="tim-icons icon-chart-pie-36 text-info"
                    style={{ marginRight: "10px" }}
                  />
                  {home.rapporto_all !== null
                    ? home.rapporto_all.total.toString().concat("%")
                    : null}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area" style={{ padding: "3%" }}>
                  {home.rapporto_all !== null ? (
                    <Pie
                      data={dataRapportoAll.data}
                      options={dataRapportoAll.options}
                    />
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg="8">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col
                    lg="11"
                    md="11"
                    sm="11"
                    xs="10"
                    style={{ height: "25px" }}
                  >
                    <h5 className="card-category">
                      Andamento numero Part-time / Full-time
                    </h5>
                  </Col>
                </Row>

                <CardTitle tag="h3">
                  <i
                    className="tim-icons icon-sound-wave text-success"
                    style={{ marginRight: "10px" }}
                  />
                  {home.ftpt_all !== null ? selected_year : null}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {home.ftpt_all !== null ? (
                    <Line
                      data={dataFtPtAll.data}
                      options={dataFtPtAll.options}
                    />
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  home: state.home,
  selected_columns: state.client.selected_columns,
  selected_year: state.client.selected_year
});

export default connect(mapStateToProps, {
  selectPage
})(Dashboard);
