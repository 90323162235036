import React, { Component } from "react";
import Add_user from "./Add_user";
import Modify_user from "./Modify_user";

class Manage_users extends Component {
  state = {
    new_user: true,
    modify_user: false
  };

  activateNew = () =>
    this.setState({
      new_user: true,
      modify_user: false
    });

  activateModify = () =>
    this.setState({
      new_user: false,
      modify_user: true
    });
  render() {
    const { new_user, modify_user } = this.state;
    return (
      <div className="container" style={{ paddingTop: "30px" }}>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            {new_user ? (
              <a
                onClick={this.activateNew}
                className="nav-link active"
                style={{ cursor: "pointer", color: "blue" }}
              >
                Crea
              </a>
            ) : (
              <a
                onClick={this.activateNew}
                className="nav-link"
                style={{ cursor: "pointer" }}
              >
                Crea
              </a>
            )}
          </li>
          <li className="nav-item">
            {modify_user ? (
              <a
                onClick={this.activateModify}
                className="nav-link active"
                style={{ cursor: "pointer", color: "blue" }}
              >
                Modifica
              </a>
            ) : (
              <a
                onClick={this.activateModify}
                className="nav-link"
                style={{ cursor: "pointer" }}
              >
                Modifica
              </a>
            )}
          </li>
        </ul>
        {new_user ? <Add_user /> : <Modify_user />}
      </div>
    );
  }
}

export default Manage_users;
