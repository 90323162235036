import React from "react";
import { StyleSheet, View, Image, Text } from "@react-pdf/renderer";
import PdfTableComponent from "./PdfTableComponent";

const styles = StyleSheet.create({
  title_section: {
    fontWeight: 900,
    marginBottom: "20px"
  },
  p1: {
    fontSize: 12,
    marginBottom: "40px"
  }
});

const CostiQualifica = ({ qualifica, selected_year, report }) => {
  var last_month = report.ordered_months[report.ordered_months.length - 1];
  return (
    <View>
      <Text style={styles.title_section}>Qualifiche contrattuali</Text>
      <Text style={{ ...styles.p1, marginBottom: "20px" }}>
        Nel corso dell'anno {selected_year} si sono registrate un totale di{" "}
        {String(qualifica.unique_qualifica.length - 1)} qualifiche. Si riporta
        di seguito un dettaglio comparativo sulla somma dei costi mensili e sul
        numero del personale per ciascuna qualifica{" "}
        {report.ordered_months.includes("Dicembre")
          ? null
          : `fino al mese di ${last_month}`}
        .
      </Text>
      <PdfTableComponent
        columnsDisplayed={report.qualifica.overall.labels}
        columnsRaw={report.qualifica.overall.labelsRaw}
        data={report.qualifica.overall.values}
      ></PdfTableComponent>
      <Text style={{ ...styles.p1, fontFamily: "Times-Italic" }}>
        Si ricorda che all'interno della piattaforma è possibile consultare il
        dettaglio delle principali voci per ciascuna busta paga del dipendente.
      </Text>
      {qualifica.unique_qualifica.map((s_qualifica, index) => {
        if (s_qualifica !== "Tutte") {
          return (
            <View>
              <Text
                style={{
                  ...styles.p1,
                  fontFamily: "Times-Bold",
                  textDecoration: "underline",
                  marginBottom: "15px",
                  marginTop: index !== 0 ? "5px" : "0px"
                }}
              >
                {s_qualifica.toUpperCase()}
              </Text>
              <Text
                style={{
                  ...styles.p1,
                  marginBottom: "15px"
                }}
              >
                Numero personale registrato:{" "}
                {String(qualifica.header.num_employees[s_qualifica])}. Totale
                dei costi mensili:{" "}
                {String(qualifica.header.costo_totale[s_qualifica]).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  "."
                )}
                €
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "0px",
                  marginBottom: "20px"
                }}
              >
                <Image
                  style={{ height: "170px", width: "480" }}
                  src={String(
                    document
                      .getElementById(`download_qualifica_${s_qualifica}`)
                      .toDataURL()
                  )}
                />
              </View>
              {report.qualifica !== null ? (
                <PdfTableComponent
                  columnsDisplayed={report.qualifica.detail_qualifica.labels}
                  columnsRaw={report.qualifica.detail_qualifica.labelsRaw}
                  data={report.qualifica.detail_qualifica.values[s_qualifica]}
                ></PdfTableComponent>
              ) : null}
            </View>
          );
        }
      })}
    </View>
  );
};

export default CostiQualifica;
