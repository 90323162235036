import React, { Component } from "react";
import { connect } from "react-redux";
import MyModalError from "../component/js/myModalError";

export class Listener_alert extends Component {
  render() {
    const { errors } = this.props;
    return (
      <div>
        {errors.status !== null ? <MyModalError status={true} /> : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors
});

export default connect(mapStateToProps)(Listener_alert);
