import React, { Component } from "react";
import { connect } from "react-redux";
import { sendNews } from "../../../actions/newsActions";
import MyModalSuccess from "../../component/js/myModalSuccess";
import classnames from "classnames";

export class Add_news extends Component {
  state = {
    title: "",
    text: "",
    errors: {}
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.state.title === "") {
      this.setState({ errors: { title: "Il titolo è obbligatorio" } });
      return;
    }
    if (this.state.text === "") {
      this.setState({ errors: { text: "Il testo è obbligatorio" } });
      return;
    }
    this.props.sendNews(this.state.title, this.state.text);
    this.setState({ title: "", text: "", errors: {} });
  };

  onChange = e =>
    this.setState({
      [e.target.name]: e.target.value
    });

  render() {
    const { title, text, errors } = this.state;
    const { show_modal } = this.props;
    return (
      <div style={{ marginTop: "30px" }}>
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <label htmlFor="name">Titolo</label>
            <input
              name="title"
              type="text"
              className={classnames("form-control lg", {
                "is-invalid": errors.title
              })}
              value={title}
              onChange={this.onChange}
            />
            {errors.title && (
              <div className="invalid-feedback">{errors.title}</div>
            )}
          </div>
          <div className="form-group">
            <label>Testo</label>
            <textarea
              className={classnames("form-control lg", {
                "is-invalid": errors.text
              })}
              rows="10"
              name="text"
              value={text}
              onChange={this.onChange}
            />
            {errors.text && (
              <div className="invalid-feedback">{errors.text}</div>
            )}
          </div>
          <br />
          <input
            type="submit"
            value="PUBBLICA"
            className="btn btn-light btn-block button-contatti"
          />
        </form>
        {show_modal ? <MyModalSuccess status={true} /> : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  show_modal: state.news.show_modal
});

export default connect(
  mapStateToProps,
  { sendNews }
)(Add_news);
