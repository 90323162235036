/*eslint-disable*/
import React from "react";
import { NavLink, Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import PerfectScrollbar from "perfect-scrollbar";
import { Nav } from "reactstrap";
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.state = {
      dropdownOpen1: false,
      dropdownOpen2: false
    };
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  linkOnClick = () => {
    document.documentElement.classList.remove("nav-open");
  };

  toggle1() {
    this.setState({
      dropdownOpen1: !this.state.dropdownOpen1
    });
  }

  toggle2() {
    this.setState({
      dropdownOpen2: !this.state.dropdownOpen2
    });
  }

  render() {
    const { bgColor, routes, logo } = this.props;
    let logoImg = null;
    let logoText = null;
    if (logo !== undefined) {
      if (logo.outterLink !== undefined) {
        logoImg = (
          <a
            href={logo.outterLink}
            className="simple-text logo-mini"
            target="_blank"
            onClick={this.props.toggleSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo" />
            </div>
          </a>
        );
        logoText = (
          <a
            href={logo.outterLink}
            className="simple-text logo-normal"
            target="_blank"
            onClick={this.props.toggleSidebar}
          >
            {logo.text}
          </a>
        );
      } else {
        logoImg = (
          <Link
            to={logo.innerLink}
            className="simple-text logo-mini"
            onClick={this.props.toggleSidebar}
            style={{ cursor: "default" }}
          >
            <div className="logo-img">
              <img
                src={logo.imgSrc}
                alt="logo"
                style={{ width: "80%", height: "80%" }}
              />
            </div>
          </Link>
        );
        logoText = (
          <Link
            to={logo.innerLink}
            className="simple-text logo-normal"
            onClick={this.props.toggleSidebar}
            style={{ cursor: "default", fontWeight: "bold" }}
          >
            {logo.text}
          </Link>
        );
      }
    }

    return (
      <div className="sidebar" data={bgColor}>
        <div className="sidebar-wrapper" ref="sidebar">
          {logoImg !== null || logoText !== null ? (
            <div className="logo">
              {logoImg}
              {logoText}
            </div>
          ) : null}
          <Nav>
            {routes.map((prop, key) => {
              if (prop.redirect) return null;
              return (
                <div key={key}>
                  {prop.name == "HOME" ? (
                    <Link
                      to={prop.layout + prop.path}
                      className="nav-link"
                      activeClassName="active"
                      onClick={this.props.toggleSidebar}
                    >
                      <i className={prop.icon} />
                      <p
                        style={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "11px",
                          color: "white",
                          fontWeight: "500"
                        }}
                      >
                        {prop.name}
                      </p>
                    </Link>
                  ) : prop.name === "Unità locali" ? (
                    <Dropdown
                      nav
                      isOpen={this.state.dropdownOpen1}
                      toggle={this.toggle1}
                      style={{ marginLeft: "-8px" }}
                      key="unita_locali"
                    >
                      <DropdownToggle nav>
                        <i className={prop.icon} />
                        <p
                          style={{
                            marginRight: "20px",
                            fontFamily: "Helvetica, sans-serif",
                            fontSize: "11px",
                            color: "white",
                            fontWeight: "500"
                          }}
                        >
                          {prop.name}
                          <b
                            className="caret d-none d-lg-block d-xl-block"
                            style={{ marginTop: "-2px" }}
                          />
                        </p>
                      </DropdownToggle>
                      <DropdownMenu onClick={this.props.toggleSidebar}>
                        {this.props.unita_locali.unique_unita_locali !== null
                          ? this.props.unita_locali.unique_unita_locali.map(
                              single_unita => (
                                <Link
                                  to={
                                    single_unita !== "Tutte"
                                      ? `/admin/unita_locali?unita=${single_unita}`
                                      : "/admin/totali?detail=unita_locale"
                                  }
                                  key={single_unita}
                                  style={{ color: "gray" }}
                                >
                                  <DropdownItem>
                                    {single_unita.toUpperCase()}
                                  </DropdownItem>
                                </Link>
                              )
                            )
                          : null}
                      </DropdownMenu>
                    </Dropdown>
                  ) : prop.name === "Qualifica" ? (
                    <Dropdown
                      nav
                      isOpen={this.state.dropdownOpen2}
                      toggle={this.toggle2}
                      style={{ marginLeft: "-8px" }}
                      key="qualifica"
                    >
                      <DropdownToggle nav>
                        <i className={prop.icon} />
                        <p
                          style={{
                            marginRight: "20px",
                            fontFamily: "Helvetica, sans-serif",
                            fontSize: "11px",
                            color: "white",
                            fontWeight: "500"
                          }}
                        >
                          {prop.name}
                          <b
                            className="caret d-none d-lg-block d-xl-block"
                            style={{ marginTop: "-2px" }}
                          />
                        </p>
                      </DropdownToggle>
                      <DropdownMenu onClick={this.props.toggleSidebar}>
                        {this.props.qualifica.unique_qualifica !== null
                          ? this.props.qualifica.unique_qualifica.map(
                              single_qualifica => (
                                <Link
                                  to={
                                    single_qualifica !== "Tutte"
                                      ? `/admin/qualifica?unita=${single_qualifica}`
                                      : "/admin/totali?detail=qualifica"
                                  }
                                  key={single_qualifica}
                                  style={{ color: "gray" }}
                                >
                                  <DropdownItem>
                                    {single_qualifica.toUpperCase()}
                                  </DropdownItem>
                                </Link>
                              )
                            )
                          : null}
                      </DropdownMenu>
                    </Dropdown>
                  ) : prop.name === "DETTAGLI" ? (
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      activeClassName="active"
                      onClick={this.props.toggleSidebar}
                    >
                      <i className={prop.icon} />
                      <p
                        style={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "11px",
                          color: "white",
                          fontWeight: "500"
                        }}
                      >
                        {prop.name}
                      </p>
                    </NavLink>
                  ) : prop.name == "Download" ? (
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      activeClassName="active"
                      onClick={this.props.toggleSidebar}
                    >
                      <i className={prop.icon} />
                      <p
                        style={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "11px",
                          color: "white",
                          fontWeight: "500"
                        }}
                      >
                        {prop.name.toUpperCase()}
                      </p>
                    </NavLink>
                  ) : prop.name == "Storico" ? (
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      activeClassName="active"
                      onClick={this.props.toggleSidebar}
                    >
                      <i className={prop.icon} />
                      <p
                        style={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "11px",
                          color: "white",
                          fontWeight: "500"
                        }}
                      >
                        {prop.name.toUpperCase()}
                      </p>
                    </NavLink>
                  ) : prop.name == "Report" ? (
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      activeClassName="active"
                      onClick={this.props.toggleSidebar}
                    >
                      <i className={prop.icon} />
                      <p
                        style={{
                          fontFamily: "Helvetica, sans-serif",
                          fontSize: "11px",
                          color: "white",
                          fontWeight: "500"
                        }}
                      >
                        {prop.name.toUpperCase()}
                      </p>
                    </NavLink>
                  ) : null}
                </div>
              );
            })}
          </Nav>
        </div>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "primary",
  routes: [{}]
};

Sidebar.propTypes = {
  bgColor: PropTypes.oneOf(["primary", "blue", "green"]),
  routes: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = state => ({
  unita_locali: state.unita_locali,
  qualifica: state.qualifica
});

export default connect(mapStateToProps)(withRouter(Sidebar));
