import React, { Component } from "react";
import Add_Data from "./Add_Data";
import Modify_Data from "./Modify_Data";

class Manage_Data extends Component {
  state = {
    new_news: true,
    modify_news: false
  };

  activateNew = () =>
    this.setState({
      new_news: true,
      modify_news: false
    });

  activateModify = () =>
    this.setState({
      new_news: false,
      modify_news: true
    });
  render() {
    const { new_news, modify_news } = this.state;
    return (
      <div className="container" style={{ paddingTop: "30px" }}>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            {new_news ? (
              <a
                onClick={this.activateNew}
                className="nav-link active"
                style={{ cursor: "pointer", color: "blue" }}
              >
                Inserisci
              </a>
            ) : (
              <a
                onClick={this.activateNew}
                className="nav-link"
                style={{ cursor: "pointer" }}
              >
                Inserisci
              </a>
            )}
          </li>
          <li className="nav-item">
            {modify_news ? (
              <a
                onClick={this.activateModify}
                className="nav-link active"
                style={{ cursor: "pointer", color: "blue" }}
              >
                Modifica
              </a>
            ) : (
              <a
                onClick={this.activateModify}
                className="nav-link"
                style={{ cursor: "pointer" }}
              >
                Modifica
              </a>
            )}
          </li>
        </ul>
        {new_news ? <Add_Data /> : <Modify_Data />}
      </div>
    );
  }
}

export default Manage_Data;
