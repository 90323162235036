import React from "react";
import { StyleSheet, View, Image, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  title_section: {
    fontWeight: 900,
    marginBottom: "20px"
  },
  p1: {
    fontSize: 12,
    marginBottom: "40px"
  }
});

const CostiMensili = ({ selected_year, report, home }) => {
  var last_month = report.ordered_months[report.ordered_months.length - 1];

  return (
    <View>
      <Text style={styles.title_section}>Costi mensili</Text>
      {report.ordered_months.length === 1 ? (
        <Text style={styles.p1}>
          Nel corso dell'anno {String(selected_year)} l'azienda ha sostentuto un
          totale di{" "}
          {String(report.overall.costi_mensili_totali).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            "."
          )}
          ,00€ per il versamento delle buste paga.
        </Text>
      ) : (
        <Text style={styles.p1}>
          Nel corso dell'anno {String(selected_year)} l'azienda ha sostentuto un
          totale di{" "}
          {String(report.overall.costi_mensili_totali).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            "."
          )}
          ,00€ per il versamento delle buste paga, con un{" "}
          {String(report.overall.delta_costi_totali).includes("-")
            ? "decremento"
            : "incremento"}{" "}
          del {String(report.overall.delta_costi_totali).replace(/-/g, "")}% fra
          i mesi di {report.ordered_months[0]} e {last_month}.
        </Text>
      )}

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "0px",
          marginBottom: "20px"
        }}
      >
        <Image
          style={{ height: "170px", width: "480px" }}
          src={String(
            document.getElementById("download_costi_mensili").toDataURL()
          )}
        />
      </View>
      {report.ordered_months.length === 1 ? (
        <Text style={styles.p1}>
          Il numero del personale, comprensivo di Amministratori, è pari a{" "}
          {home.num_employees.total} nel mese di {last_month} {selected_year}.
        </Text>
      ) : (
        <Text style={styles.p1}>
          Il numero del personale, comprensivo di Amministratori, è pari a{" "}
          {home.num_employees.total} nel mese di {last_month} {selected_year}.
          Da inizio anno, il numero complessivo del personale ha registrato un{" "}
          {String(report.overall.delta_num_employees).includes("-")
            ? "decremento"
            : "incremento"}{" "}
          del {String(report.overall.delta_num_employees).replace(/-/g, "")}
          %, con{" "}
          {String(report.overall.delta_indet_employees).includes("-")
            ? "una diminuzione"
            : "un aumento"}{" "}
          del numero di contratti a tempo indeterminato pari al{" "}
          {String(report.overall.delta_indet_employees).replace(/-/g, "")}%.
        </Text>
      )}

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "0px",
          marginBottom: "30px"
        }}
      >
        <Image
          style={{ height: "150px", width: "220", marginRight: "40px" }}
          src={String(
            document.getElementById("download_num_employees").toDataURL()
          )}
        />
        <Image
          style={{ height: "150px", width: "220" }}
          src={String(
            document.getElementById("download_num_indet").toDataURL()
          )}
        />
      </View>
      <Text style={{ ...styles.p1, marginBottom: "20px" }}>
        Il rapporto fra Part-time e Full-time (PT/FT) al mese di {last_month}{" "}
        {String(selected_year)} è pari al {String(home.rapporto_all.total)}
        %.
      </Text>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "175px",
          marginBottom: "20px"
        }}
      >
        <Image
          style={{ height: "100px", width: "200" }}
          src={String(document.getElementById("download_rapporto").toDataURL())}
        />
      </View>
    </View>
  );
};

export default CostiMensili;
