import React, { Component } from "react";
import All_team from "../../../photos/photo_all.jpg";

export default class description_1_team extends Component {
  render() {
    return (
      <React.Fragment>
        <img alt="Team" src={All_team} style={{ width: "100%" }} />
      </React.Fragment>
    );
  }
}
