import React, { Component } from "react";
import { ButtonToolbar, Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { closeModalGlobal } from "../../../actions/modalActions";

export class MyModalSuccess extends Component {
  state = {
    lgShow: this.props.status
  };

  onClose = () => {
    this.setState({ lgShow: false });
    this.props.closeModalGlobal();
  };

  render() {
    return (
      <ButtonToolbar>
        <Modal
          size="lg"
          show={this.state.lgShow}
          onHide={this.onClose}
          aria-labelledby="example2-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example2-modal-sizes-title-lg">
              <i style={{ color: "#66cdaa" }} className="fas fa-thumbs-up" />
              &nbsp;Fatto
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p> Operazione completata con successo!</p>
            <div
              className="mywrapper"
              style={{ textAlign: "center", marginTop: "30px" }}
            >
              <Button onClick={this.onClose} variant="success">
                Chiudi
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </ButtonToolbar>
    );
  }
}

export default connect(
  null,
  { closeModalGlobal }
)(MyModalSuccess);
