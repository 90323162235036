import React, { Component } from "react";
import { ButtonToolbar, Modal } from "react-bootstrap";

export class Loading extends Component {
  state = {
    lgShow: this.props.status
  };

  hideFalse() {
    return false;
  }

  render() {
    return (
      <ButtonToolbar style={{ width: "100vw", height: "100vh" }}>
        <Modal
          size="lg"
          show={this.state.lgShow}
          onHide={this.hideFalse}
          aria-labelledby="example2-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title id="example2-modal-sizes-title-lg">
              Caricamento...
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "400px", textAlign: "center" }}>
            <div
              className="spinner-border"
              role="status"
              style={{ width: "4rem", height: "4rem", marginTop: "110px" }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </Modal.Body>
        </Modal>
      </ButtonToolbar>
    );
  }
}

export default Loading;
