import React, { Component } from "react";
import "../style_app/Contatti.css";
import classnames from "classnames";
import { sendEmail } from "../../actions/userActions";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class Contatti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      errors: {},
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  componentDidMount() {
    document.title = "Contattaci";
  }

  onSubmit = e => {
    e.preventDefault();
    if (this.state.name === "") {
      this.setState({ errors: { name: "Il titolo è obbligatorio" } });
      return;
    } else if (this.state.email === "") {
      this.setState({ errors: { email: "Il testo è obbligatorio" } });
      return;
    } else if (this.state.subject === "") {
      this.setState({ errors: { subject: "Il titolo è obbligatorio" } });
      return;
    } else if (this.state.message === "") {
      this.setState({ errors: { message: "Il testo è obbligatorio" } });
      return;
    } else {
      this.toggle();
      const formData = {
        sender: this.state.email,
        subject: this.state.subject,
        content: this.state.message
      };
      this.props.sendEmail(formData);
    }
    // console.log(this.state);
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: "",
      errors: {}
    });
  };

  onChange = e =>
    this.setState({
      [e.target.name]: e.target.value
    });

  render() {
    window.scrollTo(0, 0);
    const { name, email, subject, message, errors } = this.state;
    return (
      <div className="full-width-div">
        <div className="container">
          <div className="center-contatti">
            <div className="card mb-3">
              <div className="card-header">CONTATTACI</div>
              <div className="card-body">
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">Nome e Cognome</label>
                    <input
                      name="name"
                      type="text"
                      className={classnames("form-control lg", {
                        "is-invalid": errors.name
                      })}
                      value={name}
                      onChange={this.onChange}
                    />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      name="email"
                      type="email"
                      className={classnames("form-control lg", {
                        "is-invalid": errors.email
                      })}
                      value={email}
                      onChange={this.onChange}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="subject">Oggetto</label>
                    <input
                      name="subject"
                      type="text"
                      className={classnames("form-control lg", {
                        "is-invalid": errors.subject
                      })}
                      value={subject}
                      onChange={this.onChange}
                    />
                    {errors.subject && (
                      <div className="invalid-feedback">{errors.subject}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea3">
                      Messaggio
                    </label>
                    <textarea
                      className={classnames("form-control lg", {
                        "is-invalid": errors.message
                      })}
                      id="exampleFormControlTextarea3"
                      rows="3"
                      name="message"
                      value={message}
                      onChange={this.onChange}
                    />
                    {errors.message && (
                      <div className="invalid-feedback">{errors.message}</div>
                    )}
                  </div>
                  {/* <div className="form-group">
                    <label htmlFor="message">Messaggio</label>
                    <textarea
                      name="message"
                      type="text"
                      className="form-control lg"
                      value={message}
                      onChange={this.onChange}
                      style={{ height: "100px" }}
                    />
                  </div> */}
                  <input
                    type="submit"
                    value="INVIA"
                    className="btn btn-light btn-block button-contatti"
                  />
                  <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className={this.props.className}
                  >
                    <ModalHeader toggle={this.toggle}>
                      Grazie per averci contattato!
                    </ModalHeader>
                    <ModalBody>
                      Un nostro operatore risponderà il prima possibile.
                    </ModalBody>
                    <ModalFooter>
                      <Button color="success" onClick={this.toggle}>
                        Chiudi
                      </Button>
                    </ModalFooter>
                  </Modal>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { sendEmail }
)(Contatti);
