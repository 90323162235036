import React, { Component } from "react";
import Add_News from "./Add_news";
import Modify_News from "./Modify_News";

class Manage_News extends Component {
  state = {
    new_news: true,
    modify_news: false
  };

  activateNew = () =>
    this.setState({
      new_news: true,
      modify_news: false
    });

  activateModify = () =>
    this.setState({
      new_news: false,
      modify_news: true
    });
  render() {
    const { new_news, modify_news } = this.state;
    return (
      <div className="container" style={{ paddingTop: "30px" }}>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            {new_news ? (
              <a
                onClick={this.activateNew}
                className="nav-link active"
                style={{ cursor: "pointer", color: "blue" }}
              >
                Inserisci
              </a>
            ) : (
              <a
                onClick={this.activateNew}
                className="nav-link"
                style={{ cursor: "pointer" }}
              >
                Inserisci
              </a>
            )}
          </li>
          <li className="nav-item">
            {modify_news ? (
              <a
                onClick={this.activateModify}
                className="nav-link active"
                style={{ cursor: "pointer", color: "blue" }}
              >
                Modifica
              </a>
            ) : (
              <a
                onClick={this.activateModify}
                className="nav-link"
                style={{ cursor: "pointer" }}
              >
                Modifica
              </a>
            )}
          </li>
        </ul>
        {new_news ? <Add_News /> : <Modify_News />}
      </div>
    );
  }
}

export default Manage_News;
