import { combineReducers } from "redux";
import newsReducer from "./newsReducer";
import auth from "./auth";
import errors from "./errorsReducer";
import modal from "./modalReducer";
import users from "./userReducer";
import client from "./clientReducer";
import home from "./homeReducer";
import unita_locali from "./unita_localiReducer";
import qualifica from "./qualificaReducer";
import dettagli from "./dettagliReducer";
import download from "./downloadReducer";
import storico from "./storicoReducer";
import report from "./reportReducer";

export default combineReducers({
  news: newsReducer,
  auth,
  errors,
  modal,
  users,
  client,
  home,
  unita_locali,
  qualifica,
  dettagli,
  download,
  storico,
  report
});
