import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "./Loading";
import { NavLink } from "reactstrap";

export class Listener_loading extends Component {
  render() {
    const { is_loading_data } = this.props;

    return (
      <div>
        {/* {console.log(is_loading_data)} */}
        {is_loading_data === true ? <Loading status={true} /> : null}
        {/* {console.log(is_loading_data)} */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  is_loading_data: state.client.is_loading_data
});

export default connect(mapStateToProps)(Listener_loading);
