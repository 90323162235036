import React, { useState } from "react";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import StoricoChart_component from "./StoricoChart_component";
import { selectPage } from "../../actions/clientActions";
import { connect } from "react-redux";

export const Storico_page = ({ selectPage }) => {
  const [selectedData, updateSelectedData] = useState("");
  const [detail, updateDetail] = useState("");

  selectPage("STORICO");

  return (
    <div className="content" style={{ marginTop: "0", paddingBottom: "0px" }}>
      <Row>
        <Col lg="12">
          <Card
            className="card-chart"
            style={{ padding: "10px", height: "300px" }}
          >
            <CardHeader>
              <h5
                className="card-category"
                style={{ fontSize: "13px", textTransform: "none" }}
              >
                Annuali
              </h5>
              <CardTitle tag="h3" style={{ fontSize: "20px" }}>
                Confronta i risultati su più anni
              </CardTitle>
            </CardHeader>
            <CardBody style={{ padding: "15px 15px 0px" }}>
              <div className="chart-area">
                <label htmlFor="sel_data">Vuoi raggruppare i dati?</label>
                <select
                  name="selectedData"
                  onChange={e => updateSelectedData(e.target.value)}
                  value={selectedData}
                  className="form-control"
                  id="sel_data"
                >
                  <option value="" selected disabled>
                    Visualizza elenco...
                  </option>
                  <option key="no">No</option>
                  <option key="unita">Sì, raggruppa per unità locale</option>
                  <option key="qualifica">Sì, raggruppa per qualifica</option>
                </select>
                <br />
                {selectedData !== "" ? (
                  <div>
                    <label htmlFor="sel_data2">
                      Quale infomazione vuoi visualizzare?
                    </label>
                    <select
                      name="detail"
                      onChange={e => updateDetail(e.target.value)}
                      value={detail}
                      className="form-control"
                      id="sel_data2"
                    >
                      <option value="" selected disabled>
                        Visualizza elenco...
                      </option>
                      <option key="Retribuzione_lorda">
                        Retribuzione lorda
                      </option>
                      <option key="Rimborso_spese">Rimborso spese</option>
                      <option key="Trasferte">Trasferte</option>
                      <option key="Multe_danni">Multe e danni</option>
                      <option key="Retribuzione_netta">
                        Retribuzione netta
                      </option>
                      <option key="Costo_mensile">Costo mensile</option>
                      <option key="Inps">Inps</option>
                      <option key="Malattia">Malattia</option>
                      <option key="Ferie_permessi">Ferie e permessi</option>
                      <option key="TFR">TFR</option>
                      <option key="Mensilita_agg.">Mensilità agg.</option>
                      <option key="Straordinari">Straordinari</option>
                      <option key="Costo_globale">Costo globale</option>
                    </select>
                    <br />
                  </div>
                ) : null}
                <br />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {detail !== "" ? (
        <Row>
          <Col lg="12">
            <Card>
              <CardHeader>
                <CardTitle
                  tag="h3"
                  style={{ fontSize: "20px", marginBottom: "25px" }}
                >
                  {detail}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <StoricoChart_component
                  selected_data={
                    selectedData.includes("No")
                      ? "overall"
                      : selectedData.includes("unità locale")
                      ? "unita_locale"
                      : "qualifica"
                  }
                  detail={detail}
                />
                <br />
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};
export default connect(null, {
  selectPage
})(Storico_page);
