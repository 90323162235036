export const GET_NEWS = "GET_NEWS";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const MODAL_SUCCESS = "MODAL_SUCCESS";
export const MODAL_SUCCESS_CLOSING = "MODAL_SUCCESS_CLOSING";
export const MODAL_MODIFY = "MODAL_MODIFY";
export const MODAL_MODIFY_CLOSE = "MODAL_MODIFY_CLOSE";
export const DELETE_NEWS = "DELETE_NEWS";
export const MODAL_DELETE = "MODAL_DELETE";
export const MODAL_DELETE_CLOSE = "MODAL_DELETE_CLOSE";
export const SELECT_ID = "SELECT_ID";
export const MODIFY_NEWS = "MODIFY_NEWS";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const GET_ERRORS = "GET_ERRORS";
export const CLOSE_ERROR_MODAL = "CLOSE_ERROR_MODAL";
export const MODAL_SUCCESS_GLOBAL = "MODAL_SUCCESS_GLOBAL";
export const MODAL_SUCCESS_GLOBAL_CLOSE = "MODAL_SUCCESS_GLOBAL_CLOSE";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const SELECT_USER_ID = "SELECT_USER_ID";
export const MODAL_DELETE_USER = "MODAL_DELETE_USER";
export const DELETE_USER = "DELETE_USER";
export const MODAL_DELETE_CLOSE_USER = "MODAL_DELETE_CLOSE_USER";
export const ONLOAD_FILE_HANDLE = "ONLOAD_FILE_HANDLE";
export const GET_ALL_DATA = "GET_ALL_DATA";
export const MODAL_DELETE_FILE = "MODAL_DELETE_FILE";
export const SELECT_FILE = "SELECT_FILE";
export const MODAL_DELETE_CLOSE_FILE = "MODAL_DELETE_CLOSE_FILE";
export const DELETE_FILE = "DELETE_FILE";
export const ADD_FILE_TO_STATE = "ADD_FILE_TO_STATE";
export const GET_DISTINCT_YEAR = "GET_DISTINCT_YEAR";
export const DEFAULT_SELECTED_YEAR = "DEFAULT_SELECTED_YEAR";
export const SELECT_YEAR = "SELECT_YEAR";
export const CHART_COMPILE = "CHART_COMPILE";
export const IS_LOADING_DATA = "IS_LOADING_DATA";
export const LOADED_DATA = "LOADED_DATA";
export const SELECT_PAGE = "SELECT_PAGE";
export const HANDLE_COLUMN = "HANDLE_COLUMN";
export const ANNULLA_COLUMN = "ANNULLA_COLUMN";
export const UPDATE_ANNULLA = "UPDATE_ANNULLA";
export const HANDLE_COLUMN_TOTALI = "HANDLE_COLUMN_TOTALI";
export const UPDATE_ANNULLA_TOTALI = "UPDATE_ANNULLA_TOTALI";
export const ANNULLA_COLUMN_TOTALI = "ANNULLA_COLUMN_TOTALI";
export const ALL_DATA_HOME = "ALL_DATA_HOME";
export const ALL_DATA_UNITA_LOCALI = "ALL_DATA_UNITA_LOCALI";
export const ALL_DATA_QUALIFICA = "ALL_DATA_QUALIFICA";
export const SET_MODE = "SET_MODE";
export const UPDATE_SELECTED_TOTALI = "UPDATE_SELECTED_TOTALI";
export const ALL_DATA_DETTAGLI = "ALL_DATA_DETTAGLI";
export const ALL_DATA_DOWNLOAD = "ALL_DATA_DOWNLOAD";
export const ALL_DATA_STORICO = "ALL_DATA_STORICO";
export const ALL_DATA_REPORT = "ALL_DATA_REPORT";

// NEW
export const API_URL = "https://gefconsultingstp.it/api";
// export const API_URL = "http://localhost:8000/api";
