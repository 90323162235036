import React, { Component } from "react";
import { Button, Modal, ButtonToolbar } from "react-bootstrap";

export class MyLargeModal extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      lgShow: false
    };
  }

  render() {
    let lgClose = () => this.setState({ lgShow: false });

    return (
      <ButtonToolbar>
        <Button onClick={() => this.setState({ lgShow: true })}>
          Dettagli
        </Button>

        <Modal
          size="lg"
          show={this.state.lgShow}
          onHide={lgClose}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {this.props.modal_title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.modal_body}
            <p
              style={{
                marginTop: "10px",
                textAlign: "right",
                color: "gray"
              }}
            >
              {" "}
              <i>{"Aggiunto: ".concat(this.props.modal_date)}</i>
            </p>
          </Modal.Body>
        </Modal>
      </ButtonToolbar>
    );
  }
}

export default MyLargeModal;
