import React, { Component } from "react";
import "../css/card_news.css";
import { MyLargeModal } from "./myLargeModal";

export class card_news extends Component {
  render() {
    const date = new Date(this.props.date_card).toLocaleDateString();
    return (
      <div className="col-md-6 col-sm-12" style={{ marginTop: "30px" }}>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{this.props.title_card}</h5>
            <p className="card-text" style={{ textAlign: "justify" }}>
              {this.props.body_card.length > 450
                ? this.props.body_card.substring(0, 450).concat("...")
                : this.props.body_card}
            </p>
            <p
              style={{
                marginTop: "10px",
                textAlign: "left",
                color: "gray"
              }}
            >
              {" "}
              <i>{"Aggiunto: ".concat(date)}</i>
            </p>
            {this.props.body_card.length > 450 ? (
              <MyLargeModal
                modal_title={this.props.title_card}
                modal_body={this.props.body_card}
                modal_date={date}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default card_news;
