import Dashboard from "./views/Dashboard.jsx";
import Unita_locali from "./views/Unita_locali.jsx";
import Qualifica from "./views/Qualifica.jsx";
import Dettagli from "./views/Dettagli.jsx";
import Dettaglio_dipendente from "./views/DettaglioDipendente.jsx";
import Download from "./views/Download.jsx";
import Storico from "./views/Storico.jsx";
import Totali from "./views/Totali.jsx";
import Report from "./views/Report.jsx";

var routes = [
  {
    path: "/dashboard",
    name: "HOME",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/unita_locali",
    name: "Unità locali",
    icon: "tim-icons icon-atom",
    component: Unita_locali,
    layout: "/admin"
  },
  {
    path: "/qualifica",
    name: "Qualifica",
    icon: "tim-icons icon-settings",
    component: Qualifica,
    layout: "/admin"
  },
  {
    path: "/dettagli",
    name: "DETTAGLI",
    icon: "tim-icons icon-single-02",
    component: Dettagli,
    layout: "/admin"
  },
  {
    path: "/dettaglio_dipendente",
    name: "Dettaglio_dipendente",
    icon: "tim-icons icon-puzzle-10",
    component: Dettaglio_dipendente,
    layout: "/admin"
  },
  {
    path: "/download",
    name: "Download",
    icon: "tim-icons icon-cloud-download-93",
    component: Download,
    layout: "/admin"
  },
  {
    path: "/storico",
    name: "Storico",
    icon: "tim-icons icon-chart-bar-32",
    component: Storico,
    layout: "/admin"
  },
  {
    path: "/totali",
    name: "Totali",
    icon: "tim-icons icon-align-center",
    component: Totali,
    layout: "/admin"
  },
  {
    path: "/report",
    name: "Report",
    icon: "tim-icons icon-notes",
    component: Report,
    layout: "/admin"
  }
];
export default routes;
