import React, { Component } from "react";
import SideBar from "../../components/layout/sidebar";
import Nav from "../../components/layout/navbar";
import Footer from "../../components/layout/Footer";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { navOpacity: 0, width: 0, height: 0 };
    this.updateNavOpacity = this.updateNavOpacity.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  static defaultProps = {
    bottomBorderWidth: 0,
    fadeInDistance: 80
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.updateNavOpacity);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateNavOpacity);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateNavOpacity() {
    const navbarHeight = 50; // Bootstrap default
    const { bottomBorderWidth, fadeInDistance } = this.props;
    const { height } = this.state;
    const endFade = height - navbarHeight - bottomBorderWidth;
    const startFade = endFade - fadeInDistance;
    const scrolly = window.scrollY;

    if (scrolly < startFade) {
      if (this.state.opacity === 0) return;
      this.setState({ navOpacity: 0 });
      return;
    }

    if (scrolly > endFade) {
      if (this.state.opacity === 1) return;
      this.setState({ navOpacity: 1 });
      return;
    }

    const pxPastStartFade = scrolly - startFade;
    const navOpacity = pxPastStartFade / (endFade - startFade);
    this.setState({ navOpacity });
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    return (
      <div>
        <SideBar pageWrapId={"page-wrap"} outerContainerId={"App"} />
        <Nav
          opacity={this.state.navOpacity}
          borderBottomWidth={this.props.bottomBorderWidth}
        />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

export default DefaultLayout;
