import React, { Component } from "react";
import Photo_3_4_perche_noi from "../component/js/photo_3_4_perche_noi";
import { Row, Col } from "reactstrap";
import video_low from "../../photos/video_finale.mp4";
import layers from "../../photos/layers.png";
import rocket from "../../photos/rocket.png";
import "../style_app/Perche_noi.css";

export default class Perche_noi extends Component {
  componentDidMount() {
    document.title = "Perchè noi";
  }
  render() {
    window.scrollTo(0, 0);
    return (
      <div>
        <Photo_3_4_perche_noi />

        <div className="title_perche_noi">MAI PIU' SORPRESE</div>
        <hr style={{ width: "20%", margin: "0 auto 50px auto" }} />

        <div className="container" style={{ width: "100%" }}>
          <Row style={{ marginBottom: "40px" }}>
            <Col lg="6">
              <div className="home-about">
                <h5>Why us</h5>
                <h2>Monitora, gestisci, migliora.</h2>
                <p style={{ fontSize: "17px", marginTop: "16px" }}>
                  Per gestire efficacemente un'attività imprenditoriale occorre
                  effettuare in modo periodico approfondite analisi dei costi
                  aziendali.
                </p>
                <p style={{ fontSize: "17px" }}>
                  In questo contesto, l’analisi dei costi del personale, è
                  estremamente importante per interpretare i risultati e gestire
                  le risorse aziendali. Guarda il video e scopri le soluzioni
                  sviluppate su misura per te.
                </p>
              </div>
            </Col>
            <Col lg="3" style={{ marginTop: "25px" }}>
              <div className="home-icon-box">
                <figure className="icon-pic">
                  <img
                    src={layers}
                    style={{ width: "70px", height: "70px" }}
                    alt=""
                  />
                </figure>
                <h3>Innovazione</h3>
                <p style={{ fontSize: "17px" }}>
                  Non vogliamo dare solamente un risultato, ma spiegare
                  l'origine e le cause dei numeri.
                </p>
              </div>
            </Col>
            <Col lg="3" style={{ marginTop: "25px" }}>
              <div className="home-icon-box">
                <figure className="icon-pic">
                  <img
                    src={rocket}
                    style={{ width: "70px", height: "70px" }}
                    alt=""
                  />
                </figure>
                <h3>Galileo</h3>
                <p style={{ fontSize: "17px" }}>
                  Scopri Galileo, l'innovativa piattaforma per monitorare in
                  ogni istante i costi del personale.
                </p>
              </div>
            </Col>
          </Row>

          <div className="my_video">
            <video className="my_video_inside" controls>
              <source src={video_low} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}
