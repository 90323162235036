import { GET_NEWS } from "../actions/types";
import {
  MODAL_SUCCESS,
  MODAL_SUCCESS_CLOSING,
  MODAL_MODIFY,
  MODAL_MODIFY_CLOSE,
  DELETE_NEWS,
  MODAL_DELETE,
  MODAL_DELETE_CLOSE,
  SELECT_ID,
  MODIFY_NEWS,
  UPDATE_NEWS
} from "../actions/types";

const initialState = {
  news: [],
  show_modal: false,
  show_modify: false,
  show_delete: false,
  select_id: [],
  modified_news: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_NEWS:
      return {
        ...state,
        news: action.payload
      };
    case MODAL_SUCCESS:
      return {
        ...state,
        show_modal: true
      };
    case MODAL_SUCCESS_CLOSING:
      return {
        ...state,
        show_modal: false
      };
    case MODAL_MODIFY:
      return {
        ...state,
        show_modify: true
      };
    case MODAL_MODIFY_CLOSE:
      return {
        ...state,
        show_modify: false
      };
    case MODAL_DELETE:
      return {
        ...state,
        show_delete: true
      };
    case MODAL_DELETE_CLOSE:
      return {
        ...state,
        show_delete: false
      };
    case DELETE_NEWS:
      return {
        ...state,
        news: state.news.filter(one_news => one_news.id !== action.payload)
      };
    case SELECT_ID:
      return {
        ...state,
        select_id: action.payload
      };
    case MODIFY_NEWS:
      return {
        ...state,
        modified_news: state.news.filter(
          one_news => one_news.id === action.payload
        )
      };
    case UPDATE_NEWS:
      return {
        ...state,
        news: state.news.map(one_news =>
          one_news.id === action.payload.id
            ? (one_news = action.payload)
            : one_news
        )
      };
    default:
      return state;
  }
}
