import { ALL_DATA_HOME } from "../actions/types";

const initialState = {
  multiline: null,
  num_employees: null,
  num_indet: null,
  rapporto_all: null,
  ftpt_all: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ALL_DATA_HOME:
      return {
        ...state,
        multiline: action.payload.multiline,
        num_employees: action.payload.num_employees,
        num_indet: action.payload.num_indet,
        rapporto_all: action.payload.rapporto_all,
        ftpt_all: action.payload.ftpt_all
      };
    default:
      return state;
  }
}
