import React, { useState } from "react";
import { useEffect } from "react";
import { Line, Pie } from "react-chartjs-2";
import cloneDeep from "lodash/cloneDeep";

const Report_LineChart = ({
  download_id,
  values,
  labels,
  maintainAspectRatio,
  displayLegend,
  titleText,
  yLabelText,
  ticksFunction,
  titleFontSize,
  yLabelFontSize,
  yTicksFontSize,
  xTicksFontSize
}) => {
  const [dataLine, updateDataLine] = useState({
    data: null,
    options: null
  });

  useEffect(() => {
    let chart_lines_options;
    if (ticksFunction) {
      chart_lines_options = {
        maintainAspectRatio: maintainAspectRatio,
        legend: {
          display: displayLegend
        },
        title: {
          display: true,
          text: titleText,
          position: "bottom",
          fontSize: titleFontSize,
          fontFamily: "Arial"
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest"
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: yLabelText,
                fontSize: yLabelFontSize
              },
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.0)",
                zeroLineColor: "transparent"
              },
              ticks: {
                callback: function(label, index, labels) {
                  return label / 1000;
                },
                fontColor: "#9a9a9a",
                fontSize: yTicksFontSize
              }
            }
          ],
          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.1)",
                zeroLineColor: "transparent"
              },
              ticks: {
                padding: 20,
                fontColor: "#9a9a9a",
                fontSize: xTicksFontSize
              }
            }
          ]
        }
      };
    } else {
      chart_lines_options = {
        maintainAspectRatio: maintainAspectRatio,
        legend: {
          display: displayLegend
        },
        title: {
          display: true,
          text: titleText,
          position: "bottom",
          fontSize: titleFontSize,
          fontFamily: "Arial"
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest"
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: yLabelText,
                fontSize: yLabelFontSize
              },
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.0)",
                zeroLineColor: "transparent"
              },
              ticks: {
                padding: 20,
                fontColor: "#9a9a9a",
                fontSize: yTicksFontSize
              }
            }
          ],
          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.1)",
                zeroLineColor: "transparent"
              },
              ticks: {
                padding: 20,
                fontColor: "#9a9a9a",
                fontSize: xTicksFontSize
              }
            }
          ]
        }
      };
    }

    var data_to_update = {
      data: canvas => {
        let ctx = canvas.getContext("2d");
        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
        gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
        gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
        return {
          labels: labels,
          datasets: [
            {
              label: "N. Dipendenti",
              fill: false,
              backgroundColor: gradientStroke,
              borderColor: "#1f8ef1",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#1f8ef1",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#1f8ef1",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: values
            }
          ]
        };
      },
      options: chart_lines_options
    };
    updateDataLine(data_to_update);
  }, [download_id, labels, values]);

  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          left: "-999em",
          width: 1100,
          height: 400
        }}
      >
        <Line
          id={download_id}
          data={dataLine.data}
          options={dataLine.options}
        />
      </div>
    </React.Fragment>
  );
};

export default Report_LineChart;
