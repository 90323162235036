import React, { Component } from "react";
import { ButtonToolbar, Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";

export class Load_file extends Component {
  state = {
    lgShow: this.props.onload_file
  };

  hideFalse() {
    return false;
  }

  render() {
    return (
      <ButtonToolbar>
        <Modal
          size="lg"
          show={this.state.lgShow}
          onHide={this.hideFalse}
          aria-labelledby="example2-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title id="example2-modal-sizes-title-lg">
              Caricamento...
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "300px", textAlign: "center" }}>
            <div
              className="spinner-border"
              role="status"
              style={{ width: "4rem", height: "4rem", marginTop: "90px" }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </Modal.Body>
        </Modal>
      </ButtonToolbar>
    );
  }
}
const mapStateToProps = state => ({
  onload_file: state.users.onload_file
});

export default connect(mapStateToProps)(Load_file);
