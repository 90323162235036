import { ALL_DATA_STORICO } from "../actions/types";

const initialState = {
  labels: null,
  overall: null,
  unita_locale: null,
  qualifica: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ALL_DATA_STORICO:
      return {
        ...state,
        labels: action.payload.labels,
        overall: action.payload.overall,
        unita_locale: action.payload.unita_locale,
        qualifica: action.payload.qualifica
      };
    default:
      return state;
  }
}
