import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { getUsers } from "../../../actions/userActions";
import Single_row_user from "../js/Single_row_user";
import ModalDeleteUser from "./ModalDeleteUser";

export class Modify_user extends Component {
  componentDidMount() {
    this.props.getUsers();
  }
  render() {
    const { users } = this.props;
    return (
      <div>
        <Table responsive>
          <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {users.map(single_user => (
              <Single_row_user key={single_user.id} single_user={single_user} />
            ))}
          </tbody>
        </Table>
        {this.props.show_delete_user ? (
          <ModalDeleteUser status={true} key={this.props.selected_user_id} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users.users,
  show_delete_user: state.users.show_delete_user,
  selected_user_id: state.users.selected_user_id
});

export default connect(
  mapStateToProps,
  { getUsers }
)(Modify_user);
