import React, { Component } from "react";
import { Card, CardImg, CardText, CardBody, CardTitle } from "reactstrap";

export class Cookie extends Component {
  render() {
    return (
      <div className="container">
        <Card style={{ margin: "80px 0 80px 0" }}>
          <CardBody>
            <CardTitle>
              <strong style={{ fontSize: "20px" }}>
                <center>INFORMATIVA GENERALE SUI COOKIE</center>
              </strong>
            </CardTitle>
            <CardText>
              <br />
              <p>
                Utilizziamo i cookie per rendere il nostro sito più facile ed
                intuitivo. I dati raccolti grazie ai cookie servono per rendere
                l'esperienza di navigazione più piacevole e più efficiente in
                futuro.
              </p>
              <p>
                <strong>Che cosa sono i Cookie?</strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                I cookie sono piccoli file di testo inviati dal sito al
                terminale dell’interessato &#40;solitamente al browser&#41;,
                dove vengono memorizzati per essere poi ritrasmessi al sito alla
                successiva visita del medesimo utente. Un cookie non può
                richiamare nessun altro dato dal disco fisso dell’utente né
                trasmettere virus informatici o acquisire indirizzi email. Ogni
                cookie è unico per il web browser dell’utente. Alcune delle
                funzioni dei cookie possono essere demandate ad altre
                tecnologie. Nel presente documento con il termine ‘cookie’ si
                vuol far riferimento sia ai cookie, propriamente detti, sia a
                tutte le tecnologie similari.
              </p>
              <p>
                <strong>Tipologie di Cookie </strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                Relativamente alla natura dei cookie, ne esistono di diversi
                tipi:{" "}
              </p>
              <p>
                <strong>1. Cookie tecnici</strong>{" "}
              </p>
              <p style={{ marginTop: "-12px" }}>
                I cookie tecnici sono quelli utilizzati al solo fine di
                "effettuare la trasmissione di una comunicazione su una rete di
                comunicazione elettronica, o nella misura strettamente
                necessaria al fornitore di un servizio della società
                dell'informazione esplicitamente richiesto dall'abbonato o
                dall'utente a erogare tale servizio" &#40;cfr. art. 122, comma
                1, del Codice&#41;. Essi non vengono utilizzati per scopi
                ulteriori e sono normalmente installati direttamente dal
                titolare o gestore del sito web. Possono essere suddivisi in:{" "}
              </p>
              <p style={{ marginTop: "-12px" }}>
                - cookie di navigazione o di sessione, che garantiscono la
                normale navigazione e fruizione del sito web &#40;permettendo,
                ad esempio, di realizzare un acquisto o autenticarsi per
                accedere ad aree riservate&#41;; essi sono di fatto necessari
                per il corretto funzionamento del sito;{" "}
              </p>
              <p style={{ marginTop: "-12px" }}>
                - cookie analytics, assimilati ai cookie tecnici laddove
                utilizzati direttamente dal gestore del sito per raccogliere
                informazioni, in forma aggregata, sul numero degli utenti e su
                come questi visitano il sito stesso, al fine di migliorare le
                performance del sito;
              </p>
              <p style={{ marginTop: "-12px" }}>
                - cookie di funzionalità, che permettono all'utente la
                navigazione in funzione di una serie di criteri selezionati
                &#40;ad esempio, la lingua, i prodotti selezionati per
                l'acquisto&#41; al fine di migliorare il servizio reso allo
                stesso. Per l'installazione di tali cookie non è richiesto il
                preventivo consenso degli utenti &#40;più informazioni nel
                paragrafo Gestione dei cookie in basso&#41;.{" "}
              </p>
              <p>
                <strong>2. Cookie di profilazione</strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                I cookie di profilazione sono volti a creare profili relativi
                all'utente e vengono utilizzati al fine di inviare messaggi
                pubblicitari in linea con le preferenze manifestate dallo stesso
                nell'ambito della navigazione in rete. Queste tecnologie non
                collezionano nome degli utenti, indirizzi mail, numeri di
                telefono, indirizzi fisici. Per l'utilizzo dei cookie di
                profilazione è richiesto il consenso dell'interessato. In caso
                di cookie di terze parti, il sito non ha un controllo diretto
                dei singoli cookie e non può controllarli &#40;non può né
                installarli direttamente né cancellarli&#41;. Puoi comunque
                gestire questi cookie attraverso le impostazioni del browser
                &#40;segui le istruzioni riportate più avanti&#41;, o i siti
                indicati nella sezione "Gestione dei cookie".{" "}
              </p>
              <p>
                <strong>
                  Elenco dei cookie tecnici presenti sul sito G&amp;F Consulting
                </strong>
              </p>
              <p>
                <strong>Cookie di sistema </strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                Il sito G&amp;F Consulting utilizza cookie per garantire
                all'utente una migliore esperienza di navigazione; tali cookie
                sono indispensabili per la fruizione corretta del sito. Puoi
                disabilitare questi cookie dal browser seguendo le indicazioni
                nel paragrafo dedicato, ma comprometterai la tua esperienza sul
                sito e non potremo rispondere dei malfunzionamenti.{" "}
              </p>
              <p>
                <strong>
                  Configurazioni per la disabilitazione dei cookies{" "}
                </strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                L'utente può scegliere di non abilitare l'esecuzione dei cookies
                e, dunque, annullare l'attività di profilazione a scopo
                promozionale agendo sulle impostazioni del proprio browser.
                Istruzioni per la disabilitazione dei cookies relative ai
                browser più utilizzati:{" "}
              </p>
              <p style={{ marginTop: "-12px" }}>- Chrome</p>
              <p style={{ marginTop: "-12px" }}>- Firefox</p>
              <p style={{ marginTop: "-12px" }}>- Internet Explorer</p>
              <p style={{ marginTop: "-12px" }}>- Safari</p>
              <p>
                L'assenza di disabilitazione dei cookies per questo sito è da
                intendersi come assenso all'utilizzo dei cookies stessi, e dei
                dati da questi raccolti, come specificato nel paragrafo relativo
                ai cookies tecnici e di profilazione erogati da parti terze.{" "}
              </p>
              <p>
                <strong>Ulteriori informazioni </strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                Per ulteriori informazioni riguardanti i cookie si prega di
                inviare un'email a info@gefconsulting.it{" "}
              </p>
            </CardText>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Cookie;
