import React, { Component } from "react";
import "../style_app/Login.css";
import logo_background from "../../photos/logo.png";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import { Redirect } from "react-router-dom";
import classnames from "classnames";

class Login extends Component {
  state = {
    username: "",
    password: "",
    errors: {},
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    is_staff: PropTypes.bool,
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.username === "") {
      this.setState({ errors: { username: "Username obbligatorio" } });
      return;
    }
    if (this.state.password === "") {
      this.setState({ errors: { password: "Password obbligatoria" } });
      return;
    }
    this.props.login(this.state.username, this.state.password);
    this.setState({
      username: "",
      password: "",
      errors: {},
    });
  };

  componentDidMount() {
    document.title = "Login";
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    window.scrollTo(0, 0);
    if (this.props.isAuthenticated && this.props.is_staff) {
      return <Redirect to="/manage/home" />;
    } else if (this.props.isAuthenticated) {
      return <Redirect to="/admin/dashboard" />;
    }
    const { username, password, errors } = this.state;
    return (
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100 p-t-190 p-b-30">
            <form className="login100-form validate-form">
              <div className="login100-form-avatar">
                <img src={logo_background} alt="AVATAR" />
              </div>

              <span className="login100-form-title p-t-20 p-b-45">
                G&amp;F Consulting
              </span>
              <div className="wrap-input100 validate-input m-b-10">
                <input
                  className={classnames("input100", {
                    "is-invalid": errors.username,
                  })}
                  type="text"
                  name="username"
                  placeholder="Username"
                  onChange={this.onChange}
                  value={username}
                />
                {errors.username && (
                  <div className="invalid-feedback">{errors.username}</div>
                )}
                <span className="focus-input100" />
                <span className="symbol-input100">
                  <i className="fa fa-user" />
                </span>
              </div>

              <div className="wrap-input100 validate-input m-b-10">
                <input
                  className={classnames("input100", {
                    "is-invalid": errors.password,
                  })}
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={this.onChange}
                  value={password}
                />
                {errors.password && (
                  <div className="invalid-feedback">{errors.password}</div>
                )}
                <span className="focus-input100" />
                <span className="symbol-input100">
                  <i className="fa fa-lock" />
                </span>
              </div>

              <div className="container-login100-form-btn p-t-10">
                <button onClick={this.onSubmit} className="login100-form-btn">
                  Login
                </button>
              </div>

              {/* <div className="text-center w-full p-t-25 p-b-230">
                <a href="#" className="txt1 login">
                  Dimenticato Username / Password?
                </a>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  is_staff: state.auth.is_staff,
  user: state.auth.user,
});

export default connect(mapStateToProps, { login })(Login);
