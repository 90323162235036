import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Col,
  Collapse,
  Button,
  Row
} from "reactstrap";

export class BustaPaga extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);
    this.toggle4 = this.toggle4.bind(this);
    this.toggle5 = this.toggle5.bind(this);
    this.toggleAltroRetrib = this.toggleAltroRetrib.bind(this);
    this.state = {
      collapse: false,
      collapse2: false,
      collapse3: false,
      collapse4: false,
      collapse5: false,
      OpenAltroRetrib: false
    };
  }
  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }
  toggle2() {
    this.setState(state => ({ collapse2: !state.collapse2 }));
  }
  toggle3() {
    this.setState(state => ({ collapse3: !state.collapse3 }));
  }
  toggle4() {
    this.setState(state => ({ collapse4: !state.collapse4 }));
  }
  toggle5() {
    this.setState(state => ({ collapse5: !state.collapse5 }));
  }

  toggleAltroRetrib() {
    this.setState({
      OpenAltroRetrib: !this.state.OpenAltroRetrib
    });
  }

  render() {
    var my_id = this.props.busta.id;
    return (
      <div className="container">
        <Card>
          <CardHeader>
            <CardTitle tag="h4">
              Busta paga {this.props.busta.intro_sezione.mese}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <Table className="tablesorter">
                  <tbody>
                    <tr>
                      <td>
                        <b>Nome completo</b>
                      </td>
                      <td className="text-right">
                        {this.props.busta.intro_sezione.nome_completo}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Unità locale</b>
                      </td>
                      <td className="text-right">
                        {this.props.busta.intro_sezione.unita_locale}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Qualifica</b>
                      </td>
                      <td className="text-right">
                        {this.props.busta.intro_sezione.qualifica}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Livello</b>
                      </td>
                      <td className="text-right">
                        {this.props.busta.intro_sezione.livello}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Tempo determinato</b>
                      </td>
                      <td className="text-right">
                        {this.props.busta.intro_sezione.tempo_determinato}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Part-time</b>
                      </td>
                      <td className="text-right">
                        {this.props.busta.intro_sezione.part_time}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Mansione</b>
                      </td>
                      <td className="text-right">
                        {this.props.busta.intro_sezione.mansione}
                      </td>
                    </tr>
                  </tbody>
                </Table>{" "}
              </Col>
              <Col md="6">
                <p className="stato_busta">
                  STATO:&nbsp;
                  {this.props.busta.intro_sezione.attivo ? (
                    <span style={{ color: "#32CD32" }}>ATTIVO</span>
                  ) : (
                    <span style={{ color: "red" }}>CESSATO</span>
                  )}
                </p>
              </Col>
            </Row>

            <Col md="12">
              <div style={{ width: "90%", margin: "0 auto" }}>
                <Button
                  size="lg"
                  block
                  onClick={this.toggle}
                  style={{ backgroundColor: "Transparent" }}
                >
                  <Row>
                    <Col md="6" sm="6" className="stato_busta_interno">
                      Retribuzione lorda
                    </Col>

                    <Col md="6" sm="6" className="stato_busta_interno">
                      {this.props.busta.totale_retrib}
                    </Col>
                  </Row>
                </Button>

                <Collapse isOpen={this.state.collapse}>
                  <Card>
                    <CardBody>
                      <div style={{ width: "90%", margin: "0 auto" }}>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              Retribuzione lorda
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {this.props.busta.sezione_retrib["lordo"]}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              Rimborso spese
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {this.props.busta.sezione_retrib["rimb_spese"]}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              Malattia ditta
                            </p>
                          </Col>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {
                                this.props.busta.sezione_retrib[
                                  "malattia_ditta"
                                ]
                              }
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              Malattia INPS
                            </p>
                          </Col>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {this.props.busta.sezione_retrib["malattia"]}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              Trasferte
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {this.props.busta.sezione_retrib["trasferte"]}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              Multe/danni
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {this.props.busta.sezione_retrib["multe_danni"]}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Può comprendere arrotondamenti, assegno nucleo famigliare, trattenute sindacali/acconti e Fondo/Quota T.F.R."
                              style={{
                                textAlign: "center",
                                color: "gray",
                                textDecoration: "underline",
                                cursor: "default"
                              }}
                            >
                              Altre retrib.
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {this.props.busta.sezione_retrib["altro_retrib"]}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </Col>
            <Col md="12">
              <div style={{ width: "90%", margin: "0 auto" }}>
                <Button
                  size="lg"
                  block
                  onClick={this.toggle2}
                  style={{ backgroundColor: "Transparent" }}
                >
                  <Row>
                    <Col md="6" sm="6" className="stato_busta_interno">
                      INPS
                    </Col>
                    <Col md="6" sm="6" className="stato_busta_interno">
                      {this.props.busta.inps}
                    </Col>
                  </Row>
                </Button>

                <Collapse isOpen={this.state.collapse2}>
                  <Card>
                    <CardBody>
                      <div style={{ width: "90%", margin: "0 auto" }}>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              Di cui a carico ditta
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {this.props.busta.sezione_inps["inps_ditta"]}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </Col>
            <Col md="12">
              <div style={{ width: "90%", margin: "0 auto" }}>
                <Button
                  size="lg"
                  block
                  onClick={this.toggle3}
                  style={{ backgroundColor: "Transparent" }}
                >
                  <Row>
                    <Col md="6" sm="6" className="stato_busta_interno">
                      Sezione Fiscale
                    </Col>
                    <Col md="6" sm="6" className="stato_busta_interno">
                      {this.props.busta.fiscale_totale}
                    </Col>
                  </Row>
                </Button>

                <Collapse isOpen={this.state.collapse3}>
                  <Card>
                    <CardBody>
                      <div style={{ width: "90%", margin: "0 auto" }}>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              Irpef ordinaria
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {
                                this.props.busta.sezione_fiscale[
                                  "irpef_ordinaria"
                                ]
                              }
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Può comprendere credito fiscale DL 66/14, addizionale regionale e comunale"
                              style={{
                                textAlign: "center",
                                color: "gray",
                                textDecoration: "underline",
                                cursor: "default"
                              }}
                            >
                              Altro
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {
                                this.props.busta.sezione_fiscale[
                                  "altro_fiscale"
                                ]
                              }
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </Col>
            <Col md="12">
              <div style={{ width: "90%", margin: "0 auto" }}>
                <Button
                  size="lg"
                  block
                  onClick={this.toggle4}
                  style={{ backgroundColor: "Transparent" }}
                >
                  <Row>
                    <Col md="6" sm="6" className="stato_busta_interno">
                      Costo mensile
                    </Col>
                    <Col md="6" sm="6" className="stato_busta_interno">
                      {this.props.busta.totale}
                    </Col>
                  </Row>
                </Button>

                <Collapse isOpen={this.state.collapse4}>
                  <Card>
                    <CardBody>
                      <div style={{ width: "90%", margin: "0 auto" }}>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              Di cui retribuzione netta
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {this.props.busta.sezione_riepilogo["netto"]}
                            </p>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              INPS netto
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {this.props.busta.sezione_riepilogo["inps_netto"]}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              Irpef netto
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {
                                this.props.busta.sezione_riepilogo[
                                  "irpef_netto"
                                ]
                              }
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Può comprendere EBILOG, sindacati, addizionale regionale e comunale"
                              style={{
                                textAlign: "center",
                                color: "gray",
                                textDecoration: "underline",
                                cursor: "default"
                              }}
                            >
                              Altre voci
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {
                                this.props.busta.sezione_riepilogo[
                                  "altro_riepilogo"
                                ]
                              }
                            </p>
                          </Col>
                        </Row> */}
                      </div>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </Col>
            <Col md="12">
              <div style={{ width: "90%", margin: "0 auto" }}>
                <Button
                  size="lg"
                  block
                  onClick={this.toggle5}
                  style={{ backgroundColor: "Transparent" }}
                >
                  <Row>
                    <Col md="6" sm="6" className="stato_busta_interno">
                      Costo globale
                    </Col>

                    <Col md="6" sm="6" className="stato_busta_interno">
                      {this.props.busta.totali}
                    </Col>
                  </Row>
                </Button>

                <Collapse isOpen={this.state.collapse5}>
                  <Card>
                    <CardBody>
                      <div style={{ width: "90%", margin: "0 auto" }}>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              Costo ord.
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {(this.props.busta.costo_globale[
                                "costi_ordinari"
                              ] - this.props.busta.sezione_retrib["trasferte"]).toFixed(2)}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              Trasferte
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {this.props.busta.sezione_retrib["trasferte"]}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              Ferie e perm.
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {this.props.busta.costo_globale["ferie_perm"]}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              TFR
                            </p>
                          </Col>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {this.props.busta.costo_globale["tfr"]}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              Mens. agg. ve.
                            </p>
                          </Col>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {this.props.busta.costo_globale["mens_agg_ve"]}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              Costo straord.
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {this.props.busta.costo_globale["costi_straor"]}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {" "}
                              Altri costi
                            </p>
                          </Col>

                          <Col md="6" sm="6">
                            <p
                              className="stato_busta_interno_p2"
                              style={{
                                textAlign: "center",
                                color: "gray"
                              }}
                            >
                              {this.props.busta.costo_globale["altri_costi"]}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </Col>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default BustaPaga;
