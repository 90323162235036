import React, { useState } from "react";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import Download_component from "./Download_component";
import { selectPage } from "../../actions/clientActions";
import { connect } from "react-redux";

export const Download_page = ({ selected_year, selectPage }) => {
  const [selectedData, updateSelectedData] = useState("");
  const [detail, updateDetail] = useState("");

  selectPage("DOWNLOAD");

  return (
    <div className="content" style={{ marginTop: "0", paddingBottom: "0px" }}>
      <Row>
        <Col lg="12">
          <Card
            className="card-chart"
            style={{ padding: "10px", minHeight: "460px" }}
          >
            <CardHeader>
              <h5
                className="card-category"
                style={{ fontSize: "13px", textTransform: "none" }}
              >
                Download dati
              </h5>
              <CardTitle tag="h3" style={{ fontSize: "20px" }}>
                <i
                  className="tim-icons icon-attach-87 text-success"
                  style={{ marginRight: "10px" }}
                />
                Anno: {selected_year}
              </CardTitle>
            </CardHeader>
            <CardBody style={{ padding: "15px 15px 0px" }}>
              <div className="chart-area">
                <h4>Per scaricare i dati compilare i campi sottostanti</h4>
                <br />
                <label htmlFor="sel_data">Vuoi raggruppare i dati?</label>
                <select
                  name="selectedData"
                  onChange={e => updateSelectedData(e.target.value)}
                  value={selectedData}
                  className="form-control"
                  id="sel_data"
                >
                  <option value="" selected disabled>
                    Visualizza elenco...
                  </option>
                  <option key="no">No</option>
                  <option key="unita">Sì, raggruppa per unità locale</option>
                  <option key="qualifica">Sì, raggruppa per qualifica</option>
                </select>
                <br />
                {selectedData !== "" ? (
                  <div>
                    <label htmlFor="sel_data2">
                      Hai bisogno anche del dettaglio del singolo dipendente?
                    </label>
                    <select
                      name="detail"
                      onChange={e => updateDetail(e.target.value)}
                      value={detail}
                      className="form-control"
                      id="sel_data2"
                    >
                      <option value="" selected disabled>
                        Visualizza elenco...
                      </option>
                      <option key="no2">
                        No, voglio solo il quadro generale
                      </option>
                      <option key="detaildipendente">Sì</option>
                    </select>
                    <br />
                  </div>
                ) : null}
                {detail !== "" ? (
                  <div style={{ marginTop: "20px" }}>
                    <Download_component
                      selected_data={
                        selectedData.includes("No")
                          ? "overall"
                          : selectedData.includes("unità locale")
                          ? "unita_locale"
                          : "qualifica"
                      }
                      detail={detail}
                    />
                    <br />
                  </div>
                ) : null}
                <br />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  selected_year: state.client.selected_year
});

export default connect(mapStateToProps, {
  selectPage
})(Download_page);
