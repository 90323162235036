import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button
} from "reactstrap";
import { selectPage } from "../../actions/clientActions";
import { updateSelectedTotali } from "../../actions/clientActions";
import MultilineComponentTotali from "../../components/component/js/MultilineComponentTotali";
import cloneDeep from "lodash/cloneDeep";

export const Totali = ({
  unita_locali,
  qualifica,
  selected_columns_totali,
  selected_page,
  selectPage,
  updateSelectedTotali
}) => {
  var my_page = window.location.href.split("=").pop();
  var variableClone;
  var checkboxes_list;
  if (my_page === "unita_locale") {
    my_page = "Totale unità locali";
    variableClone = cloneDeep(unita_locali.totali);
    checkboxes_list = cloneDeep(unita_locali.unique_unita_locali);
  } else {
    my_page = "Totale qualifiche";
    variableClone = cloneDeep(qualifica.totali);
    checkboxes_list = cloneDeep(qualifica.unique_qualifica);
  }
  selectPage(my_page);

  const [dataMultiline, updateDataMultiline] = useState({
    data: null,
    options: null
  });
  const [selectedTotale, updateSelectedTotale] = useState(
    "Visualizza elenco..."
  );
  const [allCheckbox, updateAllCheckbox] = useState([]);
  const [showMultiline, updateShowMultiline] = useState(false);

  const makeUpdate = () => {
    var keys_obj = Object.keys(variableClone[selectedTotale]);
    if (keys_obj.length >= 5) {
      keys_obj = keys_obj.slice(0, 4);
    }
    // redux function: update selected_columns_totali
    updateSelectedTotali(keys_obj);
    updateShowMultiline(true);
  };

  const mounted = useRef();
  useEffect(() => {
    updateSelectedTotale("Visualizza elenco...");
    updateShowMultiline(false);
  }, [selected_page]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (showMultiline) {
        let chart_multiple_options = {
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          tooltips: {
            backgroundColor: "#f5f5f5",
            titleFontColor: "#333",
            bodyFontColor: "#666",
            bodySpacing: 4,
            xPadding: 12,
            mode: "nearest",
            intersect: 0,
            position: "nearest"
          },
          responsive: true,
          scales: {
            yAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: "rgba(29,140,248,0.0)",
                  zeroLineColor: "transparent"
                },
                ticks: {
                  padding: 20,
                  fontColor: "#9e9e9e"
                }
              }
            ],
            xAxes: [
              {
                barPercentage: 1.6,
                gridLines: {
                  drawBorder: false,
                  color: "rgba(0,242,195,0.1)",
                  zeroLineColor: "transparent"
                },
                ticks: {
                  padding: 20,
                  fontColor: "#9e9e9e"
                }
              }
            ]
          }
        };
        if (
          selected_columns_totali.length >= 1 &&
          selected_columns_totali.length <= 4
        ) {
          // when moving from unita locali to qualifica and vicevrsa, selected_columns_totali is not yet updated on first render
          if (checkboxes_list.includes(selected_columns_totali[0])) {
            var dataToUpdate = {
              data: canvas => {
                let ctx = canvas.getContext("2d");
                let gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);
                let gradientStroke2 = ctx.createLinearGradient(0, 230, 0, 50);
                let gradientStroke3 = ctx.createLinearGradient(0, 230, 0, 50);
                let gradientStroke4 = ctx.createLinearGradient(0, 230, 0, 50);
                gradientStroke1.addColorStop(1, "rgba(29,140,248,0.15)");
                gradientStroke1.addColorStop(0.4, "rgba(29,140,248,0.0)"); //blu colors
                gradientStroke1.addColorStop(0, "rgba(29,140,248,0)"); //blu colors
                gradientStroke2.addColorStop(1, "rgba(66,134,121, 0.4)");
                gradientStroke2.addColorStop(0.4, "rgba(66,134,121, 0.0)"); //green colors
                gradientStroke2.addColorStop(0, "rgba(66,134,121, 0.0)"); //green colors
                gradientStroke3.addColorStop(1, "rgba(255, 148, 120, 0.4)");
                gradientStroke3.addColorStop(0.4, "rgba(255, 148, 120, 0.0)"); //red colors
                gradientStroke3.addColorStop(0, "rgba(255, 148, 120, 0.0)"); //red colors
                gradientStroke4.addColorStop(1, "rgba(25, 240, 255, 0.4)");
                gradientStroke4.addColorStop(0.4, "rgba(25, 240, 255, 0.0)"); //light blue colors
                gradientStroke4.addColorStop(0, "rgba(25, 240, 255, 0.0)"); //light blue colors
                var gradientStrokeArray = [
                  gradientStroke1,
                  gradientStroke2,
                  gradientStroke3,
                  gradientStroke4
                ];
                var colorsArray = ["#1f8ef1", "#00d6b4", "#d24419", "#19F0FF"];
                var obj_dataset;
                var datasetArray = selected_columns_totali.map(function(
                  str,
                  index
                ) {
                  obj_dataset = {
                    label: str,
                    fill: true,
                    backgroundColor: gradientStrokeArray[index],
                    borderColor: colorsArray[index],
                    borderWidth: 2,
                    borderDash: [],
                    borderDashOffset: 0.0,
                    pointBackgroundColor: colorsArray[index],
                    pointBorderColor: "rgba(255,255,255,0)",
                    pointHoverBackgroundColor: colorsArray[index],
                    pointBorderWidth: 20,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 15,
                    pointRadius: 4,
                    data: variableClone[selectedTotale][str].values
                  };
                  return obj_dataset;
                });
                return {
                  labels: variableClone.labels,
                  datasets: datasetArray
                };
              },
              options: chart_multiple_options
            };
            updateDataMultiline(dataToUpdate);
            updateAllCheckbox(checkboxes_list);
          }
        }
      }
    }
  }, [
    qualifica,
    unita_locali,
    selected_columns_totali,
    selected_page,
    showMultiline
  ]);

  const onChangeFillIn = e => {
    updateSelectedTotale(e.target.value);
    updateShowMultiline(false);
  };

  return (
    <>
      <div className="content" style={{ marginTop: "0", paddingBottom: "0px" }}>
        <Row>
          <Col lg="12">
            <Card
              className="card-chart"
              style={{ padding: "10px", height: "270px" }}
            >
              <CardHeader>
                {selected_page === "Totale unità locali" ? (
                  <CardTitle tag="h3" style={{ fontSize: "20px" }}>
                    Confronta tutte le unità locali
                  </CardTitle>
                ) : (
                  <CardTitle tag="h3" style={{ fontSize: "20px" }}>
                    Confronta tutte le qualifiche
                  </CardTitle>
                )}
              </CardHeader>
              <CardBody style={{ padding: "15px 15px 0px" }}>
                <div className="chart-area">
                  <div>
                    <label htmlFor="sel_data2">
                      Quale infomazione vuoi visualizzare?
                    </label>
                    <select
                      name="selected_data"
                      onChange={onChangeFillIn}
                      value={selectedTotale}
                      className="form-control"
                      id="sel_data2"
                    >
                      <option value="Visualizza elenco..." selected disabled>
                        Visualizza elenco...
                      </option>
                      <option key="Retribuzione_lorda">
                        Retribuzione lorda
                      </option>
                      <option key="Rimborso_spese">Rimborso spese</option>
                      <option key="Trasferte">Trasferte</option>
                      <option key="Multe_danni">Multe e danni</option>
                      <option key="Retribuzione_netta">
                        Retribuzione netta
                      </option>
                      <option key="Costo_mensile">Costo mensile</option>
                      <option key="Inps">Inps</option>
                      <option key="Malattia">Malattia</option>
                      <option key="Ferie_permessi">Ferie e permessi</option>
                      <option key="TFR">TFR</option>
                      <option key="Mensilita_agg.">Mensilità agg.</option>
                      <option key="Straordinari">Straordinari</option>
                      <option key="Costo_globale">Costo globale</option>
                    </select>
                    <br />
                  </div>

                  {selectedTotale !== "Visualizza elenco..." ? (
                    <div>
                      <Button onClick={makeUpdate}>Mostra</Button>

                      <br />
                    </div>
                  ) : null}
                  <br />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {showMultiline && dataMultiline.data !== null ? (
          <MultilineComponentTotali
            data={dataMultiline.data}
            options={dataMultiline.options}
            all_checkboxes={allCheckbox}
          ></MultilineComponentTotali>
        ) : null}
        <br />
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  unita_locali: state.unita_locali,
  qualifica: state.qualifica,
  selected_columns_totali: state.client.selected_columns_totali,
  selected_page: state.client.selected_page
});

export default connect(mapStateToProps, {
  selectPage,
  updateSelectedTotali
})(Totali);
