import React, { Component } from "react";
import { connect } from "react-redux";
import { openDelete } from "../../../actions/userActions";
import { selectId } from "../../../actions/userActions";

export class Single_row extends Component {
  onDelete = id => {
    this.props.selectId(id);
    this.props.openDelete();
  };
  render() {
    const { id, username, email } = this.props.single_user;
    return (
      <tr>
        <td>{username}</td>
        <td>{email}</td>
        <td>
          <i
            className=" fas fa-trash-alt"
            style={{ color: "red", cursor: "pointer" }}
            onClick={this.onDelete.bind(this, id)}
          />
        </td>
      </tr>
    );
  }
}

export default connect(
  null,
  { openDelete, selectId }
)(Single_row);
