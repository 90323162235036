import React, { Component } from "react";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button
} from "reactstrap";

export class Privacy extends Component {
  render() {
    return (
      <div className="container">
        <Card style={{ margin: "80px 0 80px 0" }}>
          <CardBody>
            <CardTitle>
              <strong style={{ fontSize: "20px" }}>
                <center>POLITICA SULLA RISERVATEZZA</center>
              </strong>
            </CardTitle>
            <CardText>
              <br />
              <p>
                Ai sensi dell'articolo 13 del Regolamento UE n. 2016/679 - GDPR
                G&amp;F Consulting stp srl in conformità al Regolamento UE n.
                2016/679, contenente le istruzioni per proteggere il trattamento
                dei dati personali delle persone fisiche e di altre entità, in
                persona del suo legale rappresentante, Amministratore unico,
                Pierfederico Gardino, di seguito denominato Titolare del
                Trattamento ti informa che i dati personali forniti saranno
                soggetti al regolamento sopra menzionato e agli obblighi di
                riservatezza della società.{" "}
              </p>
              <br />
              <p>
                <strong>Perché questo avviso</strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                Nel prosieguo della pagina sono descritte le modalità di
                gestione del sito in riferimento al trattamento dei dati
                personali degli utenti che lo consultano. Si tratta di
                un’informativa resa anche ai sensi del GDPR a coloro che
                interagiscono con i servizi direttamente forniti da G&amp;F
                Consulting stp srl. L’informativa è resa per il sito da G&amp;F
                Consulting stp srl in qualità di Titolare del trattamento La
                Politica e gli Standard di Privacy utilizzati per la protezione
                dei dati personali sono fondati sui seguenti principi:
              </p>
              <p>
                <strong>1&#41; PRINCIPIO DI RESPONSABILITÀ </strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                Il trattamento di dati personali è gestito nel tempo da apposite
                responsabilità individuate all’interno dell’organizzazione
                aziendale.{" "}
              </p>
              <p>
                <strong>2&#41; PRINCIPIO DI TRASPARENZA </strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                I dati personali sono raccolti e successivamente trattati
                secondo i principi espressi dalla Politica di Privacy adottata,
                indicati nella presente Privacy Policy. Al momento
                dell’eventuale conferimento dei dati viene fornita
                all’interessato un’informativa, sintetica ma completa, secondo
                quanto previsto dal GDPR.{" "}
              </p>
              <p>
                <strong>3&#41; PRINCIPIO DI PERTINENZA DELLA RACCOLTA</strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                I dati personali sono trattati in modo lecito e secondo
                correttezza; sono registrati per scopi determinati, espliciti e
                legittimi; sono pertinenti e non eccedenti le finalità del
                trattamento; sono conservati per il tempo necessario agli scopi
                della raccolta.{" "}
              </p>
              <p>
                <strong>4&#41; PRINCIPIO DI FINALITA’ DELL’UTILIZZO</strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                Le finalità del trattamento dei dati personali sono rese note
                agli interessati al momento della raccolta. Eventuali nuovi
                trattamenti di dati, se estranei agli scopi dichiarati, sono
                attivati previa nuova informativa all’interessato ed eventuale
                richiesta di consenso, quando richiesto dal GDPR.{" "}
              </p>
              <p>
                <strong>5&#41; PRINCIPIO DI VERIFICABILITÀ</strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                I dati personali sono esatti ed aggiornati nel tempo. Sono
                altresì organizzati e conservati in modo che sia data
                all’interessato la possibilità di conoscere, se lo desidera,
                quali dati sono stati raccolti e registrati, nonché di
                controllarne la qualità e richiederne l’eventuale correzione,
                integrazione, cancellazione per violazione di legge od
                opposizione al trattamento e di esercitare tutti gli altri
                diritti previsti dal GDPR presso gli indirizzi indicati nelle
                Informative presenti nel sito.{" "}
              </p>
              <p>
                <strong>6&#41; PRINCIPIO DI SICUREZZA</strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                I dati personali sono protetti da misure di sicurezza tecniche,
                informatiche, organizzative, logistiche e procedurali, contro i
                rischi di distruzione o perdita, anche accidentale, e di accesso
                non autorizzato o di trattamento non consentito. Tali misure
                sono aggiornate periodicamente in base al progresso tecnico,
                alla natura dei dati e alle specifiche caratteristiche del
                trattamento, controllate costantemente e verificate nel tempo.{" "}
              </p>
              <p>
                Le terze parti che svolgono attività di supporto di qualsiasi
                tipo per l’erogazione dei servizi da parte di G&amp;F Consulting
                stp srl, in relazione ai quali eseguono operazioni di
                trattamento di dati personali, sono da quest’ultime designate
                Responsabili del trattamento e sono contrattualmente vincolate
                al rispetto delle misure per la sicurezza e la riservatezza dei
                trattamenti. L’identità di dette terze parti è resa nota agli
                utenti. Con il consenso degli interessati, se richiesto dalla
                legge, e comunque previa adeguata informativa che specifica le
                varie finalità, i dati personali possono essere comunicati a
                soggetti terzi, pubblici e privati, estranei a G&amp;F
                Consulting stp srl, che li tratteranno in qualità di autonomi
                titolari del trattamento. Dei trattamenti dei dati personali
                eseguiti da detti terzi titolari del trattamento G&amp;F
                Consulting stp srl non è in alcun modo responsabile.{" "}
              </p>
              <p>
                <strong>Titolare del Trattamento</strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                G&amp;F Consulting stp srl è il Titolare del trattamento.{" "}
              </p>
              <p>
                <strong>Responsabile interno del trattamento dei dati</strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                Elena Fioravanzo è il responsabile interno del trattamento dei
                dati.{" "}
              </p>
              <p>
                <strong>Oggetto del trattamento </strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                I dati personali impliciti devono essere i seguenti:
              </p>
              <p style={{ marginTop: "-12px" }}>
                -identificazione dei dati &#40;dati biografici, indirizzo email,
                ...&#41;
              </p>
              <p style={{ marginTop: "-12px" }}>
                -dati genetici &#40;di origine razziale o etnica, ...&#41;
              </p>
              <p style={{ marginTop: "-12px" }}>
                -biometrici &#40;caratteristiche fisiche, fisiologiche e
                comportamentali che possono essere desunte da documenti
                esistenti e di proprietà&#41;
              </p>
              <p style={{ marginTop: "-12px" }}>
                -opinioni politiche &#40;se sono disponibili obblighi
                dichiarativi&#41;
              </p>
              <p style={{ marginTop: "-12px" }}>
                -convinzioni religiose e filosofiche &#40;se sono disponibili
                obblighi dichiarativi&#41;{" "}
              </p>
              <p style={{ marginTop: "-12px" }}>
                -appartenenza sindacale &#40;se sono disponibili obblighi
                dichiarativi&#41;
              </p>
              <p>
                Qualsiasi altro dato acquisito dal nostro mandato conferito si
                riferisce a qualsiasi dettaglio della nostra analisi. Potrebbero
                essere trattati dati particolari da lei liberamente forniti nel
                form "contatti".
              </p>
              <p>
                <strong>a. Dati di navigazione </strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                I sistemi informatici e le procedure software preposte al
                funzionamento del Sito acquisiscono, nel corso del loro normale
                esercizio, alcuni Dati Personali la cui trasmissione è implicita
                nell’uso dei protocolli di comunicazione di Internet. Si tratta
                di informazioni che non sono raccolte per essere associate a
                interessati identificati, ma che per loro stessa natura
                potrebbero, attraverso elaborazioni ed associazioni con dati
                detenuti da terzi, permettere di identificare gli utenti. In
                questa categoria di dati rientrano gli indirizzi IP o i nomi a
                dominio dei computer utilizzati dagli utenti che si connettono
                al Sito, gli indirizzi in notazione URI &#40;Uniform Resource
                Identifier&#41; delle risorse richieste, l’orario della
                richiesta, il metodo utilizzato nel sottoporre la richiesta al
                server, la dimensione del file ottenuto in risposta, il codice
                numerico indicante lo stato della risposta data dal server
                &#40;buon fine, errore, etc.&#41; ed altri parametri relativi al
                sistema operativo e all’ambiente informatico dell’utente. Questi
                dati vengono utilizzati al solo fine di ricavare informazioni
                statistiche anonime sull’uso del Sito e per controllarne il
                corretto funzionamento, per identificare anomalie e/o abusi, e
                vengono cancellati immediatamente dopo l’elaborazione. I dati
                potrebbero essere utilizzati per l’accertamento di
                responsabilità in caso di ipotetici reati informatici ai danni
                del sito o di terzi: salva questa eventualità, allo stato i dati
                sui contatti web non persistono per più di sette giorni.{" "}
              </p>
              <p>
                <strong>b. Speciali categorie di dati personali </strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                Nell’utilizzo della sezione del form "Contatti", potrebbe
                verificarsi un conferimento di suoi Dati Personali rientranti
                nel novero delle speciali categorie di Dati Personali di cui
                all’art. 9 del Regolamento, testualmente i “[…] dati idonei a
                rivelare l’origine razziale o etnica, le opinioni politiche, le
                convinzioni religiose o filosofiche, o l’appartenenza sindacale,
                nonché trattare dati genetici, dati biometrici intesi a
                identificare in modo univoco una persona fisica, dati relativi
                alla salute o alla vita sessuale o all’orientamento sessuale
                della persona”. La invitiamo comunicare tali dati solo ove
                strettamente necessario. Invero, Le ricordiamo che a fronte
                della trasmissione di speciali categorie di Dati Personali, ma
                in assenza di specifica manifestazione del consenso a trattare
                tali dati &#40;eventualità che comunque le consente ovviamente
                di inviare un curriculum vitae&#41;, G&amp;F Consulting stp srl
                non potrà essere ritenuta responsabile a nessun titolo, né potrà
                ricevere contestazioni di sorta, poiché in tal caso il
                trattamento sarà consentito in quanto avente ad oggetto dati
                resi manifestamente pubblici dall’interessato, in conformità con
                l’art. 9 del Regolamento. Specifichiamo comunque l’importanza,
                come sopra già segnalato – di manifestare l’esplicito consenso
                al trattamento delle speciali categorie di Dati Personali,
                laddove decidesse di condividere tali informazioni.{" "}
              </p>
              <p>
                <strong>
                  c. Dati forniti volontariamente dall’interessato{" "}
                </strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                Nell’utilizzo di alcuni Servizi del Sito potrebbe verificarsi un
                trattamento di Dati Personali di terzi soggetti inviati a
                G&amp;F Consulting stp srl. Rispetto a tali ipotesi, Lei si pone
                come autonomo titolare del trattamento, assumendosi tutti gli
                obblighi e le responsabilità di legge. In tal senso, conferisce
                sul punto la più ampia manleva rispetto ad ogni contestazione,
                pretesa, richiesta di risarcimento del danno da trattamento,
                ecc. che dovesse pervenire a G&amp;F Consulting stp srl da terzi
                soggetti i cui Dati Personali siano stati trattati attraverso il
                Suo utilizzo delle funzioni del Sito in violazione delle norme
                sulla tutela dei dati personali applicabili. In ogni caso,
                qualora fornisse o in altro modo trattasse Dati Personali di
                terzi nell’utilizzo del Sito, garantisce fin da ora –
                assumendosene ogni connessa responsabilità – che tale
                particolare ipotesi di trattamento si fonda su un’idonea base
                giuridica ai sensi dell’art. 6 del Regolamento che legittima il
                trattamento delle informazioni in questione.
              </p>
              <p>
                <strong>Finalità del trattamento dei dati personali </strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                I dati forniti sono necessari per svolgere le nostre attività
                pianificate, per garantire la sicurezza del sito e per assolvere
                eventuali compiti amministrativi.{" "}
              </p>
              <p>
                <strong>
                  Base legale e natura obbligatoria o facoltativa del
                  trattamento{" "}
                </strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                La base legale del trattamento di Dati Personali per le finalità
                di cui alla sezione 3&#40;a-b&#41; è l’art.
                6&#40;1&#41;&#40;b&#41; del Regolamento in quanto i trattamenti
                sono necessari all’erogazione dei Servizi o per il riscontro di
                richieste dell’interessato. Il conferimento dei Dati Personali
                per queste finalità è facoltativo ma l’eventuale mancato
                conferimento comporterebbe l’impossibilità di attivare i Servizi
                forniti dal Sito. La finalità di cui alla sezione 3c rappresenta
                un trattamento legittimo di Dati Personali ai sensi dell’art.
                6&#40;1&#41;&#40;c&#41; del Regolamento. Una volta conferiti i
                Dati Personali, il trattamento è invero necessario per adempiere
                ad un obbligo di legge a cui G&amp;F Consulting stp srl è
                soggetto. Per i trattamenti effettuati ai fini di invio diretto
                di proprio materiale pubblicitario o di propria vendita diretta
                o per il compimento di proprie ricerche di mercato o di
                comunicazioni commerciali in relazione a prodotti o servizi di
                G&amp;F Consulting stp srl analoghi a quelli richiesti, G&amp;F
                Consulting stp srl può utilizzare, senza il Suo consenso, gli
                indirizzi di posta elettronica e di posta cartacea ai sensi e
                nei limiti consentiti dal provvedimento dell’Autorità Garante
                per la protezione dei dati personali del 19 giugno 2008; la base
                giuridica del trattamento dei Suoi dati per tale finalità è
                l’art. 6.1.f&#41; del Regolamento. In ogni caso, ai sensi
                dell’art. 21 del Regolamento, ha la possibilità di opporsi a
                tale trattamento in ogni momento, inizialmente o in occasione di
                successive comunicazioni, in maniera agevole e gratuitamente
                anche scrivendo al Titolare, nonché di ottenere un immediato
                riscontro che confermi l’interruzione di tale trattamento
                &#40;art. 15 del Regolamento&#41;.{" "}
              </p>
              <p>
                <strong>Destinatari dei dati personali </strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                I Dati Personali potranno essere condivisi, per le finalità
                descritte sopra, con:
              </p>
              <p style={{ marginTop: "-12px" }}>
                a. soggetti che agiscono tipicamente in qualità di responsabili
                del trattamento ex art. 28 del Regolamento ossia: i&#41;
                persone, società o studi professionali che prestano attività di
                assistenza e consulenza a G&amp;F Consulting stp srl in materia
                contabile, amministrativa, legale, tributaria, finanziaria e di
                recupero crediti relativamente alla erogazione dei Servizi;
                ii&#41; soggetti con i quali sia necessario interagire per
                l’erogazione dei Servizi &#40;ad esempio gli hosting
                provider&#41; iii&#41; ovvero soggetti delegati a svolgere
                attività di manutenzione tecnica &#40;inclusa la manutenzione
                degli apparati di rete e delle reti di comunicazione
                elettronica&#41;; &#40;collettivamente “Destinatari”&#41;;
                l’elenco dei responsabili del trattamento che trattano dati può
                essere richiesto al Titolare.{" "}
              </p>
              <p style={{ marginTop: "-12px" }}>
                b. soggetti, enti od autorità, autonomi titolari del
                trattamento, a cui sia obbligatorio comunicare i Dati Personali
                in forza di disposizioni di legge o di ordini delle autorità;{" "}
              </p>
              <p style={{ marginTop: "-12px" }}>
                c. persone autorizzate da G&amp;F Consulting stp srl al
                trattamento di Dati Personali ex art. 29 del Regolamento
                necessario a svolgere attività strettamente correlate
                all’erogazione dei Servizi, che si siano impegnate alla
                riservatezza o abbiano un adeguato obbligo legale di
                riservatezza &#40;es. dipendenti di G&amp;F Consulting stp
                srl&#41;{" "}
              </p>
              <p>
                <strong>Metodi di archiviazione e elaborazione </strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                Il trattamento dei dati personali sarà effettuato
                automaticamente e / o manualmente, ai sensi dell'articolo 32 sul
                regolamento sulle misure di sicurezza, da persone autorizzate,
                ai sensi dell'articolo 29 del regolamento, che agirà sotto
                l'autorità del responsabile del trattamento e / o
                dell'elaboratore dei dati. Solo le persone autorizzate possono
                elaborare i tuoi personali.{" "}
              </p>
              <p>
                <strong>Aree di comunicazione e distribuzione</strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                I dati personali conferiti potranno essere comunicati a terzi
                soggetti per adempiere ad obblighi di legge, in esecuzione di
                ordini provenienti da pubbliche autorità a ciò legittimate
                ovvero ancora per fare valere o difendere un diritto in sede
                giudiziaria. Se necessario in relazione a particolari servizi o
                prodotti richiesti, i dati personali potranno essere comunicati
                a terzi soggetti che svolgono, in qualità di autonomi titolari
                del trattamento, funzioni strettamente connesse e strumentali
                alla prestazione dei servizi o fornitura dei prodotti. Senza la
                comunicazione, questi servizi e prodotti non potrebbero essere
                forniti. I dati personali non saranno diffusi, salvo che il
                servizio richiesto lo richieda.{" "}
              </p>
              <p>
                <strong>Trasferimento dati personali</strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                I tuoi dati personali sono memorizzati in server locali al
                nostro indirizzo legale; non sarà mai trasferito né in nessuno
                degli Stati membri dell'Unione europea né in paesi terzi al di
                fuori dell'Unione europea.{" "}
              </p>
              <p>
                <strong>Categorie speciali di dati personali </strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                Ai sensi degli articoli 9 e 10 del regolamento, è possibile
                conferire dati etichettati come "categorie speciali di dati
                personali", ossia dati che rivelano "origine razziale o etnica,
                opinioni politiche, convinzioni religiose e filosofiche o
                appartenenza sindacale, nonché dati biometrici destinati a
                identificare in modo inequivocabile la persona fisica, i dati
                relativi alla salute personale, alla vita sessuale o
                all'orientamento sessuale".
              </p>
              <p>
                <strong>Conservazione dei dati</strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                I Dati Personali trattati per le finalità di cui sopra saranno
                conservati per il tempo strettamente necessario a raggiungere
                quelle stesse finalità nel rispetto dei principi di
                minimizzazione e limitazione della conservazione ex art.
                5.1.e&#41; del Regolamento. In ogni caso, il Titolare tratterà i
                Dati Personali per il tempo necessario ad adempimenti
                contrattuali e di legge.
              </p>
              <p>
                <strong>I tuoi diritti</strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                Ai sensi degli articoli 15 e 22 del regolamento, in qualsiasi
                momento è possibile esercitare il diritto di:
              </p>
              <p style={{ marginTop: "-12px" }}>
                a. Richiedere l’esistenza dei tuoi dati personali;{" "}
              </p>
              <p style={{ marginTop: "-12px" }}>
                b. Ottenere le informazioni sullo scopo del trattamento dei dati
                personali, delle categorie di dati personali, del destinatario o
                del gruppo di destinatari a cui i dati personali sono stati o
                saranno trasferiti e, ove possibile, della durata di
                archiviazione;{" "}
              </p>
              <p style={{ marginTop: "-12px" }}>
                c. Ricevere aggiornamenti, modifiche e cancellazione dei dati
                personali;
              </p>
              <p style={{ marginTop: "-12px" }}>
                d. Ottenere limitazioni al trattamento dei tuoi dati personali;
              </p>
              <p style={{ marginTop: "-12px" }}>
                {" "}
                e. Richiedere la portabilità dei dati, ovvero ricevere le
                informazioni personali da un controller di dati, in un formato
                strutturato di facile lettura e trasferirle su un altro
                controller senza ostacoli;
              </p>
              <p style={{ marginTop: "-12px" }}>
                f. Rifiutarsi di approvare l'elaborazione dei dati in qualsiasi
                momento anche in caso di marketing diretto;
              </p>
              <p style={{ marginTop: "-12px" }}>
                g. Rifiutare di approvare qualsiasi processo decisionale
                automatizzato relativo a persone fisiche, inclusa la
                profilazione;
              </p>
              <p style={{ marginTop: "-12px" }}>
                h. Chiedere al responsabile del trattamento dei dati l'accesso
                ai propri dati personali e richiedere modifiche, cancellazioni,
                limitazioni o al loro rifiuto di elaborazione, al di là della
                portabilità dei dati;
              </p>
              <p style={{ marginTop: "-12px" }}>
                i. Revocare la approvazione in qualsiasi momento senza
                compromettere la legalità basata sul consenso prima della
                revoca;
              </p>
              <p style={{ marginTop: "-12px" }}>
                j. Conformarsi all'autorità di vigilanza.{" "}
              </p>
              <p>
                <strong>Come esercitare i tuoi diritti </strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                Puoi esercitare i tuoi diritti inviando una raccomandata a.r. a
                G&amp;F Consulting stp srl al nostro indirizzo legale o tramite
                il seguente indirizzo di posta elettronica certificata:
                gefconsultingstp@pec.it{" "}
              </p>
              <p>
                <strong>Modifiche</strong>
              </p>
              <p style={{ marginTop: "-12px" }}>
                La presente privacy policy è in vigore dal 20/06/2019. G&amp;F
                Consulting stp srl si riserva di modificarne o semplicemente
                aggiornarne il contenuto, in parte o completamente, anche a
                causa di variazioni della normativa applicabile. G&amp;F
                Consulting stp srl la informerà di tali variazioni non appena
                verranno introdotte ed esse saranno vincolanti non appena
                pubblicate sul Sito. G&amp;F Consulting stp srl la invita quindi
                a visitare con regolarità questa sezione per prendere cognizione
                della più recente ed aggiornata versione della privacy policy in
                modo da essere sempre aggiornato sui dati raccolti e sull’uso
                che ne fa G&amp;F Consulting stp srl.
              </p>
            </CardText>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Privacy;
