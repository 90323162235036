import React, { Component } from "react";
import { ButtonToolbar, Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { closeDeleteFile } from "../../../actions/userActions";
import { closeDeleteModalFile } from "../../../actions/userActions";

export class MyModalDelete extends Component {
  state = {
    lgShow: this.props.status
  };

  onClose = select_file => {
    const my_body = { file_nome: select_file };
    this.props.closeDeleteFile(my_body);
    this.setState({ lgShow: false });
  };

  onClose2 = () => {
    this.setState({ lgShow: false });
    this.props.closeDeleteModalFile();
  };

  render() {
    const { select_file } = this.props;
    return (
      <ButtonToolbar>
        <Modal
          size="lg"
          show={this.state.lgShow}
          onHide={this.onClose2}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Attenzione
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p> Sei sicuro di voler eliminare il file selezionato?</p>
            <div
              className="mywrapper"
              style={{ textAlign: "center", marginTop: "30px" }}
            >
              <Button
                onClick={this.onClose.bind(this, select_file)}
                variant="danger"
              >
                ELIMINA
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </ButtonToolbar>
    );
  }
}

const mapStateToProps = state => ({
  select_file: state.users.select_file
});

export default connect(
  mapStateToProps,
  { closeDeleteFile, closeDeleteModalFile }
)(MyModalDelete);
