import React, { Component } from "react";
import Photo_3_4_news from "../component/js/photo_3_4_news";
import Card_news from "../component/js/card_news";
import "../style_app/News.css";
import { connect } from "react-redux";
import { getNews } from "../../actions/newsActions";
import PropTypes from "prop-types";

class News extends Component {
  componentDidMount() {
    document.title = "News";
    this.props.getNews();
  }

  render() {
    const { news } = this.props;
    window.scrollTo(0, 0);
    return (
      <React.Fragment>
        <Photo_3_4_news />
        <div className="title_news">LE ULTIME NOVITA'</div>
        <hr style={{ width: "20%", margin: "0 auto" }} />
        <div className="container" style={{ margin: "50px auto" }}>
          <div className="row">
            {news.map(single_news => (
              <Card_news
                key={single_news.id}
                title_card={single_news.title}
                body_card={single_news.text}
                date_card={single_news.created_at}
              />
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

News.propTypes = {
  news: PropTypes.array.isRequired,
  getNews: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  news: state.news.news
});

export default connect(
  mapStateToProps,
  { getNews }
)(News);
