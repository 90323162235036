import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import {
  Table,
  TableHeader,
  TableBody,
  DataTableCell,
  TableCell
} from "@david.kucsai/react-pdf-table";
import { Bar, Line, Pie } from "react-chartjs-2";

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    marginBottom: "30px"
  },
  tableCellHeader: {
    margin: "auto",
    fontSize: 12,
    fontWeight: 900,
    color: "white",
    backgroundColor: "#40afe3",
    padding: "5px 10px",
    borderStyle: "solid",
    borderColor: "white"
  },
  tableCell: {
    margin: "auto",
    padding: "3px 10px",
    fontSize: 10
  }
});

const PdfTableComponent = ({ data, columnsDisplayed, columnsRaw }) => {
  return (
    <View style={styles.table}>
      <Table data={data}>
        <TableHeader>
          {columnsDisplayed.map(str => {
            return <TableCell styles={styles.tableCellHeader}>{str}</TableCell>;
          })}
        </TableHeader>
        <TableBody>
          {columnsRaw.map(column => {
            return (
              <DataTableCell
                styles={styles.tableCell}
                getContent={r => r[column]}
              />
            );
          })}
        </TableBody>
      </Table>
    </View>
  );
};

export default PdfTableComponent;
