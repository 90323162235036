import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { getNews } from "../../../actions/newsActions";
import Single_row from "../js/Single_row";
import MyModalModifyNews from "./MyModalModifyNews";
import MyModalDeleteNews from "./MyModalDeleteNews";

export class Modify_News extends Component {
  componentDidMount() {
    this.props.getNews();
  }
  render() {
    const { news } = this.props;
    return (
      <div>
        <Table responsive>
          <thead>
            <tr>
              <th>Data creazione</th>
              <th>Titolo</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {news.map(single_news => (
              <Single_row key={single_news.id} single_news={single_news} />
            ))}
          </tbody>
        </Table>
        {this.props.show_modify ? (
          <MyModalModifyNews status={true} key={this.props.select_id} />
        ) : null}
        {this.props.show_delete ? (
          <MyModalDeleteNews status={true} key={this.props.select_id} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  news: state.news.news,
  show_modify: state.news.show_modify,
  show_delete: state.news.show_delete,
  select_id: state.news.select_id
});

export default connect(
  mapStateToProps,
  { getNews }
)(Modify_News);
