/*eslint-disable*/
import React from "react";
import { Container } from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer" style={{ marginRight: "40px" }}>
        <Container fluid>
          <div className="copyright">
            © {new Date().getFullYear()}
            &nbsp;sviluppato da G&amp;F Consulting STP Srl e{" "}
            <a href="https://www.gdpanalytics.com/index.php">
              GDP Analytics srl
            </a>
          </div>
        </Container>
      </footer>
    );
  }
}

export default Footer;
