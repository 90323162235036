import React, { Component } from "react";
import { ButtonToolbar, Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { closeErrorModal } from "../../../actions/errorsActions";

export class MyModalSuccess extends Component {
  state = {
    lgShow: this.props.status
  };

  onClose = () => {
    this.setState({ lgShow: false });
    this.props.closeErrorModal();
  };

  render() {
    const { msg } = this.props;

    return (
      <ButtonToolbar>
        {/* {console.log(msg[Object.keys(msg)[0]][0])} */}
        <Modal
          size="lg"
          show={this.state.lgShow}
          onHide={this.onClose}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <i
                style={{ color: "red" }}
                className="fas fa-exclamation-triangle"
              />
              &nbsp;Errore
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {msg[Object.keys(msg)[0]][0] === "Incorrect Credentials" ? (
              <p>Le credenziali inserite non sono corrette!</p>
            ) : null}
            {msg[Object.keys(msg)[0]][0] === "Enter a valid email address." ? (
              <p>Inserisci un valido indirizzo email</p>
            ) : null}
            {msg[Object.keys(msg)[0]][0] ===
            "A user with that username already exists." ? (
              <p>Esiste già un utente con lo stesso Username</p>
            ) : null}
            {msg[Object.keys(msg)[0]][0] ===
            "Enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters." ? (
              <p>
                Inserire un valido username. Può contenere solo lettere, numeri
                e @/./+/-/_ ma non spazi!
              </p>
            ) : null}
            {msg === "File non supportato" ? (
              <p>
                Il file inserito non è supportato. Selezionare un file di tipo
                Excel!
              </p>
            ) : null}
            {msg ===
            "Esiste già un file con lo stesso nome. Assicurati di non voler inserire lo stesso file, oppure rinominalo." ? (
              <p>
                Esiste già un file con lo stesso nome. Assicurati di non voler
                inserire lo stesso file, oppure rinominalo.
              </p>
            ) : null}

            <div
              className="mywrapper"
              style={{ textAlign: "center", marginTop: "30px" }}
            >
              <Button onClick={this.onClose} variant="danger">
                Chiudi
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </ButtonToolbar>
    );
  }
}

const mapStateToProps = state => ({
  msg: state.errors.msg
});

export default connect(
  mapStateToProps,
  { closeErrorModal }
)(MyModalSuccess);
