import React, { useState, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { selectPage } from "../../actions/clientActions";
import MultilineComponent from "../../components/component/js/MultilineComponent";
import GenericTable from "../../components/component/js/GenericTable";
import cloneDeep from "lodash/cloneDeep";

export const Qualifica = ({
  qualifica,
  selected_columns,
  selected_year,
  selected_page,
  selectPage,
}) => {
  const qualificaClone = cloneDeep(qualifica);
  const [dataMultiline, updateDataMultiline] = useState({
    data: null,
    options: null,
  });
  const [costoMedioUnitaLocale, updateCostoMedioUnitaLocale] = useState({
    data: null,
    options: null,
  });
  const [costoMedioMansione, updateCostoMedioMansione] = useState({
    data: null,
    options: null,
  });
  const [dataTable, updateDataTable] = useState({
    multe_danni: [],
    malattia: [],
    netto: [],
  });

  const mounted = useRef();
  var my_page = window.location.href.split("=").pop();
  selectPage(my_page.replace(/%20/g, " "));

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      let chart_multiple_options = {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },

        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.0)",
                zeroLineColor: "transparent",
              },
              ticks: {
                padding: 20,
                fontColor: "#9e9e9e",
              },
            },
          ],

          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(0,242,195,0.1)",
                zeroLineColor: "transparent",
              },
              ticks: {
                padding: 20,
                fontColor: "#9e9e9e",
              },
            },
          ],
        },
      };
      if (selected_columns.length >= 1 && selected_columns.length <= 4) {
        var dataToUpdate = {
          data: (canvas) => {
            let ctx = canvas.getContext("2d");

            let gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);
            let gradientStroke2 = ctx.createLinearGradient(0, 230, 0, 50);
            let gradientStroke3 = ctx.createLinearGradient(0, 230, 0, 50);
            let gradientStroke4 = ctx.createLinearGradient(0, 230, 0, 50);

            gradientStroke1.addColorStop(1, "rgba(29,140,248,0.15)");
            gradientStroke1.addColorStop(0.4, "rgba(29,140,248,0.0)"); //blu colors
            gradientStroke1.addColorStop(0, "rgba(29,140,248,0)"); //blu colors

            gradientStroke2.addColorStop(1, "rgba(66,134,121, 0.4)");
            gradientStroke2.addColorStop(0.4, "rgba(66,134,121, 0.0)"); //green colors
            gradientStroke2.addColorStop(0, "rgba(66,134,121, 0.0)"); //green colors

            gradientStroke3.addColorStop(1, "rgba(255, 148, 120, 0.4)");
            gradientStroke3.addColorStop(0.4, "rgba(255, 148, 120, 0.0)"); //red colors
            gradientStroke3.addColorStop(0, "rgba(255, 148, 120, 0.0)"); //red colors

            gradientStroke4.addColorStop(1, "rgba(25, 240, 255, 0.4)");
            gradientStroke4.addColorStop(0.4, "rgba(25, 240, 255, 0.0)"); //light blue colors
            gradientStroke4.addColorStop(0, "rgba(25, 240, 255, 0.0)"); //light blue colors

            var gradientStrokeArray = [
              gradientStroke1,
              gradientStroke2,
              gradientStroke3,
              gradientStroke4,
            ];
            var colorsArray = ["#1f8ef1", "#00d6b4", "#d24419", "#19F0FF"];
            var obj_dataset;
            var datasetArray = selected_columns.map(function (str, index) {
              obj_dataset = {
                label: str,
                fill: true,
                backgroundColor: gradientStrokeArray[index],
                borderColor: colorsArray[index],
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: colorsArray[index],
                pointBorderColor: "rgba(255,255,255,0)",
                pointHoverBackgroundColor: colorsArray[index],
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: qualificaClone.multiline[selected_page][
                  selected_columns[index]
                ].values,
              };
              return obj_dataset;
            });
            return {
              labels:
                qualificaClone.multiline[selected_page][selected_columns[0]]
                  .labels,
              datasets: datasetArray,
            };
          },
          options: chart_multiple_options,
        };
        updateDataMultiline(dataToUpdate);
      }

      let chart_bars_options = {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(225,78,202,0.1)",
                zeroLineColor: "transparent",
              },
              ticks: {
                suggestedMin: 60,
                suggestedMax: 120,
                padding: 20,
                fontColor: "#9e9e9e",
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(225,78,202,0.1)",
                zeroLineColor: "transparent",
              },
              ticks: {
                padding: 20,
                fontColor: "#9e9e9e",
              },
            },
          ],
        },
      };

      var costo_medio_unita_locale;
      if (
        qualificaClone.costo_medio_unita_locale[selected_page] !== undefined
      ) {
        costo_medio_unita_locale = {
          data: (canvas) => {
            var ctx = canvas.getContext("2d");

            var gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

            gradientStroke.addColorStop(1, "rgba(119,52,169,0.2)");
            gradientStroke.addColorStop(0.4, "rgba(119,52,169,0)");
            gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

            return {
              labels:
                qualificaClone.costo_medio_unita_locale[selected_page].labels,
              datasets: [
                {
                  label: "Costo medio mensile (€)",
                  fill: true,
                  backgroundColor: gradientStroke,
                  hoverBackgroundColor: gradientStroke,
                  borderColor: "#d048b6",
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  data: qualificaClone.costo_medio_unita_locale[selected_page]
                    .values,
                },
              ],
            };
          },
          options: chart_bars_options,
        };
      } else {
        costo_medio_unita_locale = { data: null };
      }
      updateCostoMedioUnitaLocale(costo_medio_unita_locale);

      var costo_medio_mansione;
      if (qualificaClone.costo_medio_mansione[selected_page] !== undefined) {
        costo_medio_mansione = {
          data: (canvas) => {
            var ctx = canvas.getContext("2d");

            var gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

            gradientStroke.addColorStop(1, "rgba(123, 239, 178, 0.1)");
            gradientStroke.addColorStop(0.4, "rgba(123, 239, 178, 0)");
            gradientStroke.addColorStop(0, "rgba(123, 239, 178, 0)"); //purple colors

            return {
              labels: qualificaClone.costo_medio_mansione[selected_page].labels,
              datasets: [
                {
                  label: "Costo medio mensile (€)",
                  fill: true,
                  backgroundColor: gradientStroke,
                  hoverBackgroundColor: gradientStroke,
                  borderColor: "#3CB371",
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  data: qualificaClone.costo_medio_mansione[selected_page]
                    .values,
                },
              ],
            };
          },
          options: chart_bars_options,
        };
      } else {
        costo_medio_mansione = { data: null };
      }
      updateCostoMedioMansione(costo_medio_mansione);
      var object_table = {};

      object_table["multe_danni"] =
        qualificaClone.table.multe_danni[selected_page].rows;
      object_table["malattia"] =
        qualificaClone.table.malattia_ditta[selected_page].rows;
      object_table["netto"] = qualificaClone.table.netto[selected_page].rows;
      updateDataTable(object_table);
    }
  }, [qualifica, selected_columns, selected_page]);

  return (
    <>
      <div className="content" style={{ marginTop: "0", paddingBottom: "0px" }}>
        <Row>
          <Col lg="3">
            <Card className="card-chart" style={{ padding: "10px" }}>
              <CardHeader>
                <h5 className="card-category" style={{ fontSize: "13px" }}>
                  PERSONALE
                </h5>
                <CardTitle tag="h3" style={{ fontSize: "20px" }}>
                  <i
                    className="tim-icons icon-notes text-success"
                    style={{ marginRight: "10px" }}
                  />
                  {qualifica.header !== null
                    ? qualifica.header.num_employees[selected_page]
                    : null}
                </CardTitle>
                <h5 className="card-category" style={{ textTransform: "none" }}>
                  Numero personale ultimo mese
                </h5>
              </CardHeader>
            </Card>
          </Col>
          <Col lg="3">
            <Card className="card-chart" style={{ padding: "10px" }}>
              <CardHeader>
                <h5 className="card-category" style={{ fontSize: "13px" }}>
                  TEMPO INDETERMINATO
                </h5>
                <CardTitle tag="h3" style={{ fontSize: "20px" }}>
                  <i
                    className="tim-icons icon-link-72 text-info"
                    style={{ marginRight: "10px" }}
                  />
                  {qualifica.header !== null
                    ? qualifica.header.num_indet[selected_page]
                    : null}
                </CardTitle>
                <h5 className="card-category" style={{ textTransform: "none" }}>
                  Dipendenti a tempo indeterminato
                </h5>
              </CardHeader>
            </Card>
          </Col>
          <Col lg="3">
            <Card className="card-chart" style={{ padding: "10px" }}>
              <CardHeader>
                <h5 className="card-category" style={{ fontSize: "13px" }}>
                  RAPPORTO PT / FT
                </h5>
                <CardTitle tag="h3" style={{ fontSize: "20px" }}>
                  <i
                    className="tim-icons icon-bell-55 text-warning"
                    style={{ marginRight: "10px" }}
                  />
                  {qualifica.header !== null
                    ? String(
                        qualifica.header.rapporto_ftpt[selected_page]
                      ).concat("%")
                    : null}
                </CardTitle>
                <h5 className="card-category" style={{ textTransform: "none" }}>
                  Percentuale Part-time/Full-time
                </h5>
              </CardHeader>
            </Card>
          </Col>
          <Col lg="3">
            <Card className="card-chart" style={{ padding: "10px" }}>
              <CardHeader>
                <h5 className="card-category" style={{ fontSize: "13px" }}>
                  TOTALE MENSILI
                </h5>
                <CardTitle tag="h3" style={{ fontSize: "20px" }}>
                  <i
                    className="tim-icons icon-money-coins text-danger"
                    style={{ marginRight: "10px" }}
                  />
                  {qualifica.header !== null
                    ? String(qualifica.header.costo_totale[selected_page])
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        .concat("€")
                    : null}
                </CardTitle>
                <h5 className="card-category" style={{ textTransform: "none" }}>
                  Somma Costo mensile anno {selected_year}
                </h5>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        <MultilineComponent
          data={dataMultiline.data}
          options={dataMultiline.options}
        ></MultilineComponent>
        <br />
        <Row>
          <Col lg="6">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h4">
                  Busta paga mensile media per unità locale
                </CardTitle>

                <h5
                  className="card-category"
                  style={{
                    textTransform: "none",
                    marginTop: "-5px",
                    marginBottom: "15px",
                  }}
                >
                  Calcolato su anno {selected_year}
                </h5>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {costoMedioUnitaLocale.data !== null ? (
                    <Bar
                      data={costoMedioUnitaLocale.data}
                      options={costoMedioUnitaLocale.options}
                    />
                  ) : (
                    <div
                      style={{
                        marginLeft: 10,
                        color: "white",
                        textDecoration: "underline",
                      }}
                    >
                      Non sono disponibili dati per il calcolo
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h4">
                  Busta paga mensile media per mansione
                </CardTitle>

                <h5
                  className="card-category"
                  style={{
                    textTransform: "none",
                    marginTop: "-5px",
                    marginBottom: "15px",
                  }}
                >
                  Calcolato su anno {selected_year}
                </h5>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {costoMedioMansione.data !== null ? (
                    <Bar
                      data={costoMedioMansione.data}
                      options={costoMedioMansione.options}
                    />
                  ) : (
                    <div
                      style={{
                        marginLeft: 10,
                        color: "white",
                        textDecoration: "underline",
                      }}
                    >
                      Non sono disponibili dati per il calcolo
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <GenericTable
          dataPassed={dataTable.multe_danni}
          columnsPassed={[
            { name: "nome", title: "Nome" },
            { name: "mansione", title: "Mansione" },
            { name: "multe_danni", title: "Multe e danni (€)" },
          ]}
          defaultSorting={[{ columnName: "multe_danni", direction: "desc" }]}
          defaultOrder={["nome", "mansione", "multe_danni"]}
          title="Dipendenti in ordine decrescente per multe e danni"
          subtitle={`Cumulativo anno ${selected_year}`}
        />
        <GenericTable
          dataPassed={dataTable.malattia}
          columnsPassed={[
            { name: "nome", title: "Nome" },
            { name: "mansione", title: "Mansione" },
            { name: "malattia_ditta", title: "Costo Malattia (€)" },
          ]}
          defaultSorting={[{ columnName: "malattia_ditta", direction: "desc" }]}
          defaultOrder={["nome", "mansione", "malattia_ditta"]}
          title="Dipendenti in ordine decrescente per costo malattia"
          subtitle={`Cumulativo anno ${selected_year}`}
        />
        <GenericTable
          dataPassed={dataTable.netto}
          columnsPassed={[
            { name: "nome", title: "Nome" },
            { name: "mansione", title: "Mansione" },
            { name: "netto", title: "Stipendio netto (€)" },
          ]}
          defaultSorting={[{ columnName: "netto", direction: "desc" }]}
          defaultOrder={["nome", "mansione", "netto"]}
          title="Dipendenti in ordine decrescente per netto in busta paga"
          subtitle={`Cumulativo anno ${selected_year}`}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  qualifica: state.qualifica,
  selected_columns: state.client.selected_columns,
  selected_year: state.client.selected_year,
  selected_page: state.client.selected_page,
});

export default connect(mapStateToProps, {
  selectPage,
})(Qualifica);
