import React, { Component } from "react";
import "../css/rectangle_2_4_ri.css";
import business_1 from "../../../cartoon_png/business_1.png";

export default class rectangle_2_4_ri extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false
    };

    this.updatePredicate = this.updatePredicate.bind(this);
  }
  componentWillMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 700 });
  }
  render() {
    const isDesktop = this.state.isDesktop;
    return (
      <React.Fragment>
        <div
          className="full_rectangle"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className="half_left">
            <h3 className="h3_rectangle_2_4">ELABORAZIONE PAGHE</h3>
            <div className="list_v">
              <ul>
                <li>
                  Buste paga e calcolo dei contributi per tutti i settori di
                  lavoro
                </li>
                <li>
                  Maggior controllo amministrativo grazie alle nostre soluzioni
                </li>
                <li>Garanzia di risultati sicuri, trasparenti e innovativi</li>
              </ul>
            </div>
          </div>
          {isDesktop ? (
            <div className="half_right">
              <img src={business_1} alt="" className="image_half" />
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
