import React from "react";
import { slide as Menu } from "react-burger-menu";
import "./sidebar.css";

export default props => {
  return (
    // Pass on our props
    <Menu {...props}>
      <a className="menu-item" href="/">
        Homepage
      </a>

      <a className="menu-item" href="/team">
        Chi Siamo
      </a>

      <a className="menu-item" href="/servizi">
        Servizi
      </a>

      <a className="menu-item" href="/perche_noi">
        Perchè noi
      </a>

      <a className="menu-item" href="/news">
        News
      </a>

      <a className="menu-item" href="/contatti">
        Contattaci
      </a>

      <a className="menu-item" href="/login">
        Login
      </a>
    </Menu>
  );
};
