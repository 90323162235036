import {
  GET_DISTINCT_YEAR,
  DEFAULT_SELECTED_YEAR,
  SELECT_YEAR,
  IS_LOADING_DATA,
  LOADED_DATA,
  SELECT_PAGE,
  HANDLE_COLUMN,
  ANNULLA_COLUMN,
  UPDATE_ANNULLA,
  HANDLE_COLUMN_TOTALI,
  UPDATE_ANNULLA_TOTALI,
  ANNULLA_COLUMN_TOTALI,
  SET_MODE,
  UPDATE_SELECTED_TOTALI,
} from "../actions/types";

const initialState = {
  distinct_year: [],
  selected_year: [],
  is_loading_data: false,
  selected_page: "HOME",
  selected_columns: ["Retribuzione lorda"],
  annulla_columns: ["Retribuzione lorda"],
  selected_columns_totali: [],
  annulla_columns_totali: [],
  mode: "dark",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DISTINCT_YEAR:
      return {
        ...state,
        distinct_year: action.payload,
      };
    case DEFAULT_SELECTED_YEAR:
    case SELECT_YEAR:
      return {
        ...state,
        selected_year: action.payload,
      };
    case IS_LOADING_DATA:
      return {
        ...state,
        is_loading_data: true,
      };
    case LOADED_DATA:
      return {
        ...state,
        is_loading_data: false,
      };
    case SELECT_PAGE:
      console.log(decodeURIComponent(state.selected_page));
      return {
        ...state,
        selected_page: decodeURIComponent(action.payload),
      };
    case HANDLE_COLUMN:
      if (state.selected_columns.includes(action.payload)) {
        return {
          ...state,
          selected_columns: state.selected_columns.filter(
            (one_elem) => one_elem !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          selected_columns: [...state.selected_columns, action.payload],
        };
      }
    case HANDLE_COLUMN_TOTALI:
      if (state.selected_columns_totali.includes(action.payload)) {
        return {
          ...state,
          selected_columns_totali: state.selected_columns_totali.filter(
            (one_elem) => one_elem !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          selected_columns_totali: [
            ...state.selected_columns_totali,
            action.payload,
          ],
        };
      }
    case ANNULLA_COLUMN:
      return {
        ...state,
        selected_columns: state.annulla_columns,
      };
    case ANNULLA_COLUMN_TOTALI:
      return {
        ...state,
        selected_columns_totali: state.annulla_columns_totali,
      };
    case UPDATE_ANNULLA:
      return {
        ...state,
        annulla_columns: state.selected_columns,
      };
    case UPDATE_ANNULLA_TOTALI:
      return {
        ...state,
        annulla_columns_totali: state.selected_columns_totali,
      };
    case SET_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case UPDATE_SELECTED_TOTALI:
      return {
        ...state,
        selected_columns_totali: action.payload,
        annulla_columns_totali: action.payload,
      };
    default:
      return state;
  }
}
