import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging
} from "@devexpress/dx-react-grid";
import {
  Grid,
  DragDropProvider,
  Table,
  TableHeaderRow,
  TableColumnReordering,
  Toolbar,
  SearchPanel,
  PagingPanel
} from "@devexpress/dx-react-grid-material-ui";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import { Card, CardBody, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";

const FocusTableCellDark = props => {
  const { column } = props;
  if (column.name === "attivo") {
    return <IconCell {...props} />;
  } else {
    return <Table.Cell {...props} tabIndex={0} style={{ color: "#f2f2f2" }} />;
  }
};
const FocusTableCellWhite = props => {
  const { column } = props;
  if (column.name === "attivo") {
    return <IconCell {...props} />;
  } else {
    return <Table.Cell {...props} tabIndex={0} style={{ color: "black" }} />;
  }
};

const IconCell = ({ style, ...restProps }) => {
  return (
    <Table.Cell
      {...restProps}
      style={{
        color: "#3b85f5",
        ...style
      }}
    >
      <Link
        to={`/admin/dettaglio_dipendente?dipendente=${restProps.row.nome}&unita_locale=${restProps.row.unita_locale}&qualifica=${restProps.row.qualifica}`}
      >
        <SearchIcon
          style={{ cursor: "pointer", marginLeft: "-20px", color: "#3b85f5" }}
        ></SearchIcon>
      </Link>
    </Table.Cell>
  );
};

const useStyles = makeStyles({
  mypaperDark: {
    background: "transparent"
  },
  mypaperWhite: {
    background: "white"
  },
  SearchPanelInputBase: {
    color: "white !important"
  },
  SearchPanelInputBaseWhite: {
    color: "rgba(0, 0, 0, 0.54)"
  }
});

const SortingIcon = ({ direction }) =>
  direction === "asc" ? (
    <ArrowUpward style={{ fontSize: "18px" }} />
  ) : (
    <ArrowDownward style={{ fontSize: "18px" }} />
  );

const SortLabelDark = ({ onSort, children, direction }) => (
  <Tooltip
    title={
      children.props.children === "What you want" ? "Description" : children
    }
  >
    <Button
      onClick={onSort}
      style={{
        textTransform: "none",
        fontWeight: "1000",
        fontSize: "16px",
        padding: "0px",
        justifyContent: "flex-start",
        color: "white"
      }}
    >
      {children}
      {direction && <SortingIcon direction={direction} />}
    </Button>
  </Tooltip>
);

const SortLabelWhite = ({ onSort, children, direction }) => (
  <Tooltip
    title={
      children.props.children === "What you want" ? "Description" : children
    }
  >
    <Button
      onClick={onSort}
      style={{
        textTransform: "none",
        fontWeight: "1000",
        fontSize: "16px",
        padding: "0px",
        justifyContent: "flex-start",
        color: "balck"
      }}
    >
      {children}
      {direction && <SortingIcon direction={direction} />}
    </Button>
  </Tooltip>
);

const styles = theme => ({
  button: {
    color: "#bababa !important"
  },
  tableStriped: {
    "& tbody tr": {
      outline: "1px solid rgba(112, 112, 112, 1)"
    },
    "& thead tr": {
      outline: "1px solid white"
    }
  }
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

export const TableComponent = withStyles(styles, { name: "TableComponent" })(
  TableComponentBase
);

const ComponentPagingBase = ({ classes, ...restProps }) => (
  <PagingPanel.Container {...restProps} className={classes.button} />
);

export const ComponentPaging = withStyles(styles, { name: "ComponentPaging" })(
  ComponentPagingBase
);

export const GenericTable = ({
  dataPassed,
  columnsPassed,
  defaultSorting,
  defaultOrder,
  columnAlignment,
  mode
}) => {
  const [columns] = useState(columnsPassed);
  const [rows, setRows] = useState(dataPassed);
  const [tableColumnExtensions] = useState(columnAlignment);
  useEffect(() => {
    setRows(dataPassed);
  }, [dataPassed]);

  const classes = useStyles();

  const [pageSizes] = useState([40, 70, 100, 0]);

  const pagingPanelMessages = {
    showAll: "Tutte",
    rowsPerPage: "Righe per pagina",
    info: "{from} - {to} di {count}"
  };
  const searchMessages = {
    searchPlaceholder: "Cerca..."
  };
  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Paper
              className={
                mode === "dark" ? classes.mypaperDark : classes.mypaperWhite
              }
            >
              <Grid rows={rows} columns={columns}>
                <SortingState defaultSorting={defaultSorting} />
                <IntegratedSorting />
                <SearchState />
                <IntegratedFiltering />
                <PagingState defaultCurrentPage={0} defaultPageSize={40} />
                <IntegratedPaging />
                <DragDropProvider />

                <Table
                  cellComponent={
                    mode === "dark" ? FocusTableCellDark : FocusTableCellWhite
                  }
                  tableComponent={TableComponent}
                  columnExtensions={tableColumnExtensions}
                />

                <TableColumnReordering defaultOrder={defaultOrder} />
                <TableHeaderRow
                  showSortingControls
                  sortLabelComponent={
                    mode === "dark" ? SortLabelDark : SortLabelWhite
                  }
                />
                <Toolbar />
                <SearchPanel
                  className="SearchPanelInputBase-root-39"
                  messages={searchMessages}
                />
                <PagingPanel
                  pageSizes={pageSizes}
                  messages={pagingPanelMessages}
                  containerComponent={ComponentPaging}
                />
              </Grid>
            </Paper>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  mode: state.client.mode
});

export default connect(mapStateToProps)(GenericTable);
