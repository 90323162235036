import React, { useState } from "react";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { selectPage } from "../../actions/clientActions";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocument from "../../components/component/js/PdfDocument";
import Report_LineChart from "../../components/component/js/Report_LineChart";
import { useEffect } from "react";
import { Line, Pie } from "react-chartjs-2";
import cloneDeep from "lodash/cloneDeep";

const Report = ({
  home,
  unita_locali,
  qualifica,
  selected_year,
  report,
  selectPage,
  auth
}) => {
  const homeClone = cloneDeep(home);
  const unita_localiClone = cloneDeep(unita_locali);
  const qualificaClone = cloneDeep(qualifica);
  const reportClone = cloneDeep(report);

  const [macro, updateMacro] = useState("");
  const [show, updateShow] = useState(false);
  const [options, updateOptions] = useState({
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Costi mensili (migliaia di €)",
            fontSize: 20
          },
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent"
          },
          ticks: {
            callback: function(label, index, labels) {
              return label / 1000;
            },
            padding: 20,
            fontColor: "#9a9a9a",
            fontSize: 20
          }
        }
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a",
            fontSize: 20
          }
        }
      ]
    }
  });
  const [dataRapportoAll, updateDataRapportoAll] = useState({
    data: null,
    options: null
  });
  useEffect(() => {
    // pie
    var rapporto_all_chart = {
      data: canvas => {
        let ctx = canvas.getContext("2d");
        let gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);
        let gradientStroke2 = ctx.createLinearGradient(0, 230, 0, 50);
        gradientStroke1.addColorStop(1, "rgba(29,140,248,0.5)");
        gradientStroke1.addColorStop(0.4, "rgba(29,140,248,0.0)");
        gradientStroke1.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
        gradientStroke2.addColorStop(1, "rgba(83, 51, 237, 0.4)");
        gradientStroke2.addColorStop(0.4, "rgba(83, 51, 237, 0.0)");
        gradientStroke2.addColorStop(0, "rgba(83, 51, 237, 0.0)"); //blue colors
        return {
          labels: homeClone.rapporto_all.labels,
          datasets: [
            {
              fill: false,
              backgroundColor: [
                "rgba(242, 123, 79, 0.8)",
                "rgba(51, 243, 147, 0.8)"
              ],
              borderColor: ["red", "green"],
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#1f8ef1",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#1f8ef1",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: homeClone.rapporto_all.values
            }
          ]
        };
      },
      options: {
        maintainAspectRatio: true,
        legend: {
          display: true,
          position: "right",
          labels: {
            fontSize: 50
          }
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          titleFontColor: "#333",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest"
        },
        responsive: true
      }
    };
    updateDataRapportoAll(rapporto_all_chart);
  }, [home]);

  selectPage("REPORT");

  const setMacro = e => {
    updateMacro(e.target.value);
    updateShow(true);
  };

  return (
    <div className="content" style={{ marginTop: "0", paddingBottom: "0px" }}>
      <Report_LineChart
        download_id="download_costi_mensili"
        values={homeClone.multiline["Costo mensile"].values}
        labels={homeClone.multiline["Costo mensile"].labels}
        maintainAspectRatio={false}
        displayLegend={false}
        titleText="Totale dei costi mensili"
        titleFontSize={22}
        yLabelText="Costi mensili (migliaia di €)"
        yLabelFontSize={20}
        yTicksFontSize={20}
        xTicksFontSize={16}
        ticksFunction={true}
      ></Report_LineChart>
      <Report_LineChart
        download_id="download_num_employees"
        values={homeClone.num_employees.values}
        labels={homeClone.num_employees.labels}
        maintainAspectRatio={true}
        displayLegend={false}
        titleText="Numero del personale"
        titleFontSize={36}
        yLabelText=""
        yLabelFontSize={40}
        yTicksFontSize={30}
        xTicksFontSize={25}
        ticksFunction={false}
      ></Report_LineChart>
      <Report_LineChart
        download_id="download_num_indet"
        values={homeClone.num_indet.values}
        labels={homeClone.num_indet.labels}
        maintainAspectRatio={true}
        displayLegend={false}
        titleText="Numero contratti indeterminati"
        titleFontSize={36}
        yLabelText=""
        yLabelFontSize={40}
        yTicksFontSize={30}
        xTicksFontSize={25}
        ticksFunction={false}
      ></Report_LineChart>
      <div
        style={{
          position: "absolute",
          left: "-999em",
          width: 1100,
          height: 400
        }}
      >
        <Pie
          id="download_rapporto"
          data={dataRapportoAll.data}
          options={dataRapportoAll.options}
        />
      </div>
      <div
        style={{
          position: "absolute",
          left: "-999em",
          width: 1100,
          height: 400
        }}
      >
        {unita_localiClone.unique_unita_locali.map(unita => {
          if (unita !== "Tutte") {
            return (
              <Line
                id={`download_unita_locale_${unita}`}
                data={canvas => {
                  let ctx = canvas.getContext("2d");
                  let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                  gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
                  gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
                  gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
                  return {
                    labels:
                      unita_localiClone.multiline[unita]["Costo mensile"]
                        .labels,
                    datasets: [
                      {
                        fill: false,
                        backgroundColor: gradientStroke,
                        borderColor: "#1f8ef1",
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: "#1f8ef1",
                        pointBorderColor: "rgba(255,255,255,0)",
                        pointHoverBackgroundColor: "#1f8ef1",
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data:
                          unita_localiClone.multiline[unita]["Costo mensile"]
                            .values
                      }
                    ]
                  };
                }}
                options={{
                  ...options,
                  title: {
                    display: true,
                    text: `Costo mensile ${unita}`,
                    position: "bottom",
                    fontSize: 22,
                    fontFamily: "Arial"
                  }
                }}
              />
            );
          }
        })}
      </div>
      <div
        style={{
          position: "absolute",
          left: "-999em",
          width: 1100,
          height: 400
        }}
      >
        {qualificaClone.unique_qualifica.map(s_qual => {
          if (s_qual !== "Tutte") {
            return (
              <Line
                id={`download_qualifica_${s_qual}`}
                data={canvas => {
                  let ctx = canvas.getContext("2d");
                  let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
                  gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
                  gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
                  gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
                  return {
                    labels:
                      qualificaClone.multiline[s_qual]["Costo mensile"].labels,
                    datasets: [
                      {
                        fill: false,
                        backgroundColor: gradientStroke,
                        borderColor: "#1f8ef1",
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: "#1f8ef1",
                        pointBorderColor: "rgba(255,255,255,0)",
                        pointHoverBackgroundColor: "#1f8ef1",
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data:
                          qualificaClone.multiline[s_qual]["Costo mensile"]
                            .values
                      }
                    ]
                  };
                }}
                options={{
                  ...options,
                  title: {
                    display: true,
                    text: `Costo mensile ${s_qual.toUpperCase()}`,
                    position: "bottom",
                    fontSize: 22,
                    fontFamily: "Arial"
                  }
                }}
              />
            );
          }
        })}
      </div>
      <Row>
        <Col lg="12">
          <Card
            className="card-chart"
            style={{ padding: "10px", height: "290px" }}
          >
            <CardHeader>
              <h5
                className="card-category"
                style={{ fontSize: "13px", textTransform: "none" }}
              >
                Seleziona il report
              </h5>
              <CardTitle tag="h3" style={{ fontSize: "20px" }}>
                Compilare i campi sottostanti
              </CardTitle>
            </CardHeader>
            <CardBody style={{ padding: "15px 15px 0px" }}>
              <div className="chart-area">
                <label htmlFor="macro">A quale report sei interessato?</label>
                <select
                  name="macro"
                  onChange={setMacro}
                  value={macro}
                  className="form-control"
                  id="macro"
                  style={{ marginBottom: "45px", width: "250px" }}
                >
                  <option value="" selected disabled>
                    Visualizza elenco...
                  </option>
                  <option key="annuale">Annuale</option>
                </select>
                {show && (
                  <React.Fragment>
                    <PDFDownloadLink
                      document={
                        <PdfDocument
                          unita_locali={unita_localiClone}
                          qualifica={qualificaClone}
                          selected_year={selected_year}
                          report={reportClone}
                          home={home}
                        />
                      }
                      fileName={`${auth.user.username.toLowerCase()}-${reportClone.ordered_months[
                        reportClone.ordered_months.length - 1
                      ].toLowerCase()}-${selected_year}`}
                      style={{
                        textDecoration: "none",
                        padding: "10px",
                        color: "white",
                        backgroundColor: "#6fd170",
                        border: "1px solid #6fd170",
                        borderRadius: "10px",
                        minWidth: "50px"
                      }}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <React.Fragment>
                            <div
                              className="spinner-border"
                              role="status"
                              style={{
                                width: "15px",
                                height: "15px",
                                marginRight: "8px"
                              }}
                            ></div>
                            Attendere
                          </React.Fragment>
                        ) : (
                          "Download PDF"
                        )
                      }
                    </PDFDownloadLink>
                    <div
                      style={{
                        marginTop: "20px",
                        color: "#d4d4d4",
                        fontSize: "13px"
                      }}
                    >
                      {window.innerWidth < 990
                        ? "*Su dispositivi mobili il download è rallentato"
                        : null}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  home: state.home,
  unita_locali: state.unita_locali,
  qualifica: state.qualifica,
  selected_year: state.client.selected_year,
  report: state.report,
  auth: state.auth
});

export default connect(mapStateToProps, { selectPage })(Report);
