import {
  MODAL_SUCCESS_GLOBAL,
  MODAL_SUCCESS_GLOBAL_CLOSE
} from "../actions/types";

const initialState = {
  show_modal_global: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MODAL_SUCCESS_GLOBAL:
      return {
        ...state,
        show_modal_global: true
      };
    case MODAL_SUCCESS_GLOBAL_CLOSE:
      return {
        ...state,
        show_modal_global: false
      };

    default:
      return state;
  }
}
