import React, { Component } from "react";
import { ButtonToolbar, Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { closeDelete } from "../../../actions/userActions";
import { closeDeleteModal } from "../../../actions/userActions";

export class ModalDeleteUser extends Component {
  state = {
    lgShow: this.props.status
  };

  onClose = selected_user_id => {
    this.props.closeDelete(selected_user_id);
    this.setState({ lgShow: false });
  };

  onClose2 = () => {
    this.setState({ lgShow: false });
    this.props.closeDeleteModal();
  };

  render() {
    const { selected_user_id } = this.props;
    return (
      <ButtonToolbar>
        <Modal
          size="lg"
          show={this.state.lgShow}
          onHide={this.onClose2}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Attenzione
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p> Sei sicuro di voler eliminare l'utente selezionato?</p>
            <div
              className="mywrapper"
              style={{ textAlign: "center", marginTop: "30px" }}
            >
              <Button
                onClick={this.onClose.bind(this, selected_user_id)}
                variant="danger"
              >
                ELIMINA
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </ButtonToolbar>
    );
  }
}

const mapStateToProps = state => ({
  selected_user_id: state.users.selected_user_id
});

export default connect(
  mapStateToProps,
  { closeDelete, closeDeleteModal }
)(ModalDeleteUser);
