import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Homepage from "./components/pages/Homepage";
import Team from "./components/pages/Team";
import Servizi from "./components/pages/Servizi";
import Perche_noi from "./components/pages/Perche_noi";
import News from "./components/pages/News";
import Manage from "./components/pages/Manage";
import Contatti from "./components/pages/Contatti";
import Login from "./components/pages/Login";
import Privacy from "./components/pages/Privacy";
import Cookie from "./components/pages/Cookie";
import PrivateRoute from "./components/common/PrivateRoute";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AOS from "aos";
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import DefaultLayout from "./components/layout/DefaultLayout";
import Listener_alerts from "./components/layout/Listener_alert";
import Listener_loading from "./dashboard/views/Listener_loading";

import { createBrowserHistory } from "history";
import AdminLayout from "./dashboard/layouts/Admin.jsx";

const hist = createBrowserHistory();

class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }
  render() {
    AOS.init({ once: true });

    return (
      <Provider store={store}>
        <Listener_alerts />
        <Listener_loading />
        <Router history={hist}>
          <div className="App">
            <Switch>
              <PrivateRoute path="/manage" component={Manage} />
              <PrivateRoute
                path="/admin"
                component={props => <AdminLayout {...props} />}
              />
              <DefaultLayout>
                <Route exact path="/" component={Homepage} />
                <Route exact path="/team" component={Team} />
                <Route exact path="/servizi" component={Servizi} />
                <Route exact path="/perche_noi" component={Perche_noi} />
                <Route exact path="/news" component={News} />
                <Route exact path="/contatti" component={Contatti} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/cookie" component={Cookie} />
              </DefaultLayout>
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
