import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  CustomInput,
  Form,
  FormGroup
} from "reactstrap";
import { Line } from "react-chartjs-2";
import { handleChart } from "../../../actions/clientActions";
import { annullaChart } from "../../../actions/clientActions";
import { updateAnnulla } from "../../../actions/clientActions";
import { removeTabChart } from "../../../actions/clientActions";
import { connect } from "react-redux";
import "../css/multiline.css";

export const MultilineComponent = ({
  data,
  options,
  selected_columns,
  handleChart,
  annullaChart,
  removeTabChart,
  updateAnnulla,
  multiline
}) => {
  const insertColumn = elem => {
    handleChart(elem);
  };

  const removeTab = elem => {
    removeTabChart(elem);
  };

  const annullaInsert = () => {
    annullaChart();
  };

  const updateChart = () => {
    updateAnnulla();
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card className="card-chart">
            <CardHeader>
              <Row style={{ marginTop: "4px" }}>
                <Col lg="11" md="11" sm="11" xs="10" style={{ height: "25px" }}>
                  <h5
                    className="card-category"
                    style={{ textTransform: "none" }}
                  >
                    Seleziona i costi
                  </h5>
                </Col>
                <Col
                  lg="1"
                  md="1"
                  sm="1"
                  xs="2"
                  style={{
                    height: "25px",
                    position: "absolute",
                    top: "15px",
                    right: "0px"
                  }}
                >
                  <CardTitle tag="h3">
                    <i
                      data-toggle="modal"
                      data-target="#exampleModalChart"
                      className="tim-icons icon-simple-add text-success"
                      data-placement="bottom"
                      title="Aggiungi elemento"
                      style={{
                        cursor: "pointer",
                        fontSize: "1em",
                        marginTop: "15px"
                      }}
                    />
                    <div
                      className="modal fade"
                      id="exampleModalChart"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                      data-backdrop="static"
                      data-keyboard="false"
                    >
                      <div
                        className="modal-dialog"
                        role="document"
                        style={{ marginTop: "-120px" }}
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title" id="exampleModalLabel">
                              Aggiungi uno o più elementi
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={annullaInsert}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <Form>
                              <FormGroup>
                                <div>
                                  <CustomInput
                                    type="checkbox"
                                    id="primo"
                                    label="Retribuzione lorda"
                                    key="primo"
                                    onClick={() =>
                                      insertColumn("Retribuzione lorda")
                                    }
                                    checked={
                                      selected_columns.includes(
                                        "Retribuzione lorda"
                                      )
                                        ? true
                                        : false
                                    }
                                    className="darkLabel"
                                  />
                                  <CustomInput
                                    type="checkbox"
                                    id="secondo"
                                    label="Rimborso spese"
                                    key="secondo"
                                    onClick={() =>
                                      insertColumn("Rimborso spese")
                                    }
                                    checked={
                                      selected_columns.includes(
                                        "Rimborso spese"
                                      )
                                        ? true
                                        : false
                                    }
                                    className="darkLabel"
                                  />
                                  <CustomInput
                                    type="checkbox"
                                    id="terzo"
                                    label="Trasferte"
                                    key="terzo"
                                    onClick={() => insertColumn("Trasferte")}
                                    checked={
                                      selected_columns.includes("Trasferte")
                                        ? true
                                        : false
                                    }
                                    className="darkLabel"
                                  />
                                  <CustomInput
                                    type="checkbox"
                                    id="quarto"
                                    label="Multe e danni"
                                    key="quarto"
                                    onClick={() =>
                                      insertColumn("Multe e danni")
                                    }
                                    checked={
                                      selected_columns.includes("Multe e danni")
                                        ? true
                                        : false
                                    }
                                    className="darkLabel"
                                  />
                                  <CustomInput
                                    type="checkbox"
                                    id="quinto"
                                    label="Retribuzione netta"
                                    key="quinto"
                                    onClick={() =>
                                      insertColumn("Retribuzione netta")
                                    }
                                    checked={
                                      selected_columns.includes(
                                        "Retribuzione netta"
                                      )
                                        ? true
                                        : false
                                    }
                                    className="darkLabel"
                                  />
                                  <CustomInput
                                    type="checkbox"
                                    id="sesto"
                                    label="Costo mensile"
                                    key="sesto"
                                    onClick={() =>
                                      insertColumn("Costo mensile")
                                    }
                                    checked={
                                      selected_columns.includes("Costo mensile")
                                        ? true
                                        : false
                                    }
                                    className="darkLabel"
                                  />
                                  <CustomInput
                                    type="checkbox"
                                    id="settimo"
                                    label="Inps"
                                    key="settimo"
                                    onClick={() => insertColumn("Inps")}
                                    checked={
                                      selected_columns.includes("Inps")
                                        ? true
                                        : false
                                    }
                                    className="darkLabel"
                                  />
                                  <CustomInput
                                    type="checkbox"
                                    id="ottavo"
                                    label="Malattia"
                                    key="ottavo"
                                    onClick={() => insertColumn("Malattia")}
                                    checked={
                                      selected_columns.includes("Malattia")
                                        ? true
                                        : false
                                    }
                                    className="darkLabel"
                                  />
                                  <CustomInput
                                    type="checkbox"
                                    id="nono"
                                    label="Ferie e permessi"
                                    key="nono"
                                    onClick={() =>
                                      insertColumn("Ferie e permessi")
                                    }
                                    checked={
                                      selected_columns.includes(
                                        "Ferie e permessi"
                                      )
                                        ? true
                                        : false
                                    }
                                    className="darkLabel"
                                  />
                                  <CustomInput
                                    type="checkbox"
                                    id="decimo"
                                    label="TFR"
                                    key="decimo"
                                    onClick={() => insertColumn("TFR")}
                                    checked={
                                      selected_columns.includes("TFR")
                                        ? true
                                        : false
                                    }
                                    className="darkLabel"
                                  />
                                  <CustomInput
                                    type="checkbox"
                                    id="undicesimo"
                                    label="Mensilità agg."
                                    key="undicesimo"
                                    onClick={() =>
                                      insertColumn("Mensilità agg.")
                                    }
                                    checked={
                                      selected_columns.includes(
                                        "Mensilità agg."
                                      )
                                        ? true
                                        : false
                                    }
                                    className="darkLabel"
                                  />
                                  <CustomInput
                                    type="checkbox"
                                    id="dodicesimo"
                                    label="Straordinari"
                                    key="dodicesimo"
                                    onClick={() => insertColumn("Straordinari")}
                                    checked={
                                      selected_columns.includes("Straordinari")
                                        ? true
                                        : false
                                    }
                                    className="darkLabel"
                                  />
                                  <CustomInput
                                    type="checkbox"
                                    id="tredicesimo"
                                    label="Costo globale"
                                    key="tredicesimo"
                                    onClick={() =>
                                      insertColumn("Costo globale")
                                    }
                                    checked={
                                      selected_columns.includes("Costo globale")
                                        ? true
                                        : false
                                    }
                                    className="darkLabel"
                                  />
                                </div>
                              </FormGroup>
                            </Form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                              onClick={annullaInsert}
                            >
                              Annulla
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-dismiss="modal"
                              onClick={updateChart}
                            >
                              Applica
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardTitle>
                </Col>
              </Row>

              <CardTitle tag="h3">
                <i
                  className="tim-icons icon-sound-wave text-success"
                  style={{
                    marginRight: "10px"
                  }}
                />
                Costo dipendenti
              </CardTitle>

              <Row style={{ marginTop: "20px" }}>
                {selected_columns.map(single_elem => (
                  <Col lg="3" md="4" sm="6" style={{ height: "45px" }}>
                    <Card>
                      <CardBody style={{ padding: "5px 5px" }}>
                        <CardTitle style={{ marginBottom: "0px" }}>
                          <Button
                            close
                            style={{ color: "gray" }}
                            onClick={() => removeTab(single_elem)}
                          />
                        </CardTitle>
                        <CardText
                          style={{ fontSize: "14px", lineHeight: "18px" }}
                        >
                          {single_elem}
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </CardHeader>
            <CardBody>
              <div className="chart-area">
                {multiline !== null ? (
                  selected_columns.length === 0 ? (
                    <div style={{ marginLeft: "18px" }}>
                      <h4>
                        Inserire almeno un elemento per visualizzare il grafico
                      </h4>
                    </div>
                  ) : selected_columns.length >= 5 ? (
                    <div style={{ marginLeft: "18px" }}>
                      <h4>
                        Attenzione: per una resa ottimale del grafico inserire
                        massimo 4 elementi per volta
                      </h4>
                    </div>
                  ) : (
                    <Line data={data} options={options} />
                  )
                ) : null}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  multiline: state.home.multiline,
  selected_columns: state.client.selected_columns
});

export default connect(mapStateToProps, {
  handleChart,
  annullaChart,
  updateAnnulla,
  removeTabChart
})(MultilineComponent);
