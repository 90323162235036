import React, { Component } from "react";
import { logout } from "../../../actions/auth";
import "../css/Manage_main.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Manage_main extends Component {
  componentDidMount() {
    document.title = "Pannello di controllo";
  }
  render() {
    return (
      <div className="container">
        <div className="container">
          <div className="row">
            <div className="col text-right" />

            <button
              onClick={this.props.logout}
              type="button"
              className="btn btn-outline-danger"
              style={{ marginTop: "20px" }}
            >
              LOGOUT
            </button>
          </div>
        </div>
        <h1
          style={{ marginTop: "60px", textAlign: "center", fontSize: "80px" }}
        >
          G&amp;F
        </h1>
        <h2 style={{ textAlign: "center", fontSize: "40px" }}>CONSULTING</h2>
        <br />
        <hr />
        <br />
        <h2
          style={{
            textAlign: "center",
            fontSize: "25px",
            color: "gray",
            fontFamily: "Times"
          }}
        >
          Di cosa hai bisogno?
        </h2>
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <br />
              <br />
              <Link to="/manage/manage_news">
                <button
                  type="button"
                  className="btn btn-primary btn-lg manage_button1"
                >
                  Inserisci o Modifica una News
                </button>
              </Link>
              <Link to="/manage/manage_data">
                <button
                  type="button"
                  className="btn btn-primary btn-lg manage_button2"
                >
                  Inserisci o Modifica dati cliente
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col text-center">
              <br />
              <br />
              <Link to="/manage/create_user">
                <button
                  type="button"
                  className="btn btn-success btn-lg manage_button3"
                >
                  <i className="fas fa-plus" />
                  &nbsp; Crea un nuovo cliente
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  { logout }
)(Manage_main);
