import React, { Component } from "react";
import { connect } from "react-redux";
import { openModify } from "../../../actions/newsActions";
import { openDelete } from "../../../actions/newsActions";
import { selectId } from "../../../actions/newsActions";
import { getNewsToModify } from "../../../actions/newsActions";

export class Single_row extends Component {
  onModify = id => {
    this.props.selectId(id);
    this.props.getNewsToModify(id);
    this.props.openModify();
  };

  onDelete = id => {
    this.props.selectId(id);
    this.props.openDelete();
  };
  render() {
    const { id, title, created_at } = this.props.single_news;
    const date = new Date(created_at).toLocaleDateString();
    return (
      <tr>
        <td>{date}</td>
        <td>
          {title.length > 60 ? title.substring(0, 60).concat("...") : title}
        </td>
        <td>
          <i
            className=" fas fa-pencil-alt"
            style={{ color: "orange", cursor: "pointer" }}
            onClick={this.onModify.bind(this, id)}
          />
          &emsp;
          <i
            className=" fas fa-trash-alt"
            style={{ color: "red", cursor: "pointer" }}
            onClick={this.onDelete.bind(this, id)}
          />
        </td>
      </tr>
    );
  }
}

export default connect(
  null,
  { openModify, openDelete, selectId, getNewsToModify }
)(Single_row);
