import React, { Component } from "react";
import "../css/rectangle_2_4_ri_2.css";
import { Link } from "react-router-dom";
import business_3 from "../../../cartoon_png/business_3.png";

export default class rectangle_2_4_ri extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false
    };

    this.updatePredicate = this.updatePredicate.bind(this);
  }
  componentWillMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 700 });
  }
  render() {
    const isDesktop = this.state.isDesktop;
    return (
      <div
        className="full_rectangle"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="half_left">
          <h3 className="h3_rectangle_2_4">... E MOLTO ALTRO</h3>
          <div className="list_v">
            <ul id="second_ul">
              <li>
                Amministrazione efficiente del personale grazie alla nostra
                piattaforma <Link to="/perche_noi">Galileo</Link>
              </li>
              <li>Rilevazione efficiente delle presenze online</li>
              <li>Controversie sul lavoro seguite da esperti avvocati</li>
            </ul>
          </div>
        </div>
        {isDesktop ? (
          <div className="half_right_3">
            <img src={business_3} alt="" className="image_half_3" />
          </div>
        ) : null}
      </div>
    );
  }
}
