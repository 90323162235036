import { ALL_DATA_UNITA_LOCALI } from "../actions/types";

const initialState = {
  unique_unita_locali: null,
  multiline: null,
  header: null,
  costo_medio_qualifica: null,
  costo_medio_mansione: null,
  table: null,
  totali: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ALL_DATA_UNITA_LOCALI:
      return {
        ...state,
        unique_unita_locali: action.payload.unique_unita_locali,
        multiline: action.payload.multiline,
        header: action.payload.header,
        costo_medio_qualifica: action.payload.costo_medio_qualifica,
        costo_medio_mansione: action.payload.costo_medio_mansione,
        table: action.payload.table,
        totali: action.payload.totali
      };
    default:
      return state;
  }
}
