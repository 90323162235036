import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import BustaPaga from "./BustaPaga";
import cloneDeep from "lodash/cloneDeep";

export const Dettaglio_dipendente = ({ busta_paga }) => {
  const busta_pagaClone = cloneDeep(busta_paga);
  const [selectedData, updateSelectedData] = useState(null);

  var current_url = new URL(window.location.href);
  var nome_selected = current_url.searchParams.get("dipendente");
  var unita_locale_selected = current_url.searchParams.get("unita_locale");
  var qualifica_selected = current_url.searchParams.get("qualifica");

  useEffect(() => {
    var filteredData = busta_pagaClone.filter(obj => {
      if (
        obj.intro_sezione.nome_completo === nome_selected &&
        obj.intro_sezione.unita_locale === unita_locale_selected &&
        obj.intro_sezione.qualifica === qualifica_selected
      ) {
        return obj;
      }
    });
    updateSelectedData(filteredData);
  }, [busta_paga]);

  return (
    <React.Fragment>
      <div className="content" style={{ marginTop: "0", paddingBottom: "0px" }}>
        <Row>
          {selectedData !== null ? (
            <Col md="12">
              {selectedData.map(single_busta => (
                <BustaPaga busta={single_busta} key={single_busta.id} />
              ))}
            </Col>
          ) : null}
        </Row>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  busta_paga: state.dettagli.all_buste_paga
});

export default connect(mapStateToProps)(Dettaglio_dipendente);
