import {
  GET_ALL_USERS,
  SELECT_USER_ID,
  MODAL_DELETE_USER,
  DELETE_USER,
  MODAL_DELETE_CLOSE_USER,
  ONLOAD_FILE_HANDLE,
  GET_ALL_DATA,
  SELECT_FILE,
  MODAL_DELETE_FILE,
  MODAL_DELETE_CLOSE_FILE,
  DELETE_FILE,
  ADD_FILE_TO_STATE
} from "../actions/types";

const initialState = {
  users: [],
  selected_user_id: [],
  show_delete_user: false,
  onload_file: false,
  distinct_file: [],
  select_file: [],
  show_delete_file: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        users: action.payload
      };
    case SELECT_USER_ID:
      return {
        ...state,
        selected_user_id: action.payload
      };
    case MODAL_DELETE_USER:
      return {
        ...state,
        show_delete_user: true
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter(one_user => one_user.id !== action.payload)
      };
    case MODAL_DELETE_CLOSE_USER:
      return {
        ...state,
        show_delete_user: false
      };
    case ONLOAD_FILE_HANDLE:
      return {
        ...state,
        onload_file: !state.onload_file
      };
    case GET_ALL_DATA:
      return {
        ...state,
        distinct_file: action.payload
      };
    case SELECT_FILE:
      return {
        ...state,
        select_file: action.payload
      };
    case MODAL_DELETE_FILE:
      return {
        ...state,
        show_delete_file: true
      };
    case MODAL_DELETE_CLOSE_FILE:
      return {
        ...state,
        show_delete_file: false
      };
    case DELETE_FILE:
      return {
        ...state,
        distinct_file: state.distinct_file.filter(
          one_file => one_file.file_nome !== action.payload
        )
      };
    case ADD_FILE_TO_STATE:
      return {
        ...state,
        distinct_file: [...state.distinct_file, action.payload]
      };
    default:
      return state;
  }
}
