import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { getFiles } from "../../../actions/userActions";
import Single_file from "../js/Single_file";
import MyModalDeleteFile from "./MyModalDeleteFile";

export class Modify_Data extends Component {
  componentDidMount() {
    this.props.getFiles();
  }
  render() {
    const { files } = this.props;
    return (
      <div>
        <Table responsive>
          <thead>
            <tr>
              <th>Utente</th>
              <th>Anno</th>
              <th>Mese</th>
              <th>Nome file</th>
              <th>Data inserimento</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {files.map(single_file => (
              <Single_file key={single_file.my_key} single_file={single_file} />
            ))}
          </tbody>
        </Table>

        {this.props.show_delete_file ? (
          <MyModalDeleteFile status={true} key={this.props.select_file} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  files: state.users.distinct_file,
  show_delete_file: state.users.show_delete_file,
  select_file: state.users.select_file
});

export default connect(
  mapStateToProps,
  { getFiles }
)(Modify_Data);
