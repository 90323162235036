import React from "react";
import "../css/photo_3_4_news.css";

export default function photo_3_4() {
  return (
    <div className="news_page">
      <h1 className="news_h1" data-aos="fade-left" data-aos-duration="2000">
        NEWS
      </h1>
      <h2 className="news_h2" data-aos="fade-up" data-aos-duration="1500">
        Tutti gli aggiornamenti
      </h2>
    </div>
  );
}
