import React, { Component } from "react";
import "../css/intro_team.css";

export default class intro_team extends Component {
  render() {
    return (
      <React.Fragment>
        <article className="intro">
          <h1>I NOSTRI PROFESSIONISTI</h1>
          <div className="box">
            <p>
              Un gruppo di persone che condivide un obiettivo comune può
              raggiungere l’impossibile
            </p>
          </div>
        </article>
      </React.Fragment>
    );
  }
}
