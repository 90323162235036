import React from "react";
import { Page, Document, StyleSheet, Image, Text } from "@react-pdf/renderer";
import Report_annuale from "../../../photos/report/Report_annuale.png";
import Report_unita_locali from "../../../photos/report/Report_unita_locali.png";
import Report_qualifiche from "../../../photos/report/Report_qualifiche.png";
import CostiMensili from "./CostiMensili";
import CostiUnitaLocali from "./CostiUnitaLocali";
import CostiQualifica from "./CostiQualifica";

const styles = StyleSheet.create({
  pageBackground: {
    flex: 1,
    resizeMode: "cover" // or 'stretch'
  },
  year_month: {
    position: "absolute",
    top: "70px",
    right: "33px",
    color: "white",
    fontSize: 20,
    fontWeight: 900
  },
  aggiornato_a: {
    position: "absolute",
    bottom: "35px",
    left: "30px",
    color: "white",
    fontSize: 12,
    fontWeight: 900
  },
  text_sezione: {
    position: "absolute",
    top: "395px",
    left: "280px",
    color: "#2e85d1",
    fontSize: 50,
    fontWeight: 900
  },
  body: {
    padding: 60
  }
});

const PdfDocument = ({
  unita_locali,
  qualifica,
  selected_year,
  report,
  home
}) => {
  const image = document.getElementById("download_costi_mensili").toDataURL();
  return (
    <Document>
      <Page>
        <Image style={styles.pageBackground} src={Report_annuale} alt="" />
        <Text style={styles.year_month}>{selected_year}</Text>
        {report.ordered_months.includes("Dicembre") ? null : (
          <Text style={styles.aggiornato_a}>
            Aggiornato ai dati di{" "}
            {report.ordered_months[report.ordered_months.length - 1]}{" "}
            {selected_year}
          </Text>
        )}
        <Text style={styles.year_month}>{selected_year}</Text>
      </Page>
      <Page style={styles.body}>
        <CostiMensili
          selected_year={selected_year}
          report={report}
          home={home}
        ></CostiMensili>
      </Page>
      <Page>
        <Image style={styles.pageBackground} src={Report_unita_locali} alt="" />
        <Text style={styles.text_sezione}>{selected_year}</Text>
      </Page>
      <Page style={styles.body}>
        <CostiUnitaLocali
          unita_locali={unita_locali}
          selected_year={selected_year}
          report={report}
        ></CostiUnitaLocali>
      </Page>
      <Page>
        <Image style={styles.pageBackground} src={Report_qualifiche} alt="" />
        <Text style={styles.text_sezione}>{selected_year}</Text>
      </Page>
      <Page style={styles.body}>
        <CostiQualifica
          qualifica={qualifica}
          selected_year={selected_year}
          report={report}
        ></CostiQualifica>
      </Page>
    </Document>
  );
};

export default PdfDocument;
