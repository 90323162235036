import React from "react";
import "../css/photo_3_4_team.css";

export default function photo_3_4() {
  return (
    <div className="team_page">
      <h1 className="team_h1" data-aos="fade-left" data-aos-duration="2000">
        CHI SIAMO
      </h1>
      <h2 className="team_h2" data-aos="fade-up" data-aos-duration="1500">
        Una passione per la qualità
      </h2>
    </div>
  );
}
